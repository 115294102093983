import {
  BlockPositionsUIModel,
  HubPricesResult,
  LastAuctionStatsResult,
  MaintenancePlan,
  MaintenanceUpdate,
  SpreadAlert,
  SupplyChartItem,
  SpreadFilter,
  SpreadMarketArea,
  Spreads,
  FilterForEditInterface
} from 'appy-gas-core';

export class GetHubPrices {
  public static readonly type = '[Dashboard Module] Get Hub Prices';
  constructor(public hubPrices: HubPricesResult) {}
}

export class GetHubPricesFailed {
  public static readonly type = '[Dashboard Module] Get Hub Prices Failed';
}

export class GetHubPricesLoading {
  public static readonly type = '[Dashboard Module] Get Hub Prices Loading';
}

export class GetHubPricesLoaded {
  public static readonly type = '[Dashboard Module] Get Hub Prices Loaded';
}

export class GetLngCountries {
  public static readonly type = '[Dashboard Module] Get Lng Countries';
  constructor(public lngCountries: SupplyChartItem[]) {}
}

export class GetLngCountriesFailed {
  public static readonly type = '[Dashboard Module] Get Lng Countries Failed';
}

export class GetLngCountriesLoading {
  public static readonly type = '[Dashboard Module] Get Lng Countries Loading';
}

export class GetLngCountriesLoaded {
  public static readonly type = '[Dashboard Module] Get Lng Countries Loaded';
}

export class GetPipelineCountries {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries';
  constructor(public pipelineCountries: SupplyChartItem[]) {}
}

export class GetPipelineCountriesFailed {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries Failed';
}

export class GetPipelineCountriesLoading {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries Loading';
}

export class GetPipelineCountriesLoaded {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries Loaded';
}

export class GetPipelineCountriesVsLngSupply {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply';
  constructor(public pipelineCountries: SupplyChartItem[]) {}
}

export class GetPipelineCountriesVsLngSupplyFailed {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply Failed';
}

export class GetPipelineCountriesVsLngSupplyLoading {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply Loading';
}

export class GetPipelineCountriesVsLngSupplyLoaded {
  public static readonly type = '[Dashboard Module] Get Pipeline Countries vs LNG Supply Loaded';
}

export class GetMaintenancePlan {
  public static readonly type = '[Dashboard Module] Get Maintenance Plan';
  constructor(public maintenancePlan: MaintenancePlan[]) {}
}

export class GetMaintenancePlanFailed {
  public static readonly type = '[Dashboard Module] Get Maintenance Plan Failed';
}

export class GetMaintenancePlanLoading {
  public static readonly type = '[Dashboard Module] Get Maintenance Plan Loading';
}

export class GetMaintenancePlanLoaded {
  public static readonly type = '[Dashboard Module] Get Maintenance Plan Loaded';
}

export class GetSpreads {
  public static readonly type = '[Dashboard Module] Get Spreads';
  constructor(public spreads: Spreads) {}
}

export class GetSpreadsFailed {
  public static readonly type = '[Dashboard Module] Get Spreads Failed';
}

export class GetSpreadsLoading {
  public static readonly type = '[Dashboard Module] Get Spreads Loading';
}

export class GetSpreadsLoaded {
  public static readonly type = '[Dashboard Module] Get Spreads Loaded';
}

export class GetAllFilterSpreads {
  public static readonly type = '[Dashboard Module] Get All Filter Spreads';
  constructor(public allFilterSpreads: Spreads) {}
}

export class GetAllFilterSpreadsFailed {
  public static readonly type = '[Dashboard Module] Get All Filter Spreads Failed';
}

export class GetAllFilterSpreadsLoading {
  public static readonly type = '[Dashboard Module] Get All Filter Spreads Loading';
}

export class GetAllFilterSpreadsLoaded {
  public static readonly type = '[Dashboard Module] Get All Filter Spreads Loaded';
}

export class GetPreviewSpreads {
  public static readonly type = '[Dashboard Module] Get Preview Spreads';
  constructor(public previewSpreads: Spreads) {}
}

export class GetPreviewSpreadsFailed {
  public static readonly type = '[Dashboard Module] Get Preview Spreads Failed';
}

export class GetPreviewSpreadsLoading {
  public static readonly type = '[Dashboard Module] Get Preview Spreads Loading';
}

export class GetPreviewSpreadsLoaded {
  public static readonly type = '[Dashboard Module] Get Preview Spreads Loaded';
}

export class GetSpreadFilters {
  public static readonly type = '[Dashboard Module] Get Spread Filters';
  constructor(public spreadFilters: SpreadFilter[]) {}
}

export class GetSpreadFiltersFailed {
  public static readonly type = '[Dashboard Module] Get Spread Filters Failed';
}

export class GetSpreadFiltersLoading {
  public static readonly type = '[Dashboard Module] Get Spread Filters Loading';
}

export class GetSpreadFiltersLoaded {
  public static readonly type = '[Dashboard Module] Get Spread Filters Loaded';
}

export class GetSpreadMarketAreas {
  public static readonly type = '[Dashboard Module] Get Spread Market Areas';
  constructor(public spreadMarketAreas: SpreadMarketArea[]) {}
}

export class GetSpreadMarketAreasFailed {
  public static readonly type = '[Dashboard Module] Get Spread Market Areas Failed';
}

export class GetSpreadMarketAreasLoading {
  public static readonly type = '[Dashboard Module] Get Spread Market Areas Loading';
}

export class GetSpreadMarketAreasLoaded {
  public static readonly type = '[Dashboard Module] Get Spread Market Areas Loaded';
}

export class GetMaintenanceUpdate {
  public static readonly type = '[Dashboard Module] Get Maintenance Update';
}

export class GetFullSpreadFilter {
  public static readonly type = '[Dashboard Module] Get Full Spread Filter';
  constructor(public spreadFilterForEdit: FilterForEditInterface) {}
}

export class GetFullSpreadFilterFailed {
  public static readonly type = '[Dashboard Module] Get Full Spread Filter Failed';
}

export class GetFullSpreadFilterLoading {
  public static readonly type = '[Dashboard Module] Get Full Spread Filter Loading';
}

export class GetFullSpreadFilterLoaded {
  public static readonly type = '[Dashboard Module] Get Full Spread Filter Loaded';
}

export class DeleteFullSpreadFilter {
  public static readonly type = '[Dashboard Module] Delete Full Spread Filter'
}

export class GetMaintenanceUpdateSuccess {
  public static readonly type = '[Dashboard Module] Get Maintenance Update Success';
  constructor(public maintenanceUpdate: MaintenanceUpdate[]) {}
}

export class GetMaintenanceUpdateFailed {
  public static readonly type = '[Dashboard Module] Get Maintenance Update Failed';
}

export class GetLastAuctionStats {
  public static readonly type = '[Dashboard Module] Get Last Auction Stats';
  constructor(public lastAuctionStats: LastAuctionStatsResult) {}
}

export class GetLastAuctionStatsFailed {
  public static readonly type = '[Dashboard Module] Get Last Auction Stats Failed';
}

export class GetLastAuctionStatsLoading {
  public static readonly type = '[Dashboard Module] Get Last Auction Stats Loading';
}

export class GetLastAuctionStatsLoaded {
  public static readonly type = '[Dashboard Module] Get Last Auction Stats Loaded';
}

export class GetBlockPositions {
  public static readonly type = '[Dashboard Module] Get Block Positions';
  constructor(public blockPositions: BlockPositionsUIModel) {}
}
