import { Selector } from "@ngxs/store";
import head from "lodash/head";

import {
  BlockPositionsUIModel,
  HubPricesResult,
  LastAuctionStatsResult,
  MaintenanceData,
  MaintenanceDataListType,
  MaintenancePlan,
  MaintenanceUpdate,
  SpreadAlert,
  SupplyChartItem,
  Spreads,
  SpreadFilter,
  SpreadMarketArea,
  FilterForEditInterface
} from "appy-gas-core";

import { DashboardHelperService } from "../../dashboard/dashboard-helper.service";
import { PointOperatorsFilter } from "../../shared/interfaces";
import { DashboardState, DashboardStateModel } from "./dashboard.state";

export class DashboardSelectors {
  @Selector([DashboardState])
  public static hubPrices(state: DashboardStateModel): HubPricesResult {
    return state.hubPrices.data;
  }

  @Selector([DashboardState])
  public static hubPricesIsLoading(state: DashboardStateModel): boolean {
    return state.hubPrices.loading;
  }

  @Selector([DashboardState])
  public static lngCountries(state: DashboardStateModel): SupplyChartItem[] {
    return state.lngCountries.data;
  }

  @Selector([DashboardState])
  public static lngCountriesIsLoading(state: DashboardStateModel): boolean {
    return state.lngCountries.loading;
  }

  @Selector([DashboardState])
  public static pipelineCountries(state: DashboardStateModel): SupplyChartItem[] {
    return state.pipelineCountries.data;
  }

  @Selector([DashboardState])
  public static pipelineCountriesIsLoading(state: DashboardStateModel): boolean {
    return state.pipelineCountries.loading;
  }

  @Selector([DashboardState])
  public static pipelineCountriesVsLng(state: DashboardStateModel): SupplyChartItem[] {
    return state.pipelineCountriesVsLng.data;
  }

  @Selector([DashboardState])
  public static pipelineCountriesVsLngIsLoading(state: DashboardStateModel): boolean {
    return state.pipelineCountriesVsLng.loading;
  }

  @Selector([DashboardState])
  public static maintenancePlanAll(state: DashboardStateModel): MaintenancePlan[] {
    return state.maintenancePlan.data;
  }

  @Selector([DashboardState])
  public static maintenancePlanMyPortfolio(state: DashboardStateModel): MaintenancePlan[] {
    return state.maintenancePlan.data.filter((maintenancePlan) => maintenancePlan.isInPortfolio);
  }

  @Selector([DashboardState])
  public static maintenancePlanIsLoading(state: DashboardStateModel): boolean {
    return state.maintenancePlan.loading;
  }

  @Selector([DashboardSelectors.maintenancePlanAll])
  public static getMaintenancePlanAllFilters(maintenancePlanAll: MaintenancePlan[]): PointOperatorsFilter {
    return DashboardHelperService.getMaintenanceFilters(maintenancePlanAll);
  }

  @Selector([DashboardSelectors.maintenanceUpdateAll])
  public static getMaintenanceUpdateAllFilters(maintenanceUpdateAll: MaintenanceUpdate[]): PointOperatorsFilter {
    return DashboardHelperService.getMaintenanceFilters(maintenanceUpdateAll);
  }

  @Selector([DashboardSelectors.maintenanceUpdateMyPortfolio])
  public static getMaintenanceUpdateMyPortfolioFilters(
    maintenanceUpdateMyPortfolio: MaintenanceUpdate[]
  ): PointOperatorsFilter {
    return DashboardHelperService.getMaintenanceFilters(maintenanceUpdateMyPortfolio);
  }

  @Selector([DashboardSelectors.maintenancePlanMyPortfolio])
  public static getMaintenancePlanMyPortfolioFilters(
    maintenancePlanMyPortfolio: MaintenancePlan[]
  ): PointOperatorsFilter {
    return DashboardHelperService.getMaintenanceFilters(maintenancePlanMyPortfolio);
  }

  @Selector([DashboardState])
  public static maintenanceUpdateAll(state: DashboardStateModel): MaintenanceUpdate[] {
    return state.maintenanceUpdate.data;
  }

  @Selector([DashboardState])
  public static maintenanceUpdateLoading(state: DashboardStateModel): boolean {
    return state.maintenanceUpdate.loading;
  }

  @Selector([DashboardSelectors.maintenanceUpdateAll])
  public static maintenanceUpdateMyPortfolio(maintenanceUpdates: MaintenanceUpdate[]): MaintenanceUpdate[] {
    return maintenanceUpdates.filter((maintenancePlan) => {
      const isFirstPrevDataItemInPortfolio = head(
        maintenancePlan[MaintenanceDataListType.PREVIOUS].data as MaintenanceData[]
      )?.isInPortfolio;
      const isFirstCurrentDataItemInPortfolio = head(
        maintenancePlan[MaintenanceDataListType.CURRENT].data as MaintenanceData[]
      )?.isInPortfolio;

      return isFirstPrevDataItemInPortfolio || isFirstCurrentDataItemInPortfolio;
    });
  }

  @Selector([DashboardState])
  public static lastAuctionStats(state: DashboardStateModel): LastAuctionStatsResult {
    return state.lastAuctionStats.data;
  }

  @Selector([DashboardState])
  public static lastAuctionStatsIsLoading(state: DashboardStateModel): boolean {
    return state.lastAuctionStats.loading;
  }

  @Selector([DashboardState])
  public static spreads(state: DashboardStateModel): Spreads {
    return state.spreads.data;
  }

  @Selector([DashboardState])
  public static spreadsLoading(state: DashboardStateModel): boolean {
    return state.spreads.loading;
  }

  @Selector([DashboardState])
  public static allFilterSpreads(state: DashboardStateModel): Spreads {
    return state.allFilterSpreads.data;
  }

  @Selector([DashboardState])
  public static allFilterSpreadsLoading(state: DashboardStateModel): boolean {
    return state.allFilterSpreads.loading;
  }

  @Selector([DashboardState])
  public static previewSpreads(state: DashboardStateModel): Spreads {
    return state.previewSpreads.data;
  }

  @Selector([DashboardState])
  public static previewSpreadsLoading(state: DashboardStateModel): boolean {
    return state.previewSpreads.loading;
  }

  @Selector([DashboardState])
  public static spreadFilters(state: DashboardStateModel): SpreadFilter[] {
    return state.spreadFilters.data;
  }

  @Selector([DashboardState])
  public static spreadFiltersLoading(state: DashboardStateModel): boolean {
    return state.spreadFilters.loading;
  }

  @Selector([DashboardState])
  public static spreadMarketAreas(state: DashboardStateModel): SpreadMarketArea[] {
    return state.spreadMarketAreas.data;
  }

  @Selector([DashboardState])
  public static spreadMarketAreasLoading(state: DashboardStateModel): boolean {
    return state.spreadMarketAreas.loading;
  }

  @Selector([DashboardState])
  public static spreadFilterForEdit(state: DashboardStateModel): FilterForEditInterface {
    return state.spreadFilterForEdit.data;
  }

  @Selector([DashboardState])
  public static spreadFilterForEditLoading(state: DashboardStateModel): boolean {
    return state.spreadFilterForEdit.loading;
  }

  @Selector([DashboardState])
  public static blockPositions(state: DashboardStateModel): BlockPositionsUIModel {
    return state.blockPositions;
  }

  @Selector([DashboardSelectors.hubPrices])
  public static filteredByMergeGermanyDateHubPrices(hubPrices: HubPricesResult): HubPricesResult {
    hubPrices.data = DashboardHelperService.filterHubPriceDataByMergeGermanyDate(hubPrices.data);

    return hubPrices;
  }
}
