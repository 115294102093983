// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".transition-modal__header{font-size:30px;font-weight:400;font-family:Montserrat;padding:30px 50px;border-bottom:1px solid rgba(0,0,0,.125)}.transition-modal__sub-header{font-size:20px}.transition-modal__wrapper{padding:30px 50px}.transition-modal__main-block{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;padding:22px 50px 30px}.transition-modal__appie-count,.transition-modal__benefits{font-size:12px}.transition-modal__overview-form{margin:2px 0 20px}.transition-modal__main-img{position:relative;bottom:20px}.transition-modal__description-block{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}.transition-modal__description,.transition-modal__main-block{font-weight:700}.transition-modal__description{font-size:12px;line-height:16px;width:380px}.transition-modal__description-img{height:134px}.transition-modal__checkbox-form{margin-top:24px}.transition-modal__link{color:#3288c9;text-decoration:underline}.transition-modal__btn--cancel{margin-right:20px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();