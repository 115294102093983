import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ToastrModule } from 'ngx-toastr';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { ApiUsersService, EnvVariables, ModalModule, SpinnerModule, SpinnerService } from 'appy-gas-core';

import { EnvironmentsModule } from '../../environment-variables';
import { SharedModule } from '../shared';
import { CommonEntitiesService } from './common-entities/common-entities.service';
import { CookiesPopoverModule, CookiesPopoverService } from './cookies-popover';
import { ExtendPremiumComponent, ExtendPremiumModule } from './extend-premium';
import { AuthenticationGuard, AuthenticationService, CurrentUserResolverService, EmptyPropGuard } from './http-auth';
import { httpInterceptorProviders } from './http-auth/http-interceptors';
import { RequestCache, RequestCacheWithMap } from './http-auth/request-cache.service';
import { RoleNavigationHandlerService } from './role-navigation-handler/role-navigation-handler.service';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './rollbar';
import { RouteChangeHandlerService, RouterStateService } from './router';
import { TechnicalMaintenanceModule } from './technical-maintenance';
import { UpgradePremiumComponent, UpgradePremiumModule } from './upgrade-premium';
import { WelcomePopoverComponent, WelcomePopoverModule } from './welcome-popover/';

// app wide modals
import { AuthModule, ForgotPasswordComponent, LoginComponent, SignupComponent } from '../auth';
import { CommonEntitiesFacade } from '../store/common-entities';

const PROVIDERS = [
  AuthenticationService,
  CurrentUserResolverService,
  AuthenticationGuard,
  SpinnerService,
  CookiesPopoverService,
  RouteChangeHandlerService,
  RouterStateService,
  RollbarErrorHandler,
  EmptyPropGuard,
  ApiUsersService,
  CommonEntitiesService,
  CommonEntitiesFacade,
  RoleNavigationHandlerService,
  { provide: ErrorHandler, useClass: RollbarErrorHandler },
  { provide: RollbarService, deps: [EnvVariables], useFactory: rollbarFactory },
  { provide: RequestCache, useClass: RequestCacheWithMap },
  httpInterceptorProviders
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    ScrollToModule.forRoot(),
    RouterModule,
    SharedModule,
    EnvironmentsModule,
    ModalModule,
    SpinnerModule,
    CookiesPopoverModule,
    AuthModule,
    UpgradePremiumModule,
    ExtendPremiumModule,
    TechnicalMaintenanceModule,
    WelcomePopoverModule
  ],
  providers: PROVIDERS,
  exports: [ModalModule, SpinnerModule, CookiesPopoverModule, EnvironmentsModule],
  entryComponents: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    UpgradePremiumComponent,
    ExtendPremiumComponent,
    WelcomePopoverComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
