import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-access-expired',
  templateUrl: './access-expired.component.html',
  styleUrls: ['../user-transition-modals.scss', './access-expired.component.scss']
})
export class AccessExpiredComponent implements OnInit, OnDestroy {
  public newsLetterForm: FormGroup;
  public syncNewsLetterSubscription: (newsLetterValue: boolean) => void;
  public newsLetterValue: string;

  private readonly componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(private modalService: ModalService, private formBuilder: FormBuilder, private router: Router) {}

  public ngOnInit(): void {
    this.newsLetterForm = this.createNewsLetterForm();
    this.newsLetterForm
      .get('signUpNewsLetter')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((val: boolean) => this.syncNewsLetterSubscription(val));
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public hideModal(): void {
    this.modalService.hide();
  }

  public goToContactUsPage(): void {
    this.router.navigateByUrl('static/contact-us/upgrade-exclusive').then(() => this.hideModal());
  }

  public goToMyProfile(): void {
    this.router.navigateByUrl('profile/information').then(() => this.hideModal());
  }

  private createNewsLetterForm(): FormGroup {
    return this.formBuilder.group({
      signUpNewsLetter: [this.newsLetterValue || false]
    });
  }
}
