// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".percentage-chart{display:-ms-flexbox;display:flex;-ms-flex-pack:distribute;justify-content:space-around}.percentage-chart__circular{display:block;max-height:35px;overflow:visible}.percentage-chart__circle-bg{stroke:#eceef2;stroke-width:1.8}.percentage-chart__circle-dashed{fill:none;stroke:#fff;stroke-width:3.2;stroke-dasharray:6;stroke-dashoffset:6}.percentage-chart__circle{fill:none;stroke-width:1.8;stroke-linecap:round;animation:progress 1s ease-out forwards}.percentage-chart__percentage{stroke-width:1;font-family:sans-serif;font-size:11px;text-anchor:middle}@keyframes progress{0%{stroke-dasharray:0 100}}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();