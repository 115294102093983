import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { maintenanceFilterSyncConfig } from '../../constants/maintenance-filter-sync.config';
import { MaintenanceFilterTypeEnum } from '../../enums/maintenance-filter-type.enum';
import { MaintenanceDashboardFilters, PointOperatorsFilter } from '../../interfaces';

@Component({
  selector: 'appy-gas-maintenance-dashboard-filter',
  templateUrl: './maintenance-dashboard-filter.component.html',
  styleUrls: ['./maintenance-dashboard-filter.component.scss']
})
export class MaintenanceDashboardFilterComponent implements OnChanges {
  @Input() private maintenancePlanFilters: PointOperatorsFilter;

  @Output() private toggleFiltersEmitter: EventEmitter<PointOperatorsFilter> = new EventEmitter();

  public filterForm: FormGroup;
  public filterType = MaintenanceFilterTypeEnum;
  public defaultFilters: MaintenanceDashboardFilters;

  constructor(private fb: FormBuilder) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.maintenancePlanFilters) {
      this.defaultFilters = this.maintenancePlanFilters;
      this.createForm(this.defaultFilters);
      this.filterForm.valueChanges.subscribe((formValue: PointOperatorsFilter) => {
        this.toggleFiltersEmitter.emit(formValue);
      });
    }
  }

  public clearFilter(event: any): void {
    event.preventDefault();
    this.filterForm.reset({
      ip: this.defaultFilters.ip,
      tso: this.defaultFilters.tso
    });
  }

  public syncFilters(selectedItems: any, prop: MaintenanceFilterTypeEnum): void {
    const filterConfig = maintenanceFilterSyncConfig[prop];
    const syncItems = Array.from(new Set(selectedItems.map((i) => i[filterConfig.syncProperty])));

    this.filterForm.controls[filterConfig.fieldName].setValue(
      this.defaultFilters[filterConfig.fieldName].filter((i) => syncItems.includes(i[filterConfig.property])),
      { onlySelf: true }
    );
  }

  private createForm(filters: MaintenanceDashboardFilters): void {
    this.filterForm = this.fb.group({
      ip: [filters?.ip],
      tso: [filters?.tso]
    });
  }
}
