import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';
import { LocalStorageKeysEnum } from '../../../enums/local-storage-keys.enum';

@Component({
  selector: 'appy-gas-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['../user-transition-modals.scss']
})
export class InfoModalComponent {
  public accessDays: number;
  public dateAccessEnd: Date;

  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    localStorage.removeItem(LocalStorageKeysEnum.USER_ROLE_INFO_MODAL);
    this.modalService.hide();
  }
}
