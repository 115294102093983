import { Selector } from '@ngxs/store';

import { MarketAreaActivePoint, Operators } from 'appy-gas-core';
import { CommonEntitiesState, CommonEntitiesStateModel } from './common-entities.state';

export class CommonEntitiesSelectors {
  @Selector([CommonEntitiesState])
  public static operators(state: CommonEntitiesStateModel): Operators[] {
    return state.operators;
  }

  @Selector([CommonEntitiesState])
  public static eePoints(state: CommonEntitiesStateModel): MarketAreaActivePoint[] {
    return state.eePoints;
  }
}
