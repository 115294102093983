import { Action, State, StateContext } from '@ngxs/store';

import {
  BlockPositionsUIModel,
  HubPricesResult,
  LastAuctionStatsResult,
  MaintenancePlan,
  MaintenanceUpdate,
  SpreadAlert,
  SupplyChartItem,
  Spreads,
  SpreadMarketArea,
  SpreadFilter,
  FilterForEditInterface,
} from "appy-gas-core";

import {
  DeleteFullSpreadFilter,
  GetAllFilterSpreads,
  GetAllFilterSpreadsFailed,
  GetAllFilterSpreadsLoaded,
  GetAllFilterSpreadsLoading,
  GetBlockPositions,
  GetFullSpreadFilter,
  GetFullSpreadFilterFailed,
  GetFullSpreadFilterLoaded,
  GetFullSpreadFilterLoading,
  GetHubPrices,
  GetHubPricesFailed,
  GetHubPricesLoaded,
  GetHubPricesLoading,
  GetLastAuctionStats,
  GetLastAuctionStatsFailed,
  GetLastAuctionStatsLoaded,
  GetLastAuctionStatsLoading,
  GetLngCountries,
  GetLngCountriesFailed,
  GetLngCountriesLoaded,
  GetLngCountriesLoading,
  GetMaintenancePlan,
  GetMaintenancePlanFailed,
  GetMaintenancePlanLoaded,
  GetMaintenancePlanLoading,
  GetMaintenanceUpdate,
  GetMaintenanceUpdateFailed,
  GetMaintenanceUpdateSuccess,
  GetPipelineCountries,
  GetPipelineCountriesFailed,
  GetPipelineCountriesLoaded,
  GetPipelineCountriesLoading,
  GetPipelineCountriesVsLngSupply,
  GetPipelineCountriesVsLngSupplyFailed,
  GetPipelineCountriesVsLngSupplyLoaded,
  GetPipelineCountriesVsLngSupplyLoading,
  GetPreviewSpreads,
  GetPreviewSpreadsFailed,
  GetPreviewSpreadsLoaded,
  GetPreviewSpreadsLoading,
  GetSpreadFilters,
  GetSpreadFiltersFailed,
  GetSpreadFiltersLoaded,
  GetSpreadFiltersLoading,
  GetSpreadMarketAreas,
  GetSpreads,
  GetSpreadsFailed,
  GetSpreadsLoaded,
  GetSpreadsLoading
} from './dashboard.actions';

export interface DashboardStateModel {
  hubPrices: {
    data: HubPricesResult;
    loading: boolean;
    loaded: boolean;
  };
  lastAuctionStats: {
    data: LastAuctionStatsResult;
    loading: boolean;
    loaded: boolean;
  };
  lngCountries: {
    data: SupplyChartItem[];
    loading: boolean;
    loaded: boolean;
  };
  maintenancePlan: {
    data: MaintenancePlan[];
    loading: boolean;
    loaded: boolean;
  };
  maintenanceUpdate: {
    data: MaintenanceUpdate[];
    loading: boolean;
    loaded: boolean;
  };
  pipelineCountries: {
    data: SupplyChartItem[];
    loading: boolean;
    loaded: boolean;
  };
  pipelineCountriesVsLng: {
    data: SupplyChartItem[];
    loading: boolean;
    loaded: boolean;
  };
  spreads: {
    data: Spreads;
    loading: boolean;
    loaded: boolean;
  };
  allFilterSpreads: {
    data: Spreads;
    loading: boolean;
    loaded: boolean;
  };
  previewSpreads: {
    data: Spreads,
    loading: boolean,
    loaded: boolean,
  },
  spreadFilters: {
    data: SpreadFilter[];
    loading: boolean;
    loaded: boolean;
  };
  spreadMarketAreas: {
    data: SpreadMarketArea[];
    loading: boolean;
    loaded: boolean;
  },
  spreadFilterForEdit: {
    data: FilterForEditInterface;
    loading: boolean;
    loaded: boolean;
  }
  blockPositions: BlockPositionsUIModel;
}

@State<DashboardStateModel>({
  name: 'dashboard',
  defaults: {
    hubPrices: {
      data: undefined,
      loading: false,
      loaded: false
    },
    lastAuctionStats: {
      data: undefined,
      loading: false,
      loaded: false
    },
    lngCountries: {
      data: undefined,
      loading: false,
      loaded: false
    },
    maintenancePlan: {
      data: undefined,
      loading: false,
      loaded: false
    },
    maintenanceUpdate: {
      data: undefined,
      loading: false,
      loaded: false
    },
    pipelineCountries: {
      data: undefined,
      loading: false,
      loaded: false
    },
    pipelineCountriesVsLng: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    allFilterSpreads: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    previewSpreads: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    spreadFilters: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    spreadMarketAreas: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    spreadFilterForEdit: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    spreads: {
      data: undefined,
      loading: false,
      loaded: false,
    },
    blockPositions: undefined
  }
})
export class DashboardState {
  @Action(GetHubPrices)
  private getHubPrices(ctx: StateContext<DashboardStateModel>, { hubPrices }: GetHubPrices): void {
    const state = ctx.getState();
    ctx.patchState({
      hubPrices: { ...state.hubPrices, data: hubPrices }
    });
  }

  @Action(GetHubPricesFailed)
  private getHubPricesFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      hubPrices: { ...state.hubPrices, loaded: false, loading: false }
    });
  }

  @Action(GetHubPricesLoading)
  private getHubPricesLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      hubPrices: { ...state.hubPrices, loaded: false, loading: true }
    });
  }

  @Action(GetHubPricesLoaded)
  private getHubPricesLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      hubPrices: { ...state.hubPrices, loaded: true, loading: false }
    });
  }

  @Action(GetLngCountries)
  private getLngCountries(ctx: StateContext<DashboardStateModel>, { lngCountries }: GetLngCountries): void {
    const state = ctx.getState();
    ctx.patchState({
      lngCountries: { ...state.lngCountries, data: lngCountries }
    });
  }

  @Action(GetLngCountriesFailed)
  private getLngCountriesFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      lngCountries: { ...state.lngCountries, loaded: false, loading: false }
    });
  }

  @Action(GetLngCountriesLoading)
  private getLngCountriesLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      lngCountries: { ...state.lngCountries, loaded: false, loading: true }
    });
  }

  @Action(GetLngCountriesLoaded)
  private getLngCountriesLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      lngCountries: { ...state.lngCountries, loaded: true, loading: false }
    });
  }

  @Action(GetPipelineCountries)
  private getPipelineCountries(
    ctx: StateContext<DashboardStateModel>,
    { pipelineCountries }: GetPipelineCountries
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountries: { ...state.pipelineCountries, data: pipelineCountries }
    });
  }

  @Action(GetPipelineCountriesFailed)
  private getPipelineCountriesFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountries: { ...state.pipelineCountries, loaded: false, loading: false }
    });
  }

  @Action(GetPipelineCountriesLoading)
  private getPipelineCountriesLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountries: { ...state.pipelineCountries, loaded: false, loading: true }
    });
  }

  @Action(GetPipelineCountriesLoaded)
  private getPipelineCountriesLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountries: { ...state.pipelineCountries, loaded: true, loading: false }
    });
  }

  @Action(GetPipelineCountriesVsLngSupply)
  private getPipelineCountriesVsLngSupply(
    ctx: StateContext<DashboardStateModel>,
    { pipelineCountries }: GetPipelineCountriesVsLngSupply
  ): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountriesVsLng: { ...state.pipelineCountriesVsLng, data: pipelineCountries }
    });
  }

  @Action(GetPipelineCountriesVsLngSupplyFailed)
  private getPipelineCountriesVsLngSupplyFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountriesVsLng: { ...state.pipelineCountriesVsLng, loaded: false, loading: false }
    });
  }

  @Action(GetPipelineCountriesVsLngSupplyLoading)
  private getPipelineCountriesVsLngSupplyLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountriesVsLng: { ...state.pipelineCountriesVsLng, loaded: false, loading: true }
    });
  }

  @Action(GetPipelineCountriesVsLngSupplyLoaded)
  private getPipelineCountriesVsLngSupplyLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      pipelineCountriesVsLng: { ...state.pipelineCountriesVsLng, loaded: true, loading: false }
    });
  }

  @Action(GetMaintenancePlan)
  private getMaintenancePlan(ctx: StateContext<DashboardStateModel>, { maintenancePlan }: GetMaintenancePlan): void {
    const state = ctx.getState();
    ctx.patchState({
      maintenancePlan: { ...state.maintenancePlan, data: maintenancePlan }
    });
  }

  @Action(GetMaintenancePlanFailed)
  private getMaintenancePlanFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      maintenancePlan: { ...state.maintenancePlan, loaded: false, loading: false }
    });
  }

  @Action(GetMaintenancePlanLoading)
  private getMaintenancePlanLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      maintenancePlan: { ...state.maintenancePlan, loaded: false, loading: true }
    });
  }

  @Action(GetMaintenancePlanLoaded)
  private getMaintenancePlanLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      maintenancePlan: { ...state.maintenancePlan, loaded: true, loading: false }
    });
  }

  @Action(GetMaintenanceUpdate)
  private getMaintenanceUpdate(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ maintenanceUpdate: { ...state.maintenanceUpdate, loading: true } });
  }

  @Action(GetMaintenanceUpdateSuccess)
  private getMaintenanceUpdateSuccess(
    { patchState }: StateContext<DashboardStateModel>,
    { maintenanceUpdate }: GetMaintenanceUpdateSuccess
  ): void {
    patchState({ maintenanceUpdate: { data: maintenanceUpdate, loaded: true, loading: false } });
  }

  @Action(GetMaintenanceUpdateFailed)
  private getMaintenanceUpdateFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      maintenancePlan: { ...state.maintenancePlan, loaded: false, loading: false }
    });
  }

  @Action(GetLastAuctionStats)
  private getLastAuctionStats(ctx: StateContext<DashboardStateModel>, { lastAuctionStats }: GetLastAuctionStats): void {
    const state = ctx.getState();
    ctx.patchState({
      lastAuctionStats: { ...state.lastAuctionStats, data: lastAuctionStats }
    });
  }

  @Action(GetLastAuctionStatsFailed)
  private getLastAuctionStatsFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      lastAuctionStats: { ...state.lastAuctionStats, loaded: false, loading: false }
    });
  }

  @Action(GetLastAuctionStatsLoading)
  private getLastAuctionStatsLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      lastAuctionStats: { ...state.lastAuctionStats, loaded: false, loading: true }
    });
  }

  @Action(GetLastAuctionStatsLoaded)
  private getLastAuctionStatsLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      lastAuctionStats: { ...state.lastAuctionStats, loaded: true, loading: false }
    });
  }

  @Action(GetSpreads)
  private getSpreads(ctx: StateContext<DashboardStateModel>, { spreads }: GetSpreads): void {
    const state = ctx.getState();
    ctx.patchState({
      spreads: { ...state.spreads, data: spreads },
    });
  }

  @Action(GetSpreadsFailed)
  private getSpreadsFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreads: { ...state.spreads, loaded: false, loading: false },
    });
  }

  @Action(GetSpreadsLoading)
  private getSpreadsLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreads: { ...state.spreads, loaded: false, loading: true },
    });
  }

  @Action(GetSpreadsLoaded)
  private getSpreadsLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreads: { ...state.spreads, loaded: true, loading: false },
    });
  }

  @Action(GetAllFilterSpreads)
  private getAllFilterSpreads(ctx: StateContext<DashboardStateModel>, { allFilterSpreads }: GetAllFilterSpreads): void {
    const state = ctx.getState();
    ctx.patchState({
      allFilterSpreads: { ...state.spreads, data: allFilterSpreads },
    });
  }

  @Action(GetAllFilterSpreadsFailed)
  private getAllFilterSpreadsFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      allFilterSpreads: { ...state.allFilterSpreads, loaded: false, loading: false },
    });
  }

  @Action(GetAllFilterSpreadsLoading)
  private getAllFilterSpreadsLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      allFilterSpreads: { ...state.allFilterSpreads, loaded: false, loading: true },
    });
  }

  @Action(GetAllFilterSpreadsLoaded)
  private getAllFilterSpreadsLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      allFilterSpreads: { ...state.allFilterSpreads, loaded: true, loading: false },
    });
  }

  @Action(GetPreviewSpreads)
  private getPreviewSpreads(ctx: StateContext<DashboardStateModel>, { previewSpreads }: GetPreviewSpreads): void {
    const state = ctx.getState();
    ctx.patchState({
      previewSpreads: { ...state.spreads, data: previewSpreads },
    });
  }

  @Action(GetPreviewSpreadsFailed)
  private getPreviewSpreadsFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      previewSpreads: { ...state.previewSpreads, loaded: false, loading: false },
    });
  }

  @Action(GetPreviewSpreadsLoading)
  private getPreviewSpreadsLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      previewSpreads: { ...state.previewSpreads, loaded: false, loading: true },
    });
  }

  @Action(GetPreviewSpreadsLoaded)
  private getPreviewSpreadsLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      previewSpreads: { ...state.previewSpreads, loaded: true, loading: false },
    });
  }

  @Action(GetSpreadFilters)
  private getSreadFilters(ctx: StateContext<DashboardStateModel>, { spreadFilters }: GetSpreadFilters): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilters: { ...state.spreadFilters, data: spreadFilters },
    });
  }

  @Action(GetSpreadFiltersFailed)
  private getSpreadFiltersFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilters: { ...state.spreadFilters, loaded: false, loading: false },
    });
  }

  @Action(GetSpreadFiltersLoading)
  private getSpreadFiltersLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilters: { ...state.spreadFilters, loaded: false, loading: true },
    });
  }

  @Action(GetSpreadFiltersLoaded)
  private getSpreadFiltersLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilters: { ...state.spreadFilters, loaded: true, loading: false },
    });
  }

  @Action(GetSpreadMarketAreas)
  private getSpreadMarketAreas(ctx: StateContext<DashboardStateModel>, { spreadMarketAreas }: GetSpreadMarketAreas): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadMarketAreas: { ...state.spreadMarketAreas, data: spreadMarketAreas },
    });
  }

  @Action(GetSpreadFiltersFailed)
  private getSpreadMarketAreasFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadMarketAreas: { ...state.spreadMarketAreas, loaded: false, loading: false },
    });
  }

  @Action(GetSpreadFiltersLoading)
  private getSpreadMarketAreasLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadMarketAreas: { ...state.spreadMarketAreas, loaded: false, loading: true },
    });
  }

  @Action(GetSpreadFiltersLoaded)
  private getSpreadMarketAreasLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadMarketAreas: { ...state.spreadMarketAreas, loaded: true, loading: false },
    });
  }

  @Action(GetFullSpreadFilter)
  private getFullSpreadFilter(ctx: StateContext<DashboardStateModel>, { spreadFilterForEdit }: GetFullSpreadFilter): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilterForEdit: { ...state.spreadFilterForEdit, data: spreadFilterForEdit },
    });
  }

  @Action(GetFullSpreadFilterFailed)
  private getFullSpreadFilterFailed(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilterForEdit: { ...state.spreadFilterForEdit, loaded: false, loading: false },
    });
  }

  @Action(GetFullSpreadFilterLoading)
  private getFullSpreadFilterLoading(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilterForEdit: { ...state.spreadFilterForEdit, loaded: false, loading: true },
    });
  }

  @Action(GetFullSpreadFilterLoaded)
  private getFullSpreadFilterLoaded(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilterForEdit: { ...state.spreadFilterForEdit, loaded: true, loading: false },
    });
  }

  @Action(DeleteFullSpreadFilter)
  private deleteFullSpreadFilter(ctx: StateContext<DashboardStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      spreadFilterForEdit: { ...state.spreadFilterForEdit, data: undefined },
    });
  }

  @Action(GetBlockPositions)
  private getBlockPositions(
    { patchState }: StateContext<DashboardStateModel>,
    { blockPositions }: GetBlockPositions
  ): void {
    patchState({ blockPositions });
  }
}
