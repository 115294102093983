// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":host,:host .restrict-element{display:-ms-flexbox;display:flex}:host .restrict-element{background-size:cover;text-align:center;width:100%;height:100%;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}:host .restrict-element__caption{font-size:.75rem;font-family:Montserrat;font-weight:500}:host .restrict-element__link{color:#7200da;text-decoration:underline;cursor:pointer}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();