import { Selector } from '@ngxs/store';

import { IDownloadFile } from 'appy-gas-core';

import { DataExportState, DataExportStateModel } from './data-export.state';

export class DataExportSelectors {
  @Selector([DataExportState])
  public static exportData(state: DataExportStateModel): IDownloadFile[] {
    return state.exportData.data;
  }
}
