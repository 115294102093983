import { Component } from '@angular/core';

import friends from 'appy-gas-core/dist/assets/svg/icons/friends.svg';

import { APP_LINKS } from '../../config';
import { CATEGORIES, Category } from './categories';

@Component({
  selector: 'appy-gas-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  public readonly currentYear: number = new Date().getFullYear();
  public readonly categories: Category[] = CATEGORIES;
  public readonly links = APP_LINKS;
  public readonly friendsIcon: SVGElement = friends;
}
