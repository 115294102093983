import { HttpErrorResponse } from '@angular/common/http';

import { IApiUser, User } from 'appy-gas-core/dist';

import { BadgeModel } from '../../profile/interfaces/badge.model';
import { PortfolioInformationModel } from '../../profile/interfaces/portfolio-information.model';
import { TransactionHistoryModel } from '../../profile/interfaces/transaction-history.model';
import { UserBadgesModel } from '../../profile/interfaces/user-badges.model';
import { UpdateUserRoleResponseTypes } from '../../shared/enums/update-user-role-response-types.enum';

export class GetApiUsersList {
  public static readonly type = '[Profile] Get Api Users List';
}

export class GetApiUsersListSuccess {
  public static readonly type = '[Profile] Get Api Users List Success';
  constructor(public apiUsersList: IApiUser[]) {}
}

export class GetApiUsersListFail {
  public static readonly type = '[Profile] Get Api Users List Fail';
  constructor(public error: HttpErrorResponse) {}
}

export class GetPortfolioInformation {
  public static readonly type = '[Profile] Get Portfolio Information';
}

export class GetPortfolioInformationSuccess {
  public static readonly type = '[Profile] Get Portfolio Information Success';
  constructor(public portfolioInformation: PortfolioInformationModel) {}
}

export class GetPortfolioInformationFail {
  public static readonly type = '[Profile] Get Portfolio Information Fail';
  constructor(public error: HttpErrorResponse) {}
}

export class GetUserPortfolioPoints {
  public static readonly type = '[Profile] Get User Portfolio Points';
}

export class GetUserPortfolioPointsSuccess {
  public static readonly type = '[Profile] Get User Portfolio Points Success';
  constructor(public savedPointsIds: number[]) {}
}

export class GetUserPortfolioPointsFail {
  public static readonly type = '[Profile] Get User Portfolio Points Fail';
  constructor(public error: HttpErrorResponse) {}
}

export class SaveUserPortfolioPoints {
  public static readonly type = '[Profile] Save User Portfolio Points';
  constructor(public savedPointsIds: number[]) {}
}

export class GetUserProfile {
  public static readonly type = '[Profile] Get User profile';
  constructor(public user: User) {}
}

export class UpdateUserProfileNewsLetter {
  public static readonly type = '[Profile] Update User Profile News Letter';
  constructor(public newsLetter: boolean) {}
}

export class SaveApiUser {
  public static readonly type = '[Profile] Save Api User';
}

export class SaveApiUserSuccess {
  public static readonly type = '[Profile] Save Api User Success';
  constructor(public apiUser: IApiUser) {}
}

export class SaveApiUserFail {
  public static readonly type = '[Profile] Save Api User Fail';
  constructor(public error: HttpErrorResponse) {}
}

export class GetWalletInformation {
  public static readonly type = '[Profile] Get Wallet Information';
}

export class GetWalletInformationSuccess {
  public static readonly type = '[Profile] Get Wallet Information Success';
  constructor(public appiesCount: number, public appiesTransactionHistory: TransactionHistoryModel[]) {}
}

export class GetWalletInformationFail {
  public static readonly type = '[Profile] Get Wallet Information Fail';
  constructor(public error: HttpErrorResponse) {}
}

export class SetWalletBalanceStatus {
  public static readonly type = '[User state] Set Wallet Balance Status';
  constructor(public balanceStatus: UpdateUserRoleResponseTypes) {}
}

export class GetUserBadges {
  public static readonly type = '[User state] Get User Badges';
}

export class GetUserBadgesSuccess {
  public static readonly type = '[User state] Get User Badges Success';
  constructor(public userBadges: UserBadgesModel[]) {}
}

export class GetUserBadgesFailed {
  public static readonly type = '[User state] Get User Badges Failed';
  constructor(public error: HttpErrorResponse) {}
}

export class GetAllBadges {
  public static readonly type = '[User state] Get All Badges';
}
export class GetAllBadgesSuccess {
  public static readonly type = '[User state] Get All Badges Success';
  constructor(public allBadges: BadgeModel[]) {}
}
export class GetAllBadgesFailed {
  public static readonly type = '[User state] Get All Badges Failed';
  constructor(public error: HttpErrorResponse) {}
}
