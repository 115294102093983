import { Selector } from '@ngxs/store';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import sortBy from 'lodash/sortBy';

import {
  dateToGasDayRange,
  FlowsMapTabType,
  VirtualPoint,
  VirtualPointType,
  WidgetPhysicalPointModel
} from 'appy-gas-core';
import {
  WidgetPhysicalPointModelUI,
  WidgetPhysicalPointsModelUI
} from '../../flows-map/flows-map-area/flows-map-sidebar-pro/my-point-widget/interfaces';
import {
  FlowsMapWidgetsVisibilityModel,
  FlowsMapWidgetTypesEnum,
  TabPhysicalPointByGasDay
} from '../../flows-map/interfaces';
import { mergedGermanyMADate } from '../../shared';
import {
  virtualPointIdsAfterGermanyMerge,
  virtualPointIdsBeforeGermanyMerge
} from '../../shared/constants/app-exceptions';
import { UserPointTypesEnum } from '../../shared/enums';
import { DropdownShowMyPointsFilter, PointOperatorsFilter } from '../../shared/interfaces';
import { FilterHelperService } from '../../shared/services/filter-helper.service';
import { FlowsMapHelperService } from '../../shared/services/flows-map-helper.service';
import { CommonEntitiesState, CommonEntitiesStateModel } from '../common-entities';
import { FlowsMapState, FlowsMapStateModel } from './flows-map.state';

export class FlowsMapSelectors {
  @Selector([FlowsMapState])
  public static virtualPoints(state: FlowsMapStateModel): VirtualPoint[] {
    return state.virtualPoints.data;
  }

  @Selector([FlowsMapState])
  public static allPhysicalPoints(state: FlowsMapStateModel): WidgetPhysicalPointModel[] {
    return state.physicalPoints.data;
  }

  @Selector([FlowsMapState])
  public static selectedDate(state: FlowsMapStateModel): number {
    return state.selectedDate;
  }

  @Selector([FlowsMapState])
  public static activeFilterTab(state: FlowsMapStateModel): FlowsMapTabType {
    return state.activeFilterTab;
  }

  @Selector([FlowsMapState])
  public static selectedChartPoints(state: FlowsMapStateModel): VirtualPoint[] {
    return state.selectedChartPoints;
  }

  @Selector([FlowsMapState])
  public static selectedTypesFilter(state: FlowsMapStateModel): VirtualPointType[] {
    return state.selectedTypesFilter;
  }

  @Selector([FlowsMapState])
  public static widgetsVisibilityState(state: FlowsMapStateModel): FlowsMapWidgetsVisibilityModel {
    return state.widgetsVisibilityState;
  }

  @Selector([
    FlowsMapSelectors.virtualPoints,
    FlowsMapSelectors.selectedTypesFilter,
    FlowsMapSelectors.isMergedGermanyMAState
  ])
  public static filteredVirtualPoints(
    virtualPoints: VirtualPoint[],
    selectedTypesFilter: VirtualPointType[],
    isMergedGermanyMAState: boolean
  ): VirtualPoint[] {
    const filteredPointsByMergedGermanyMAState = isMergedGermanyMAState
      ? virtualPoints.filter((point) => !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId))
      : virtualPoints.filter((point) => !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId));

    return filteredPointsByMergedGermanyMAState.map((point: VirtualPoint) => {
      point.active = selectedTypesFilter.includes(point.eePointTypeId);
      return point;
    });
  }

  @Selector([FlowsMapState])
  public static selectedPointType(state: FlowsMapStateModel): UserPointTypesEnum {
    return state.pointsSwitcher;
  }

  @Selector([FlowsMapState])
  public static selectedPointsWidgetTabType(state: FlowsMapStateModel): VirtualPointType {
    return state.pointsWidgetTabTypeSwitcher;
  }

  @Selector([FlowsMapSelectors.allPhysicalPoints, CommonEntitiesState])
  public static allPhysicalMappedPointNames(
    allPhysicalPoints: WidgetPhysicalPointModel[],
    commonEntitiesState: CommonEntitiesStateModel
  ): WidgetPhysicalPointModelUI[] {
    const { eePoints } = commonEntitiesState;

    return allPhysicalPoints.map((point) => ({
      ...point,
      pointName: eePoints.find((eePoint) => point.eePointId === eePoint.eePointId)?.name
    }));
  }

  @Selector([FlowsMapSelectors.allPhysicalMappedPointNames, FlowsMapSelectors.selectedPointType])
  public static physicalPointsBySelectedPointType(
    allPhysicalPoints: WidgetPhysicalPointModelUI[],
    selectedPointType: UserPointTypesEnum
  ): WidgetPhysicalPointModelUI[] {
    return selectedPointType === UserPointTypesEnum.MY_PORTFOLIO
      ? allPhysicalPoints.filter((physicalPoint) => physicalPoint.isInPortfolio)
      : allPhysicalPoints;
  }

  @Selector([FlowsMapSelectors.physicalPointsBySelectedPointType])
  public static aggregatedWidgetPhysicalPoints(
    physicalPoints: WidgetPhysicalPointModelUI[]
  ): WidgetPhysicalPointsModelUI {
    return {
      storagePoints: physicalPoints
        .filter((physicalPoint) => physicalPoint.pointType === VirtualPointType.STO)
        .map((storagePoint) => FlowsMapHelperService.getStorageAggregatedFields(storagePoint)),
      LNGPoints: physicalPoints
        .filter((physicalPoint) => physicalPoint.pointType === VirtualPointType.LNG)
        .map((LNGPoint) => FlowsMapHelperService.getLNGAggregatedFields(LNGPoint)),
      IPPoints: physicalPoints
        .filter((physicalPoint) => physicalPoint.pointType === VirtualPointType.IP)
        .map((IPPoint) => FlowsMapHelperService.getIPAggregatedFields(IPPoint))
    };
  }

  @Selector([FlowsMapSelectors.aggregatedWidgetPhysicalPoints, FlowsMapSelectors.selectedPointsWidgetTabType])
  public static activeWidgetPhysicalPoints(
    aggregatedPoints: WidgetPhysicalPointsModelUI,
    selectedPointWidgetType: VirtualPointType
  ): WidgetPhysicalPointModelUI[] {
    switch (selectedPointWidgetType) {
      case VirtualPointType.STO:
        return sortBy(aggregatedPoints.storagePoints, 'pointName');
      case VirtualPointType.LNG:
        return sortBy(aggregatedPoints.LNGPoints, 'pointName');
      case VirtualPointType.IP:
        return sortBy(aggregatedPoints.IPPoints, 'pointName');
      default:
        return sortBy(aggregatedPoints.storagePoints, 'pointName');
    }
  }

  @Selector([FlowsMapSelectors.activeWidgetPhysicalPoints])
  public static selectedPointsFilter(activeWidgetPhysicalPoints: WidgetPhysicalPointModelUI[]): PointOperatorsFilter {
    return FilterHelperService.getMyPointsWidgetFilter(activeWidgetPhysicalPoints as DropdownShowMyPointsFilter[]);
  }

  @Selector([FlowsMapSelectors.activeWidgetPhysicalPoints, FlowsMapSelectors.pointsWidgetFilter])
  public static filteredActivePoints(
    selectedPoints: WidgetPhysicalPointModelUI[],
    selectedPointsAppliedFilter: PointOperatorsFilter
  ): WidgetPhysicalPointModelUI[] {
    return FilterHelperService.getFilteredSelectedMyPointsWidget(selectedPoints, selectedPointsAppliedFilter);
  }

  @Selector([FlowsMapState])
  public static pointsWidgetFilter(state: FlowsMapStateModel): PointOperatorsFilter {
    return state.pointsWidgetFilter;
  }

  @Selector([FlowsMapState])
  public static physicalPointsLoading(state: FlowsMapStateModel): boolean {
    return state.physicalPoints.loading;
  }

  @Selector([FlowsMapState, FlowsMapSelectors.selectedPointsWidgetTabType, FlowsMapSelectors.selectedDate])
  public static getSelectedTabPhysicalPointDataByGasDay(
    state: FlowsMapStateModel,
    selectedPointsWidgetTabType: VirtualPointType,
    selectedDate: number
  ): TabPhysicalPointByGasDay {
    const myPointWidgetState = state.widgetsVisibilityState[FlowsMapWidgetTypesEnum.MY_POINTS];
    const gasDayStartDate = dateToGasDayRange(new Date(selectedDate)).timeFrom;

    if (!myPointWidgetState.hidden) {
      return { selectedPointsWidgetTabType, gasDayStartDate };
    }
  }

  @Selector([FlowsMapSelectors.selectedDate])
  public static isMergedGermanyMAState(selectedDate: number): boolean {
    return isAfter(selectedDate, mergedGermanyMADate) || isSameDay(selectedDate, mergedGermanyMADate);
  }
}
