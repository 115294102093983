import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { Role, User } from 'appy-gas-core';
import { UserSelectors } from './user.selectors';

import { SetUserInfo } from './user.actions';

@Injectable({
  providedIn: 'root'
})
export class UserFacadeService {
  @Select([UserSelectors.getUser])
  public readonly user$: Observable<User>;

  @Select([UserSelectors.getUserRole])
  public readonly role$: Observable<Role>;

  @Select([UserSelectors.isExclusiveUser])
  public readonly isExclusiveUser$: Observable<boolean>;

  @Select([UserSelectors.isExclusivePremiumUser])
  public readonly isExclusivePremiumUser$: Observable<boolean>;

  @Select(UserSelectors.isClassicUser)
  public readonly isClassicUser$: Observable<boolean>;

  @Dispatch()
  public setUserInfo(user: User): SetUserInfo {
    return new SetUserInfo(user);
  }
}
