// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":host{display:block}#show_more__toggle{padding:0;color:#7200da;text-decoration:underline;border:none;background:transparent;outline:none;cursor:pointer}#show_more__toggle,.show_more__text{font-family:Open Sans,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;font-size:12px}.show_more__text{margin-bottom:.3rem;color:#737b85}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();