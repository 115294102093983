import { Action, State, StateContext } from '@ngxs/store';

import { MarketAreaActivePoint, Operators } from 'appy-gas-core';
import { GetEePoints, GetOperators } from './common-entities.actions';

export interface CommonEntitiesStateModel {
  eePoints: MarketAreaActivePoint[];
  operators: Operators[];
}

@State<CommonEntitiesStateModel>({
  name: 'commonEntities',
  defaults: {
    eePoints: [],
    operators: []
  }
})
export class CommonEntitiesState {
  @Action(GetEePoints)
  private getEeVirtualPoints({ patchState }: StateContext<CommonEntitiesStateModel>, { eePoints }: GetEePoints): void {
    patchState({ eePoints });
  }

  @Action(GetOperators)
  private getOperators({ patchState }: StateContext<CommonEntitiesStateModel>, { operators }: GetOperators): void {
    patchState({ operators });
  }
}
