import { Selector } from '@ngxs/store';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { IApiUser, MarketAreaNameConfig, User } from 'appy-gas-core';

import { PointUIModel } from '../../profile/interfaces/point.model';
import {
  FullPortfolioInformationModel,
  PortfolioInformationModel
} from '../../profile/interfaces/portfolio-information.model';
import { SelectedPointsByMarketAreaModel } from '../../profile/interfaces/selected-points-by-market-area.model';
import { SelectedPointsByOperatorModel } from '../../profile/interfaces/selected-points-by-operator.model';
import { TransactionHistoryModel } from '../../profile/interfaces/transaction-history.model';
import { UserBadgesModel } from '../../profile/interfaces/user-badges.model';
import { MarketAreaFlagConfig } from '../../shared/constants/market-area-flag.config';
import { UpdateUserRoleResponseTypes } from '../../shared/enums/update-user-role-response-types.enum';
import { ProfileHelperService } from '../../shared/services/profile-helper.service';
import { ProfileState, ProfileStateModel } from './profile.state';

export class ProfileSelectors {
  @Selector([ProfileState])
  public static apiUsersList({ apiUsersList }: ProfileStateModel): IApiUser[] {
    return apiUsersList.data;
  }

  @Selector([ProfileState])
  public static portfolioInformation({ portfolioInformation }: ProfileStateModel): PortfolioInformationModel {
    return portfolioInformation.data;
  }

  @Selector([ProfileState])
  public static userProfile({ userProfile }: ProfileStateModel): User {
    return userProfile;
  }

  @Selector([ProfileState])
  public static userProfileNewsLetter({ userProfile }: ProfileStateModel): boolean {
    return userProfile.newsLetter;
  }

  @Selector([ProfileState])
  public static savedPointsIds({ savedPointsIds }: ProfileStateModel): number[] {
    return savedPointsIds.data;
  }

  @Selector([ProfileState])
  public static appiesCount({ appiesCount }: ProfileStateModel): number {
    return appiesCount.data;
  }

  @Selector([ProfileState])
  public static walletBalanceStatus({ balanceStatus }: ProfileStateModel): UpdateUserRoleResponseTypes {
    return balanceStatus;
  }

  @Selector([ProfileState])
  public static appiesTransactionHistory({ appiesTransactionHistory }: ProfileStateModel): TransactionHistoryModel[] {
    return appiesTransactionHistory.data;
  }

  @Selector([ProfileState])
  public static userBadges({ userBadges }: ProfileStateModel): UserBadgesModel[] {
    return userBadges.data;
  }

  @Selector([ProfileSelectors.savedPointsIds, ProfileSelectors.portfolioInformation])
  public static fullPortfolioInformation(
    savedPointsIds: number[],
    portfolioInformation: PortfolioInformationModel
  ): FullPortfolioInformationModel {
    const filteredUselessProfileInfo = ProfileHelperService.filterUselessProfileInfo(portfolioInformation);

    return { portfolioInformation: filteredUselessProfileInfo, savedPointsIds };
  }

  @Selector([ProfileSelectors.fullPortfolioInformation])
  public static getSelectedPointsAllInformation(
    fullPortfolioInformation: FullPortfolioInformationModel
  ): PointUIModel[] {
    const { portfolioInformation, savedPointsIds } = fullPortfolioInformation;

    return [...portfolioInformation.points, ...portfolioInformation.superPoints]
      .filter((point) => savedPointsIds.includes(point.id))
      .map((point) => ({
        ...point,
        operatorName: portfolioInformation.operators.find(({ operator }) => operator.id === point.operatorId).operator
          .name
      }));
  }

  @Selector([ProfileSelectors.getSelectedPointsAllInformation])
  public static getSelectedPointsByMarketArea(
    allInfoSelectedPoints: PointUIModel[]
  ): SelectedPointsByMarketAreaModel[] {
    const groupedPoints = groupBy(allInfoSelectedPoints, 'marketAreaId');

    return map(groupedPoints, (points, marketAreaId) => {
      return {
        marketArea: MarketAreaNameConfig[marketAreaId],
        marketAreaIcon: MarketAreaFlagConfig[marketAreaId],
        points
      };
    });
  }

  @Selector([ProfileSelectors.getSelectedPointsAllInformation])
  public static getSelectedPointsByOperator(allInfoSelectedPoints: PointUIModel[]): SelectedPointsByOperatorModel[] {
    const groupedPoints = groupBy(allInfoSelectedPoints, 'operatorName');

    return map(groupedPoints, (points, operator) => {
      return {
        operator,
        points
      };
    });
  }
}
