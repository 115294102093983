import { Component, HostBinding, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "appy-gas-signup-success",
  templateUrl: "./signup-success.component.html",
  styleUrls: ["../signup.component.scss", "./signup-success.component.scss"],
})
export class SignupSuccessComponent implements OnInit {
  @HostBinding("class.is-page") public isPage = true;
  public title = "Check your email!";
  public description = `We’ve sent a message to your email.
  Open it and follow the instruction.<br><br>If you have a Promo Code do not forget to use it in the Profile section.`;

  constructor(public router: Router, public route: ActivatedRoute) {}

  ngOnInit(): void {
    console.log("loading signup-success");
    var urlObject = new URL(document.location.href);
    var params = urlObject.searchParams;
    var email = params.get("email");
    console.log(email);
    if (!email) {
      // router.navigate(["/auth/login"]);
    }

    this.description = `We’ve sent a message to <b>${email}</b>.
Open it and follow the instruction.<br><br>If you have a Promo Code do not forget to use it in the Profile section.`;
  }
}
