import { Injectable } from '@angular/core';

const APPYGAS_COOKIES_KEY = 'APPYGAS_COOKIES';

@Injectable()
export class CookiesPopoverService {
  public isCookiesAccepted(): boolean {
    return JSON.parse(localStorage.getItem(APPYGAS_COOKIES_KEY));
  }
  public acceptCookies(): void {
    localStorage.setItem(APPYGAS_COOKIES_KEY, JSON.stringify(true));
  }

  public setCookies(key: string, value: number, time: number): void {
    const d = new Date();
    d.setTime(d.getTime() + time);
    const expires = 'expires=' + d.toUTCString();
    document.cookie = key + '=' + value + ';' + expires + ';path=/';
  }

  public getCookies(key: string): string {
    const name = key + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let c of ca) {
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }

      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }

    return '';
  }
}
