import { Component, OnInit } from '@angular/core';

import { CookiesPopoverService } from '../cookies-popover.service';

const APPYGAS_COOKIES_BROWSER_KEY = 'APPYGAS_COOKIES_BROWSER';
const APPYGAS_COOKIES_BROWSER_VALUE = 1;
const APPYGAS_COOKIES_BROWSER_LIFE = 30 * 24 * 60 * 60 * 1000; // month

@Component({
  selector: 'appy-gas-browser-popover',
  templateUrl: './browser-popover.component.html',
  styleUrls: ['./browser-popover.component.scss']
})
export class BrowserPopoverComponent implements OnInit {
  public visible = false;

  constructor(private cookiesPopoverService: CookiesPopoverService) {}

  public ngOnInit(): void {
    const isChrome =
      navigator.userAgent.toLowerCase().indexOf('chrome') > -1 && navigator.vendor.toLowerCase().indexOf('google') > -1;
    if (!isChrome) {
      this.visible = !this.cookiesPopoverService.getCookies(APPYGAS_COOKIES_BROWSER_KEY);
    }
  }

  public closeWording(): void {
    this.cookiesPopoverService.setCookies(
      APPYGAS_COOKIES_BROWSER_KEY,
      APPYGAS_COOKIES_BROWSER_VALUE,
      APPYGAS_COOKIES_BROWSER_LIFE
    );
    this.visible = !this.cookiesPopoverService.getCookies(APPYGAS_COOKIES_BROWSER_KEY);
  }
}
