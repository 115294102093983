import { Component, Input } from '@angular/core';

@Component({
  selector: 'appy-gas-link-with-icon',
  templateUrl: './link-with-icon.component.html',
  styleUrls: ['./link-with-icon.component.scss']
})
export class LinkWithIconComponent {
  @Input() public icon: any;
  @Input() public href: string;
  @Input() public target: string;
  @Input() public text: string;
}
