import { Store } from '@ngxs/store';
import * as decode from 'jwt-decode';
import { StateResetAll } from 'ngxs-reset-plugin';
import { BehaviorSubject, Observable, of as ObservableOf } from 'rxjs';
import * as spacetime from 'spacetime';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BaseAuthService, Role, TokenType, User } from 'appy-gas-core';

import { APPYGAS_TOKENS } from './auth-config';

@Injectable()
export class AuthenticationService extends BaseAuthService {
  public roleSource: BehaviorSubject<Role> = new BehaviorSubject<Role>(Role.Guest);
  private roleExpSource: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);

  public roleExpDays: Observable<number> = this.roleExpSource.asObservable();
  public role: Observable<Role> = this.roleSource.asObservable();

  constructor(public httpClient: HttpClient, private store: Store) {
    super(httpClient);

    const token = this.getToken(TokenType.ACCESS);

    this.propagateToken(TokenType.ACCESS, token);
    this.saveRole(token);
  }

  public getToken(type: TokenType): string {
    return localStorage.getItem(APPYGAS_TOKENS[type]); // TODO: create a localStorage service;
  }

  public getUser(): Observable<User> {
    if (!this._token) {
      return ObservableOf(null);
    }
    return this.userinfo();
  }

  public logout(): Observable<boolean> {
    this.store.dispatch(new StateResetAll());

    return super.logout();
  }

  protected saveToken(type: TokenType, token: string): string {
    if (type === TokenType.ACCESS) {
      this.saveRole(token);
    }

    if (token) {
      localStorage.setItem(APPYGAS_TOKENS[type], token);
    } else {
      localStorage.removeItem(APPYGAS_TOKENS[type]);
    }
    return this.propagateToken(type, token);
  }

  public flushRole(): void {
    this.roleSource.next(Role.Guest);
  }

  public allowRoles(names: Role | Role[]): Promise<boolean> {
    if (!names || names === Role.All || (Array.isArray(names) && (names.length === 0 || names.includes(Role.All)))) {
      return Promise.resolve(true);
    }
    return this.hasRoleKey(names);
  }

  private saveRole(token: string): void {
    if (token) {
      const tokenPayload: any = decode(token);
      this.roleSource.next(parseInt(tokenPayload.roleId, 10));
      this.setRoleExpiration(tokenPayload.roleExpDay);
    }
  }

  private setRoleExpiration(roleExpDay: string): void {
    const expirationDate = spacetime(parseInt(roleExpDay, 10) * 1000);
    if (expirationDate.year() >= 5000) {
      this.roleExpSource.next(null);
      return;
    }
    const roleLeftDays = spacetime.now().diff(expirationDate, 'day');
    this.roleExpSource.next(roleLeftDays);
  }

  private hasRoleKey(roleName: Role | Role[]): Promise<boolean> {
    if (Array.isArray(roleName)) {
      return Promise.resolve(roleName.includes(this.roleSource.value));
    } else {
      return Promise.resolve(!!this.roleSource.value[roleName]);
    }
  }
}
