export enum UpdateUserRoleResponseTypes {
  NOT_ENOUGH_MONEY = '100',
  BALANCE_NOT_FOUND = '101',
  CONTRACT_DOESNT_ACTIVE = '102',
  CANNOT_FIND_CONTRACT = '103',
  CANNOT_FIND_CONTRACT_FOR_THIS_USER = '104',
  USER_ALREADY_HAS_EXCLUSIVE_ROLE = '105',
  ONLY_FOR_CLASSIC_USERS = '106',
  ONLY_FOR_TRIAL_CLASSIC_USER = '200',
  TRIAL_CLASSIC_RANGE_EXPIRED = '201',
  MAXIMUM_AMOUNT_OF_SEARCHES_PER_DAY = '301'
}
