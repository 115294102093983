import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { RollbarService } from './rollbar-service';

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private injector: Injector) {}

  public handleError(err: any): void {
    if (IS_PRODUCTION) {
      const rollbar = this.injector.get(RollbarService);
      rollbar.error(err.originalError || err);
    }
    throw err;
  }
}
