import { Store } from '@ngxs/store';
import { interval } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { AfterViewChecked, ChangeDetectorRef, Component, Inject, Injector, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationStart, NavigationEnd, Router } from '@angular/router';

import { EnvVariables, Logger, ModalService, Role } from 'appy-gas-core';

import * as META_TAGS from '../../config/meta-tags';
import { AuthModalHolderService } from './auth';
import { pushAlertInit, RouteChangeHandlerService, userSnapGlobalInit } from './core';
import { RoleNavigationHandlerService } from './core/role-navigation-handler/role-navigation-handler.service';
import { UserSelectors } from './store';

const log = new Logger('App');

@Component({
  selector: 'appy-gas-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewChecked {
  public currentTime = new Date().getTime();
  public endTimeDisplayBanner = new Date('2021-11-01').getTime();
  public is404Page = false;
  public user$ = this.store.select(UserSelectors.getUser);

  public isValidDate$ = interval(1000).pipe(map(() => this.currentTime < this.endTimeDisplayBanner));

  constructor(
    private routeChangeHandlerService: RouteChangeHandlerService,
    private roleNavigationHandler: RoleNavigationHandlerService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authModalHolder: AuthModalHolderService,
    private injector: Injector,
    private modalService: ModalService,
    private titleService: Title,
    private metaService: Meta,
    private changeDetector: ChangeDetectorRef,
    private store: Store,
    // tslint:disable-next-line:typedef
    @Inject(EnvVariables) private envVariables
  ) {}

  public ngOnInit(): void {
    if (IS_PRODUCTION) {
      // Setup logger
      Logger.enableProductionMode();
    }

    log.debug('init');
    this.routeChangeHandlerService.scrollToTopOnRouteChange();
    // switch between components in same modal to prevent circular dependency
    this.handleSubscriptions();
    this.updateMetaData();
    userSnapGlobalInit();
    pushAlertInit();
  }

  public ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  private getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    return activatedRoute.firstChild ? this.getChild(activatedRoute.firstChild) : activatedRoute;
  }

  private handleSubscriptions(): void {
    this.authModalHolder.onPut.pipe(filter((context) => !!context)).subscribe(() => {
      this.router.navigate(['/auth/login']);
    });
    this.roleNavigationHandler.onPut.pipe(filter((url) => !!url)).subscribe((url) => {
      this.router.navigate([url]);
    });
    this.router.events
      .pipe(filter((event) => event instanceof ActivationStart))
      .subscribe(({ snapshot }: { snapshot: ActivatedRouteSnapshot }) => {
        this.is404Page = snapshot.routeConfig.path === '**';
      });
  }

  private updateMetaData(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe((data) => {
        this.titleService.setTitle(`${data.title} | ${META_TAGS.title}`);
        this.metaService.updateTag({ name: 'title', content: `${data.title} | ${META_TAGS.title}` });
      });
    });
    this.metaService.updateTag({ name: 'description', content: META_TAGS.description });
  }
}
