import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import startsWith from 'lodash/startsWith';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import filterIcon from 'appy-gas-core/dist/assets/svg/icons/filter.svg';
import { PointFilterTypesEnum } from '../../../availability-pro/availability-pro-map/map-events-table/enums/point-filter-types.enum';
import { PointFilterTypes } from '../../../availability-pro/availability-pro-map/map-events-table/interfaces/point-filter-types.interface';
import { maintenanceFilterSyncConfig } from '../../constants/maintenance-filter-sync.config';
import { MaintenanceFilterTypeEnum } from '../../enums';
import { IPFilter, PointOperatorsFilter, TSOFilter } from '../../interfaces';

@Component({
  selector: 'appy-gas-search-points-dropdown',
  templateUrl: './search-points-dropdown.component.html',
  styleUrls: ['./search-points-dropdown.component.scss']
})
export class SearchPointsDropdownComponent implements OnChanges, OnInit, OnDestroy {
  @Input() public TSOIPFilters: PointOperatorsFilter;
  @Input() public snapshotFilters: PointOperatorsFilter;

  @Output() private updateSelectedPointEventsFilter: EventEmitter<PointOperatorsFilter> = new EventEmitter();

  public searchFilterForm: FormGroup = this.buildFilterFormGroup();
  public isSelectAll = false;
  public isOpen = false;
  public selectedPointsCount: number;
  public activeTabType: PointFilterTypesEnum;
  public pointTypes = PointFilterTypesEnum;
  public filterTabs: PointFilterTypes[] = [
    {
      label: 'Points',
      type: PointFilterTypesEnum.POINTS
    },
    {
      label: 'Operators',
      type: PointFilterTypesEnum.OPERATORS
    }
  ];
  public TSOFilters: TSOFilter[];
  public IPFilters: IPFilter[];
  public clonedTSOIPFilters: PointOperatorsFilter;

  public readonly filterIcon: SVGElement = filterIcon;

  private readonly componentDestroyed$: Subject<void> = new Subject<void>();

  public get allSelected(): boolean {
    return this.activeTabType === PointFilterTypesEnum.POINTS
      ? this.filteredIPBySearch.every((item) => item.selected)
      : this.filteredTSOBySearch.every((item) => item.selected);
  }

  public get filteredIPBySearch(): IPFilter[] {
    return this.IPFilters.filter((filterItem) => {
      return startsWith(
        filterItem.pointName.toUpperCase(),
        this.searchFilterForm.get('searchFilter').value.toUpperCase()
      );
    });
  }

  constructor(private fb: FormBuilder) {}

  public get filteredTSOBySearch(): TSOFilter[] {
    return this.TSOFilters.filter((filterItem) => {
      return startsWith(filterItem.name.toUpperCase(), this.searchFilterForm.get('searchFilter').value.toUpperCase());
    });
  }

  public ngOnInit(): void {
    this.activeTabType = PointFilterTypesEnum.POINTS;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.TSOIPFilters && !isEqual(changes.TSOIPFilters.currentValue, changes.TSOIPFilters.previousValue)) {
      this.clonedTSOIPFilters = cloneDeep(changes.TSOIPFilters.currentValue);
      this.syncWithWidgetFilter(this.snapshotFilters);
      this.activeTabType = PointFilterTypesEnum.POINTS;
      this.IPFilters = this.clonedTSOIPFilters.ip;
      this.TSOFilters = this.clonedTSOIPFilters.tso;
      this.searchFilterForm.get('searchFilter').setValue('');
    }
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public onSearchDropdownOpen(isOpen: boolean): void {
    this.isOpen = isOpen;
  }

  public onTabChange(pointTabType: PointFilterTypesEnum): void {
    this.activeTabType = pointTabType;
  }

  public toggleSelectAll(): void {
    if (this.allSelected) {
      this.filteredIPBySearch.forEach((item) => (item.selected = false));
      this.filteredTSOBySearch.forEach((item) => (item.selected = false));
    } else {
      this.filteredIPBySearch.forEach((item) => (item.selected = true));
      this.filteredTSOBySearch.forEach((item) => (item.selected = true));
    }
    this.toggleFilters();
  }

  public onItemClick(filterItem: IPFilter | TSOFilter): void {
    filterItem.selected = !filterItem.selected;
    const selectedIPItems = this.clonedTSOIPFilters.ip.filter((item) => item.selected);
    const selectedTSOItems = this.clonedTSOIPFilters.tso.filter((item) => item.selected);
    this.activeTabType === PointFilterTypesEnum.POINTS
      ? this.syncFilters<IPFilter>(selectedIPItems, MaintenanceFilterTypeEnum.IP)
      : this.syncFilters<TSOFilter>(selectedTSOItems, MaintenanceFilterTypeEnum.TSO);
    this.toggleFilters();
  }

  private buildFilterFormGroup(): FormGroup {
    return this.fb.group({
      searchFilter: ''
    });
  }

  private syncFilters<T>(selectedItems: T[], prop: MaintenanceFilterTypeEnum): void {
    const filterConfig = maintenanceFilterSyncConfig[prop];
    const syncItems = Array.from(new Set(selectedItems.map((selectedItem) => selectedItem[filterConfig.syncProperty])));

    this.clonedTSOIPFilters[filterConfig.fieldName].forEach((filterItem) => {
      filterItem.selected =
        filterConfig.property === 'value'
          ? (filterItem.selected = flatten(syncItems).some(
              (syncItem) => filterItem[filterConfig.property] === syncItem
            ))
          : (filterItem.selected = syncItems.some((syncItem) => filterItem[filterConfig.property].includes(syncItem)));
    });
  }

  private toggleFilters(): void {
    const ip = this.clonedTSOIPFilters.ip;
    const tso = this.clonedTSOIPFilters.tso;
    const eventsWidgetFilter = {
      ip,
      tso,
      searchQuery: this.searchFilterForm.get('searchFilter').value
    };
    this.updateSelectedPointEventsFilter.emit(eventsWidgetFilter);
    this.selectedPointsCount = this.clonedTSOIPFilters.ip.filter((point) => point.selected).length;
  }

  private syncWithWidgetFilter(widgetFilter: PointOperatorsFilter): void {
    if (!widgetFilter) {
      return;
    }

    this.clonedTSOIPFilters.ip.forEach((ipItem) => {
      const findSameFilter = widgetFilter.ip.find((cl) => cl.value === ipItem.value);
      ipItem.selected = findSameFilter ? findSameFilter.selected : false;
    });
    this.clonedTSOIPFilters.tso.forEach((tsoItem) => {
      const findSameFilter = widgetFilter.tso.find((cl) => cl.value === tsoItem.value);
      tsoItem.selected = findSameFilter ? findSameFilter.selected : false;
    });
    setTimeout(() => this.toggleFilters()); // TODO: fix it
  }
}
