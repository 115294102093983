// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".restricted-market-area__header{font-size:30px;font-weight:600;font-family:Montserrat;margin-bottom:1.5rem;padding:30px 50px;border-bottom:1px solid rgba(0,0,0,.125)}.restricted-market-area__wrapper{padding:22px 50px 30px}.restricted-market-area__description-block{display:-ms-flexbox;display:flex}.restricted-market-area__description{font-size:14px;line-height:16px;margin-bottom:20px}.restricted-market-area__description p:first-child{margin-bottom:40px}.restricted-market-area__description-img{height:127px;position:relative;left:25px}.restricted-market-area__btn--okay{margin-right:50px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();