// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".cookies-popover{width:100%;height:75px;position:fixed;bottom:0;background-color:#fff;z-index:999;font-size:12px;padding:1rem}.cookies-popover__close-icon{position:absolute;right:1rem;top:1rem;cursor:pointer}.cookies-popover__container{max-width:800px;margin:0 auto;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.cookies-popover__link{color:#7200da}.cookies-popover__text{font-size:16px}.cookies-popover__button{color:#fff;background-color:#7200da;border:2px solid #7200da;padding:9px 15px;white-space:nowrap;border-radius:3px;font-size:14px;font-family:Montserrat;font-weight:500;text-align:center;text-transform:uppercase;line-height:normal}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();