import { State } from '@ngxs/store';

import { AuctionProductPeriod, CurrencyType, PeriodType, QualityType, RouteCalculatorFilters } from 'appy-gas-core';

import { RouteCalculatorForm } from '../../route-calculator/interfaces/route-calculator-form.interface';

export interface RouteCalculatorStateModel {
  form: {
    model: RouteCalculatorForm;
    status: string;
    dirty: boolean;
    errors: {};
  };
  filtersForm: {
    model: RouteCalculatorFilters;
    status: string;
    dirty: boolean;
    errors: {};
  };
}

export const defaultFormState: RouteCalculatorForm = {
  marketAreas: [],
  quantity: null,
  isAvailable: false,
  period: PeriodType.DA,
  auctionProduct: AuctionProductPeriod.DAILY,
  quality: QualityType.FIRM,
  currency: CurrencyType.Euro
};

@State<RouteCalculatorStateModel>({
  name: 'routeCalculator',
  defaults: {
    form: {
      model: defaultFormState,
      dirty: false,
      status: '',
      errors: {}
    },
    filtersForm: {
      model: <RouteCalculatorFilters>{},
      dirty: false,
      status: '',
      errors: {}
    }
  }
})
export class RouteCalculatorState {}
