import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from 'appy-gas-core';
import { TermsService } from './terms.service';

@Component({
  selector: 'appy-gas-terms-of-service-update',
  templateUrl: './terms-of-service-update.component.html',
  styleUrls: ['../user-transition-modals.scss']
})
export class TermsOfServiceUpdateComponent {

  constructor(private modalService: ModalService, private router: Router, private termsService: TermsService) { }

  acceptTerms() {
    this.modalService.hide();
    this.termsService.acceptTermsOfUse()
    .subscribe(() => { 
      window.location.reload();
    });
  }
}
