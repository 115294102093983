// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".transition-modal__benefits{margin-top:25px;margin-bottom:65px}.transition-modal__right-sidebar{min-width:140px}.transition-modal__radio-item{display:-ms-flexbox;display:flex}.transition-modal__radio-item:first-child{margin-bottom:10px}.transition-modal__radio-item /deep/ .appy-gas-radio-button-label{font-size:12px}.transition-modal__radio-item /deep/ .radio-button.checked{border:1px solid #3288c9}.transition-modal__radio-item /deep/ .appy-gas-radio-button .radio-button.checked .radio-button-point{background-color:#3288c9}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();