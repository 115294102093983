import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'appy-gas-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent implements OnChanges {
  @Input() public text: string;
  @Input() public length: number;
  @Input() public divide = false;

  public showMore: boolean;
  public showLess = false;
  public fullText: string;

  public ngOnChanges(): void {
    if (this.divide) {
      this.text = this.splitParagraphs();
    }
    this.fullText = this.text;
    this.checkLength();
  }

  public splitParagraphs(): string {
    return '<p>' + this.text.split(/[\r\n\t]+/gm).join('</p><p>') + '</p>';
  }

  public checkLength(): void {
    if (this.length < (this.text && this.text.length)) {
      this.showMore = true;
      this.text = this.text.slice(0, this.length) + '...';
    }
  }

  public show(expand: boolean): void {
    this.showLess = expand;
    this.showMore = expand ? false : true;
    this.text = expand ? this.fullText : this.fullText.slice(0, this.length) + '...';
  }
}
