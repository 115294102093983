import { Component, Input } from '@angular/core';

@Component({
  selector: 'appy-gas-badge-icon',
  templateUrl: './badge-icon.component.html',
  styles: ['']
})
export class BadgeIconComponent {
  @Input() public fill = '#B1B9C3';
}
