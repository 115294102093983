import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-upgrade-premium',
  templateUrl: './upgrade-premium.component.html',
  styleUrls: ['./upgrade-premium.component.scss']
})
export class UpgradePremiumComponent {
  constructor(private modalService: ModalService, private router: Router) {}

  public closeModal(): void {
    this.modalService.hide();
  }

  public goToContact(): void {
    this.closeModal();
    this.router.navigate(['/static/contact-us/upgrade-exclusive']);
  }
}
