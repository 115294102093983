import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { EnvironmentVariables, EnvVariables, HeaderMetaDataOptions, InterceptorConfig, Logger } from 'appy-gas-core';

export interface RequestCacheEntry {
  url: string;
  response: HttpResponse<any>;
  lastRead: number;
  maxCacheAge: number;
}

export abstract class RequestCache {
  public abstract get(req: HttpRequest<any>, headerMetaData: HeaderMetaDataOptions): HttpResponse<any> | undefined;
  public abstract put(req: HttpRequest<any>, response: HttpResponse<any>, headerMetaData: HeaderMetaDataOptions): void;
}
const log = new Logger('HttpCaching');

@Injectable()
export class RequestCacheWithMap implements RequestCache {
  private cacheEntries = new Map<string, RequestCacheEntry>();
  private config: InterceptorConfig;

  constructor(@Inject(EnvVariables) public envVariables: EnvironmentVariables) {
    this.config = new InterceptorConfig({ serverUrl: envVariables.serverUrl });
  }

  public get(req: HttpRequest<any>, headerMetaData: HeaderMetaDataOptions): HttpResponse<any> | undefined {
    const url = req.url;
    const cached = this.cacheEntries.get(url);

    if (!cached) {
      return undefined;
    }

    const isExpired = cached.lastRead < Date.now() - headerMetaData.cacheMaxAge;
    const expired = isExpired ? 'expired ' : '';

    log.info(`Found ${expired}cached response for "${url}".`);
    return isExpired ? undefined : cached.response;
  }

  public put({ url }: { url: string }, response: HttpResponse<any>, headerMetaData: HeaderMetaDataOptions): void {
    const requestCacheEntry = {
      url,
      response,
      lastRead: Date.now(),
      maxCacheAge: headerMetaData.cacheMaxAge
    };

    log.info(`Caching response from "${url}".`);
    this.cacheEntries.set(url, requestCacheEntry);

    // remove expired cache entries
    const expired = Date.now() - headerMetaData.cacheMaxAge;
    this.cacheEntries.forEach((cacheEntry) => {
      if (cacheEntry.lastRead < expired) {
        this.cacheEntries.delete(cacheEntry.url);
      }
    });
    log.info(`Request cache size: ${this.cacheEntries.size}.`);
  }
}
