// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader{position:absolute;top:0;right:0;bottom:0;left:0;margin:auto;border:8px solid transparent;border-top-color:#7200da;border-radius:50%;width:80px;height:80px;animation:spin 1s linear infinite;z-index:10000}@keyframes spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();