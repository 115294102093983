import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { ButtonModule } from 'appy-gas-core';

import { UpgradePremiumComponent } from './upgrade-premium.component';

@NgModule({
  imports: [CommonModule, RouterModule, ButtonModule],
  declarations: [UpgradePremiumComponent],
  exports: [UpgradePremiumComponent]
})
export class UpgradePremiumModule {}
