import { Selector } from '@ngxs/store';
import { CalendarEvent } from 'angular-calendar';
import isAfter from 'date-fns/isAfter';
import isSameDay from 'date-fns/isSameDay';
import defaultsDeep from 'lodash/defaultsDeep';
import head from 'lodash/head';
import map from 'lodash/map';
import sum from 'lodash/sum';

import { Params } from '@angular/router';

import {
  EventDetailsModel,
  EventDetailsModelUI,
  EventDetailsPointInfoModel,
  MaintenanceConfig,
  MaintenanceConfigItem,
  MaintenanceEventModel,
  MaintenanceEventsModel,
  MaintenanceEventUIModel,
  MaintenanceMapPoint,
  MappedMaintenanceEventUIModel,
  MarketAreaNameConfig,
  RangeContext,
  RelatedPointsMaintenanceModel,
  RelatedPointsUIModel,
  SuperPointsConfig
} from 'appy-gas-core';

import { ImpactChartDataUIModel } from '../../availability-pro/availability-pro-general-info/impact-widget/interfaces/impact-chart-data.model';
import { MaintenanceMapRoutePointUI } from '../../availability-pro/availability-pro-map/availability-pro-market-areas-map/interfaces';

import { MaintenanceFiltersModel } from '../../availability-pro/availability-pro-map/availability-pro-map-filter/interfaces';
import { NorwayConfig } from '../../availability-pro/availability-pro-map/availability-pro-market-areas-map/norway-icon';
import { mergedGermanyMADate, norwayDataSourceId, virtualStorageIds } from '../../shared';
import {
  virtualPointIdsAfterGermanyMerge,
  virtualPointIdsBeforeGermanyMerge
} from '../../shared/constants/app-exceptions';
import { MAEventsTabDirection, OperatorTypesEnum, StorageDirectionEnum, UserPointTypesEnum } from '../../shared/enums';
import { MAEventsTab, PointOperatorsFilter } from '../../shared/interfaces';
import { AvailabilityHelperService } from '../../shared/services/availability-helper.service';
import { FilterHelperService } from '../../shared/services/filter-helper.service';
import { CommonEntitiesState, CommonEntitiesStateModel } from '../common-entities';
import { RouterSelectors } from '../router';
import {
  AvailabilityState,
  AvailabilityStateModel,
  GeneralInfoModel,
  WidgetsVisibilityModel
} from './availability.state';

const POINTS_CONFIG = defaultsDeep({}, SuperPointsConfig, MaintenanceConfig, NorwayConfig) as MaintenanceConfigItem;

export class AvailabilitySelectors {
  @Selector([AvailabilityState])
  public static gasDayRange(state: AvailabilityStateModel): RangeContext {
    return state.gasDayRange;
  }

  @Selector([AvailabilityState])
  public static allMaintenancePoints(state: AvailabilityStateModel): MaintenanceMapPoint[] {
    return state.allMaintenancePoints;
  }

  @Selector([AvailabilityState])
  public static widgetsVisibilityState(state: AvailabilityStateModel): WidgetsVisibilityModel {
    return state.widgetsVisibilityState;
  }

  @Selector([AvailabilityState])
  public static activeMATabType(state: AvailabilityStateModel): MAEventsTabDirection {
    return state.activeMATabType;
  }

  @Selector([AvailabilityState])
  public static portfolioMaintenancePoints(state: AvailabilityStateModel): MaintenanceMapPoint[] {
    return state.portfolioMaintenancePoints.data;
  }

  @Selector([AvailabilityState])
  public static selectedPointType(state: AvailabilityStateModel): UserPointTypesEnum {
    return state.pointsSwitcher;
  }

  @Selector([AvailabilityState])
  public static maintenanceFilters(state: AvailabilityStateModel): MaintenanceFiltersModel {
    return state.maintenanceFilters;
  }

  @Selector([AvailabilityState])
  public static selectedMapPointId(state: AvailabilityStateModel): string {
    return state.selectedMapPointId;
  }

  @Selector([AvailabilityState])
  public static getNavigatedFromExternalPageStatus(state: AvailabilityStateModel): boolean {
    return state.isNavigatedFromExternalPage;
  }

  @Selector([AvailabilityState])
  public static getNavigatedFromGeneralInfoPageStatus(state: AvailabilityStateModel): boolean {
    return state.isNavigatedFromGeneralInfoPage;
  }

  @Selector([AvailabilityState])
  public static isNorwayPointSelectedStatus(state: AvailabilityStateModel): boolean {
    return state.isNorwayPointSelected;
  }

  @Selector([AvailabilityState])
  public static getSelectedRouteEvents(state: AvailabilityStateModel): MaintenanceEventModel[] {
    return state.selectedRouteEvents.data;
  }

  @Selector([AvailabilityState])
  public static getSelectedPointEvents(state: AvailabilityStateModel): MaintenanceEventModel[] {
    return state.selectedPointEvents.data;
  }

  @Selector([
    AvailabilitySelectors.getSelectedRouteEvents,
    AvailabilitySelectors.getSelectedPointEvents,
    AvailabilitySelectors.getNavigatedFromGeneralInfoPageStatus
  ])
  public static shouldUpdateMaintenanceData(
    getSelectedRouteEvents: MaintenanceEventModel[],
    getSelectedPointEvents: MaintenanceEventModel[],
    getNavigatedFromGeneralInfoPageStatus: boolean
  ): boolean {
    return !getNavigatedFromGeneralInfoPageStatus || (!getSelectedPointEvents && !getSelectedRouteEvents);
  }

  @Selector([AvailabilityState])
  public static getAllEventPoints(state: AvailabilityStateModel): MaintenanceEventsModel[] {
    return state.allEventPoints.data;
  }

  @Selector([AvailabilityState, AvailabilitySelectors.selectedPointType, AvailabilitySelectors.isMergedGermanyMAState])
  public static filteredMaintenancePoints(
    state: AvailabilityStateModel,
    selectedPointType: UserPointTypesEnum,
    isMergedGermanyMAState: boolean
  ): MaintenanceMapPoint[] {
    const { maintenanceFilters, allMaintenancePoints, portfolioMaintenancePoints } = state;
    const pointsBySelectedPointType =
      selectedPointType === UserPointTypesEnum.MY_PORTFOLIO ? portfolioMaintenancePoints.data : allMaintenancePoints;
    const filteredPointsByMergedGermanyMAState = isMergedGermanyMAState
      ? pointsBySelectedPointType.filter((point) => !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId))
      : pointsBySelectedPointType.filter((point) => !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId));

    return map(filteredPointsByMergedGermanyMAState, (maintenancePoint) => {
      return AvailabilityHelperService.filterMaintenancePoints(maintenancePoint, maintenanceFilters);
    });
  }

  @Selector([AvailabilitySelectors.filteredMaintenancePoints])
  public static allEventsCount(maintenanceMapPoint: MaintenanceMapPoint[]): number {
    return sum(map(maintenanceMapPoint, (point) => point.maintenanceGeneralFiltered.length));
  }

  @Selector([AvailabilityState, AvailabilitySelectors.selectedPointType])
  public static maintenanceNorwayRelatedPoints(
    state: AvailabilityStateModel,
    selectedPointType: UserPointTypesEnum
  ): MaintenanceMapPoint[] {
    const { maintenanceFilters, allMaintenancePoints, portfolioMaintenancePoints } = state;
    const pointsBySelectedPointType =
      selectedPointType === UserPointTypesEnum.MY_PORTFOLIO ? portfolioMaintenancePoints.data : allMaintenancePoints;
    const pointsGasscoEventsIncluded = pointsBySelectedPointType.filter((maintenancePoint) => {
      const maintenanceGassco = maintenancePoint.maintenanceGeneral.filter(
        (event) => event.dataSourceId === norwayDataSourceId
      );
      return maintenanceGassco.length ? maintenancePoint : false;
    });
    return pointsGasscoEventsIncluded.map((points) => {
      return AvailabilityHelperService.filterMaintenancePoints(points, maintenanceFilters, true);
    });
  }

  @Selector([AvailabilityState])
  public static selectedRoutePoints(state: AvailabilityStateModel): MaintenanceMapRoutePointUI[] {
    return state.selectedRoutePoints.filter(
      (routePoint) => POINTS_CONFIG[routePoint.appyGasId].name === routePoint.name
    );
  }

  @Selector([AvailabilityState])
  public static generalInfo(state: AvailabilityStateModel): GeneralInfoModel {
    return state.generalInfo;
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static relatedPointsLoading(generalInfo: GeneralInfoModel, queryParams: Params): boolean {
    const { pointId } = queryParams;

    return generalInfo.relatedPoints[pointId].loading;
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static relatedPoints(generalInfo: GeneralInfoModel, queryParams: Params): RelatedPointsMaintenanceModel[] {
    const { pointId } = queryParams;

    return generalInfo.relatedPoints[pointId].data;
  }

  @Selector([AvailabilitySelectors.relatedPoints, CommonEntitiesState])
  public static relatedPointsInfo(
    relatedPoints: RelatedPointsMaintenanceModel[],
    commonEntitiesState: CommonEntitiesStateModel
  ): RelatedPointsUIModel[] {
    const { operators, eePoints } = commonEntitiesState;

    return AvailabilityHelperService.transformRelatedPoints(relatedPoints, eePoints, operators);
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static impactChartData(generalInfo: GeneralInfoModel, queryParams: Params): ImpactChartDataUIModel {
    const { pointId } = queryParams;
    const eventDetails = generalInfo.eventDetails[pointId].data;
    const pointData = generalInfo.impactChartData[pointId].data;
    const availableData = pointData.filter((pointItem) => pointItem.value !== 0);
    const filteredPoints = pointData.filter(
      (pointItem) =>
        !AvailabilityHelperService.filterRemainingTacByAvailableOperators(pointItem, eventDetails.expectedInterruption)
    );

    if (availableData.length && eventDetails) {
      return AvailabilityHelperService.transformImpactChartData(filteredPoints, eventDetails);
    }
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static impactChartDataLoading(generalInfo: GeneralInfoModel, queryParams: Params): boolean {
    const { pointId } = queryParams;
    return generalInfo.impactChartData[pointId].loading;
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static eventDetails(generalInfo: GeneralInfoModel, queryParams: Params): EventDetailsModel {
    const { pointId } = queryParams;

    return generalInfo.eventDetails[pointId].data;
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static eventDetailsLoading(generalInfo: GeneralInfoModel, queryParams: Params): boolean {
    const { pointId } = queryParams;
    return generalInfo.eventDetails[pointId].loading;
  }

  @Selector([AvailabilitySelectors.eventDetails])
  public static eventDetailsUI(eventDetails: EventDetailsModel): EventDetailsModelUI {
    return AvailabilityHelperService.transformEventDetails(eventDetails);
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams])
  public static otherEventsLoading(generalInfo: GeneralInfoModel, queryParams: Params): boolean {
    const { pointId } = queryParams;
    return generalInfo.otherEvents[pointId].loading;
  }

  @Selector([AvailabilitySelectors.generalInfo, RouterSelectors.queryParams, CommonEntitiesState])
  public static otherEventsUI(
    generalInfo: GeneralInfoModel,
    queryParams: Params,
    commonEntitiesState: CommonEntitiesStateModel
  ): CalendarEvent[] {
    const { eePoints } = commonEntitiesState;
    const { pointId } = queryParams;

    return AvailabilityHelperService.getCalendarEventWithAppropriateClasses(
      generalInfo.otherEvents[pointId].data,
      eePoints
    );
  }

  @Selector([AvailabilitySelectors.eventDetails, CommonEntitiesState])
  public static pointInfo(
    eventDetails: EventDetailsModel,
    commonEntitiesState: CommonEntitiesStateModel
  ): EventDetailsPointInfoModel {
    const { eePointId, operatorId, operatorTypeId, capacityType, updatedByAppygas, publishedByTSO, directionId } =
      eventDetails;
    const { operators, eePoints } = commonEntitiesState;
    const eePoint = eePoints.find((point) => point.eePointId === eePointId);

    return {
      name: eePoint?.name,
      operator: operators.find(({ id }) => id === operatorId)?.name,
      operatorTypeId,
      capacityType,
      publishedByTSO: publishedByTSO ? AvailabilityHelperService.getZonedUTCTime(publishedByTSO) : null,
      updatedByAppygas: updatedByAppygas ? AvailabilityHelperService.getZonedTime(updatedByAppygas) : null,
      directionId,
      pointTypeId: eePoint?.eePointTypeId,
      pointId: eePointId,
      tooltipData: AvailabilityHelperService.getEventTooltip(eventDetails.eePointId)
    };
  }

  @Selector([AvailabilitySelectors.selectedRoutePoints, AvailabilitySelectors.isMergedGermanyMAState])
  public static maintenanceRoutePoints(
    selectedRoutePoints: MaintenanceMapRoutePointUI[],
    isMergedGermanyMAState: boolean
  ): MaintenanceMapRoutePointUI[] {
    const mappedMaintenanceRoutePoints = map(POINTS_CONFIG, (maintenancePoint, appyGasId: string) => {
      return {
        appyGasId,
        name: maintenancePoint.name,
        discPosition: maintenancePoint.discPosition,
        isActiveRoute: selectedRoutePoints.some((routePoint) => routePoint.appyGasId === appyGasId)
      };
    });

    return isMergedGermanyMAState
      ? mappedMaintenanceRoutePoints.filter((point) => !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId))
      : mappedMaintenanceRoutePoints.filter((point) => !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId));
  }

  @Selector([AvailabilitySelectors.getSelectedPointEvents])
  public static eventsTab(selectedPointEvents: MaintenanceEventModel[]): MAEventsTab[] {
    const tabs = head(selectedPointEvents).marketAreasIds;
    const appyGasId = head(selectedPointEvents).virtualPointId;
    const mainDirection = `${MarketAreaNameConfig[tabs[0]]} ➞ ${MarketAreaNameConfig[tabs[1]]}`;
    const reverseDirection = `${MarketAreaNameConfig[tabs[1]]} ➞ ${MarketAreaNameConfig[tabs[0]]}`;
    return [
      {
        label: virtualStorageIds.includes(appyGasId) ? StorageDirectionEnum.INJECTION : mainDirection,
        type: MAEventsTabDirection.ENTRY_DIRECTION
      },
      {
        label: virtualStorageIds.includes(appyGasId) ? StorageDirectionEnum.WITHDRAWAL : reverseDirection,
        type: MAEventsTabDirection.EXIT_DIRECTION
      }
    ];
  }

  @Selector([AvailabilityState, CommonEntitiesState, AvailabilitySelectors.getSelectedPointEvents])
  public static mappedSelectedPointEvents(
    availabilityState: AvailabilityStateModel,
    commonEntitiesState: CommonEntitiesStateModel,
    selectedPointEvents: MaintenanceEventModel[]
  ): MaintenanceEventUIModel[] {
    const { operators, eePoints } = commonEntitiesState;
    const { maintenanceFilters, isNorwayPointSelected } = availabilityState;

    return AvailabilityHelperService.getMaintenanceUIEvents(
      operators,
      selectedPointEvents,
      eePoints,
      maintenanceFilters,
      isNorwayPointSelected
    );
  }

  @Selector([AvailabilitySelectors.getAllEventPoints])
  public static allEventPoints(allEventPoints: MaintenanceEventsModel[]): MaintenanceEventsModel[] {
    return allEventPoints.filter((eventPoint: MaintenanceEventsModel) => eventPoint.events.length);
  }

  @Selector([AvailabilitySelectors.allEventPoints, CommonEntitiesState])
  public static mappedAllPointEvents(
    allEventPoints: MaintenanceEventsModel[],
    commonEntitiesState: CommonEntitiesStateModel
  ): MappedMaintenanceEventUIModel[] {
    const { operators, eePoints } = commonEntitiesState;

    return AvailabilityHelperService.getMappedAllPointEvents(allEventPoints, operators, eePoints);
  }

  @Selector([AvailabilitySelectors.getSelectedRouteEvents, CommonEntitiesState])
  public static mappedSelectedRouteEvents(
    selectedRouteEvents: MaintenanceEventModel[],
    commonEntitiesState: CommonEntitiesStateModel
  ): MaintenanceEventUIModel[] {
    const { operators, eePoints } = commonEntitiesState;

    return AvailabilityHelperService.getMaintenanceUIEvents(operators, selectedRouteEvents, eePoints);
  }

  @Selector([AvailabilitySelectors.mappedSelectedPointEvents, AvailabilitySelectors.activeMATabType])
  public static selectedPointEvents(
    mappedEvents: MaintenanceEventUIModel[],
    activeMATabType: MAEventsTabDirection
  ): MaintenanceEventUIModel[] {
    return mappedEvents.filter((event) => {
      if (virtualStorageIds.includes(event.virtualPointId)) {
        return (
          (event.flowDirection === activeMATabType && event.operatorTypeId === OperatorTypesEnum.TSO) ||
          (event.flowDirection !== activeMATabType && event.operatorTypeId === OperatorTypesEnum.SSO)
        );
      } else {
        return event.flowDirection === activeMATabType;
      }
    });
  }

  @Selector([AvailabilitySelectors.selectedPointEvents])
  public static selectedPointEventsFilter(selectedPointEvents: MaintenanceEventUIModel[]): PointOperatorsFilter {
    return FilterHelperService.getEventPointsFilter(selectedPointEvents as MaintenanceEventUIModel[]);
  }

  @Selector([AvailabilitySelectors.selectedPointEvents, AvailabilitySelectors.eventsWidgetFilter])
  public static filteredSelectedPointEvents(
    selectedPointEvents: MaintenanceEventUIModel[],
    selectedPointEventsAppliedFilter: PointOperatorsFilter
  ): MaintenanceEventUIModel[] {
    return FilterHelperService.getFilteredSelectedEventPoints(
      selectedPointEvents,
      selectedPointEventsAppliedFilter
    ) as MaintenanceEventUIModel[];
  }

  @Selector([AvailabilityState])
  public static eventsWidgetFilter(state: AvailabilityStateModel): PointOperatorsFilter {
    return state.eventsWidgetFilter;
  }

  @Selector([AvailabilitySelectors.mappedSelectedPointEvents])
  public static selectedOneMADirectionEvents(mappedEvents: MaintenanceEventUIModel[]): MaintenanceEventUIModel[] {
    return mappedEvents.some((event) => event.isOneMADirection) ? mappedEvents : [];
  }

  @Selector([AvailabilitySelectors.gasDayRange])
  public static isMergedGermanyMAState(gasDayRange: RangeContext): boolean {
    return isAfter(gasDayRange.timeFrom, mergedGermanyMADate) || isSameDay(gasDayRange.timeFrom, mergedGermanyMADate);
  }
}
