import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { ModalService, RadioInput } from 'appy-gas-core/dist';

import { ExclusiveDaysPeriodEnum } from '../../../enums/exclusive-days-period.enum';

@Component({
  selector: 'appy-gas-switch-to-exclusive',
  templateUrl: './switch-to-exclusive.component.html',
  styleUrls: ['../user-transition-modals.scss', './switch-to-exclusive.component.scss']
})
export class SwitchToExclusiveComponent {
  public daysRadioInputs: RadioInput[] = [
    { label: '7 days (10 appies)', value: ExclusiveDaysPeriodEnum.SEVEN, disabled: false },
    { label: '30 days (30 appies)', value: ExclusiveDaysPeriodEnum.THIRTY, disabled: false }
  ];
  public switchToExclusiveForm: FormGroup = this.createSwitchToExclusiveForm();
  public proceedToNextStep: (period: ExclusiveDaysPeriodEnum) => void;
  public appiesCount: number;

  public get selectedPeriod(): ExclusiveDaysPeriodEnum {
    return this.switchToExclusiveForm.get('days').value;
  }

  constructor(private modalService: ModalService, private formBuilder: FormBuilder) {}

  public hideModal(): void {
    this.modalService.hide();
  }

  public switchToExclusive(): void {
    this.proceedToNextStep(this.selectedPeriod);
    this.modalService.hide();
  }

  private createSwitchToExclusiveForm(): FormGroup {
    return this.formBuilder.group({
      days: [ExclusiveDaysPeriodEnum.SEVEN]
    });
  }
}
