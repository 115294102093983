import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import uniqWith from 'lodash/uniqWith';

import { Injectable } from '@angular/core';

import { MaintenanceEventUIModel } from 'appy-gas-core';
import { WidgetPhysicalPointModelUI } from '../../flows-map/flows-map-area/flows-map-sidebar-pro/my-point-widget/interfaces';
import {
  DropdownPointsFilter,
  DropdownShowMyPointsFilter,
  IPFilter,
  PointOperatorsFilter,
  TSOFilter
} from '../interfaces';

@Injectable({
  providedIn: 'root'
})
export class FilterHelperService {
  public static getEventPointsFilter(physicalPoints: MaintenanceEventUIModel[]): PointOperatorsFilter {
    const mappedIP = physicalPoints.map((item) => {
      const pointId = item.pointId;
      return {
        name: `${item.pointName} (${item.operatorName})`,
        pointName: `${item.pointName}`,
        value: pointId,
        tsoId: [item.operatorId],
        selected: true
      };
    });
    const mappedTSO = physicalPoints.map((item) => {
      return {
        name: item.operatorName,
        value: item.operatorId,
        selected: true
      };
    });

    return this.getUniqFilter(mappedIP, mappedTSO);
  }

  public static getMyPointsWidgetFilter(physicalPoints: DropdownShowMyPointsFilter[]): PointOperatorsFilter {
    const mappedIP = physicalPoints.map((item) => {
      const operatorNames = item.operators.map((operator) => operator.name);
      const operatorIds = item.operators.map((operator) => operator.operatorId);
      return {
        name: `${item.pointName} (${operatorNames.join(', ')})`,
        pointName: `${item.pointName}`,
        value: item.eePointId,
        tsoId: operatorIds,
        selected: true
      };
    });
    const mappedTSO = physicalPoints.map((item) => {
      return item.operators.map((operator) => ({
        name: operator.name,
        value: operator.operatorId,
        selected: true
      }));
    });

    return this.getUniqFilter(mappedIP, flatten(mappedTSO));
  }

  public static getFilteredSelectedEventPoints(
    selectedPoints: MaintenanceEventUIModel[],
    selectedPointsAppliedFilter: PointOperatorsFilter
  ): DropdownPointsFilter[] {
    if (selectedPointsAppliedFilter) {
      const anySelectedFilter = selectedPointsAppliedFilter.ip.filter((IPFilterItem) => IPFilterItem.selected);
      if (anySelectedFilter.length) {
        return selectedPoints.filter((event) =>
          anySelectedFilter.some(
            (filterItem) =>
              filterItem.pointName === event.pointName && (filterItem.tsoId as number[]).includes(event.operatorId)
          )
        );
      } else {
        return [];
      }
    } else {
      return selectedPoints;
    }
  }

  public static getFilteredSelectedMyPointsWidget(
    selectedPoints: WidgetPhysicalPointModelUI[],
    selectedPointsAppliedFilter: PointOperatorsFilter
  ): WidgetPhysicalPointModelUI[] {
    if (selectedPointsAppliedFilter) {
      const selectedFilters = selectedPointsAppliedFilter.ip.filter((IPFilterItem) => IPFilterItem.selected);
      if (selectedFilters.length) {
        return selectedPoints.filter((point) =>
          selectedFilters.some((filterItem) => filterItem.pointName === point.pointName)
        );
      } else {
        return [];
      }
    } else {
      return selectedPoints;
    }
  }

  private static getUniqFilter(IPs: IPFilter[], TSOs: TSOFilter[]): PointOperatorsFilter {
    const ip = uniqWith(IPs, isEqual);
    const tso = uniqWith(TSOs, isEqual);

    return { ip: sortBy(ip, ['pointName']), tso: sortBy(tso, ['name']), searchQuery: '' };
  }
}
