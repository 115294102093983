import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { SvgIconModule } from 'appy-gas-core';

import { BrowserPopoverComponent } from './browser-popover/browser-popover.component';
import { CookiesPopoverComponent } from './cookies-popover.component';
import { CookiesPopoverService } from './cookies-popover.service';
import { TechnicalDebtPopoverComponent } from './technical-debt-popover/technical-debt-popover.component';
import { WelcomePopoverControllerComponent } from './welcome-popover-controller/welcome-popover-controller.component';

@NgModule({
  imports: [CommonModule, SvgIconModule, RouterModule, BrowserAnimationsModule, NgbModalModule],
  declarations: [
    CookiesPopoverComponent,
    BrowserPopoverComponent,
    TechnicalDebtPopoverComponent,
    WelcomePopoverControllerComponent
  ],
  providers: [CookiesPopoverService],
  exports: [
    CookiesPopoverComponent,
    BrowserPopoverComponent,
    TechnicalDebtPopoverComponent,
    WelcomePopoverControllerComponent
  ]
})
export class CookiesPopoverModule {}
