// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":host{-ms-flex:1;flex:1}@media (-ms-high-contrast:none),screen and (-ms-high-contrast:active){:host{-ms-flex:none;flex:none}}.technical-maintenance{height:100vh;-ms-flex-align:center;align-items:center;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;background-color:#fff}.technical-maintenance__image{margin-bottom:5.5rem}.technical-maintenance__logo{width:280px}.technical-maintenance__description{text-align:center;color:#2c2e3d;margin-bottom:5.5rem;font-weight:500;text-transform:none}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();