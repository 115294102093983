import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from 'appy-gas-core';

import { AuthenticationService } from '../../../core/http-auth/authentication.service';

@Component({
  selector: 'appy-gas-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent {
  constructor(
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private router: Router
  ) {}

  public closeModal(): void {
    this.modalService.hide();
  }

  public goToProfile(): void {
    this.closeModal();
    this.router.navigate(['/profile']);
  }

  public goToHomePage(): void {
    this.closeModal();
    this.authenticationService.logout().subscribe(() => {
      this.router.navigate(['/dashboard']);
    });
  }
}
