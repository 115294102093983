import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-trial-exclusive-access-expired',
  templateUrl: './trial-exclusive-access-expired.component.html',
  styleUrls: ['../user-transition-modals.scss']
})
export class TrialExclusiveAccessExpiredComponent {
  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }
}
