// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".technical-debt-popover{background-color:#f9c00c;width:100%;padding:10px 20px;text-align:center;z-index:10;min-width:1150px;position:relative}.technical-debt-popover__close-icon{position:absolute;cursor:pointer;right:20px;top:10px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();