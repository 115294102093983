import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpResponseBase
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { trialExpiredModalConfig } from '../../../auth';
import { trialExclusiveAccessExpiredConfig } from '../../../shared/components/user-transition-modals';
import { termsOfServiceUpdateConfig } from '../../../shared/components/user-transition-modals/terms-of-service-update';

import {
  EnvironmentVariables,
  EnvVariables,
  ErrorTypeListEnum,
  GrantType,
  InterceptorConfig,
  ModalService,
  Role,
  TokenType
} from 'appy-gas-core';
import { ProfileFacade } from '../../../store/profile';
import { AuthenticationService } from '../authentication.service';
import { AuthInterceptor } from './auth-interceptor';

@Injectable()
export class UserStatusInterceptor implements HttpInterceptor {
  private config: InterceptorConfig;
  private currentUserRole: number;

  constructor(
    @Inject(EnvVariables) public envVariables: EnvironmentVariables,
    private authService: AuthenticationService,
    private profileFacade: ProfileFacade,
    public toastService: ToastrService,
    public modalService: ModalService,
    public router: Router,
    private injector: Injector
  ) {
    this.config = new InterceptorConfig({ serverUrl: envVariables.serverUrl });
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.handleRoleChange(event);
          // Intercept if trial was expired and show modal
          if (event.headers.has(this.config.trialExpiredHeader) && event.headers.get(this.config.trialExpiredHeader)) {
            this.handleTrialExpired();
          }

          return event.clone();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        this.handleRoleChange(error);
        const termsOfServiceUpdated = error?.status === ErrorTypeListEnum.TERMS_OF_USE_UPDATED;
        if(termsOfServiceUpdated) {
          this.showTermsOfServiceUpdatedModal();
          return;
        }
        
        return throwError(error);
      })
    );
  }

  private handleRoleChange(event: HttpResponseBase | HttpErrorResponse): void {
    const isLoginPage = this.router.url.startsWith('/auth/login');
    const isRoleChanged = event?.headers?.has(this.config.roleChangedHeader);

    if (isLoginPage) {
      this.currentUserRole = -1;
    }

    if (isRoleChanged && !isLoginPage) {
      const prevUserRole = Number(event.headers.get(this.config.roleChangedHeader).split('-')[0]);
      const newUserRole = Number(event.headers.get(this.config.roleChangedHeader).split('-')[1]);

      if (this.currentUserRole !== newUserRole) {
        this.currentUserRole = newUserRole;
        const refreshToken: string = AuthInterceptor.getToken(TokenType.REFRESH);
        this.authService
          .refreshToken({
            refresh_token: refreshToken,
            grant_type: GrantType.REFRESH_TOKEN
          })
          .subscribe(
            () => {
              setTimeout(() => window.location.reload(), 2000);
              this.profileFacade.getWalletInformation();
            },
            () => {
              this.authService.logout();
              this.router.navigate(['/auth/login'], { queryParams: { licenseChanged: true } });
            }
          );

        if (prevUserRole === Role.TrialExclusive && newUserRole === Role.TrialClassic) {
          this.showExclusiveExpiredModal();
          return;
        }

        this.toastService.warning('Your type of license has been changed.', '', { timeOut: 10000 });
      }
    }

    if (event?.url?.includes('/accounts/userinfo') && isRoleChanged) {
      this.toastService.warning('Your type of license has been changed.', '', { timeOut: 10000 });
    }
  }

  private handleTrialExpired(): void {
    this.modalService.show(this.injector, trialExpiredModalConfig);
  }

  private showExclusiveExpiredModal(): void {
    this.modalService.show(this.injector, trialExclusiveAccessExpiredConfig);
  }

  private showTermsOfServiceUpdatedModal(): void {
    this.modalService.show(this.injector, termsOfServiceUpdateConfig);
  }
}
