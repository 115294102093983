// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".user-deactivated{padding:2rem 0}.user-deactivated__header{text-transform:none}.user-deactivated__header-wrapper{padding:0 3rem;text-align:center}.user-deactivated__text{padding:1rem 3rem;text-align:center;border-bottom:1px solid rgba(0,0,0,.125)}.user-deactivated__controls-wrapper{padding:0 3rem;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();