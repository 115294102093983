// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".transition-modal__checkbox-form{margin:24px 0 15px}.transition-modal__checkbox-form appy-gas-checkbox{display:-ms-flexbox;display:flex}.transition-modal /deep/ .appy-gas-checkbox__label{font-size:12px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();