import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-restricted-market-area-premium',
  templateUrl: './restricted-market-area-premium.component.html',
  styleUrls: ['./restricted-market-area-premium.component.scss']
})
export class RestrictedMarketAreaPremiumComponent {
  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }
}
