import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';

import { CheckboxItemModel } from './checkbox-item.model';

@Component({
  selector: 'appy-gas-checkbox-group',
  templateUrl: './checkbox-group.component.html',
  styleUrls: ['./checkbox-group.component.scss'],
  providers: [{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxGroupComponent), multi: true }]
})
export class CheckboxGroupComponent implements ControlValueAccessor {
  @Input() public groupInstance: FormGroup;
  @Input() public controlName: string;
  @Input() public options: CheckboxItemModel[];

  @Output() public clickedItemEmitter: EventEmitter<CheckboxItemModel> = new EventEmitter();

  public value: any;

  public onToggle(item: CheckboxItemModel): void {
    const control = this.getControl();
    const updatedValues = control.value.map((valueItem: CheckboxItemModel) => {
      if (valueItem.id === item.id) {
        valueItem.selected = !valueItem.selected;
      }

      return valueItem;
    });
    control.setValue(updatedValues);
    this.clickedItemEmitter.emit(item);
  }

  public writeValue(value: any): void {
    this.value = value;
  }

  public registerOnChange(fn: any): void {}

  public registerOnTouched(fn: any): void {}

  private getControl(): AbstractControl {
    return this.groupInstance && this.groupInstance.get(this.controlName);
  }
}
