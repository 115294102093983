// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".trial-expired{padding:2rem 3rem}.trial-expired__header{text-align:center;text-transform:none}.trial-expired__body{padding-top:1rem;padding-bottom:2rem}.trial-expired__text{text-align:center}.trial-expired__controls-wrapper{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();