// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".pop-up{position:relative;height:100%;visibility:visible;opacity:1;z-index:5;transition:.4s ease-in-out}.pop-up.hidden{height:0;opacity:0;visibility:hidden}.pop-up__wrapper{background-color:rgba(44,47,61,.9);border-radius:3px}.pop-up__arrow-right{border-left:5px solid rgba(44,47,61,.9);right:-5px}.pop-up__arrow-left,.pop-up__arrow-right{border-top:5px solid transparent;border-bottom:5px solid transparent;position:absolute;top:50%;transform:translateY(-50%)}.pop-up__arrow-left{border-right:5px solid rgba(44,47,61,.9);left:-5px}.pop-up__arrow-top{border-left:5px solid transparent;border-right:5px solid transparent;border-bottom:5px solid rgba(44,47,61,.9);position:absolute;right:7%;top:-5px}.pop-up__close-icon{cursor:pointer;position:absolute;top:24px;right:18px}.pop-up__header{position:absolute;top:0;background-color:#5c9ead;height:5px;border-radius:3px 0 0 0}.pop-up__header.full{border-radius:3px 3px 0 0}.pop-up__body{font-size:14px;padding:15px;line-height:1.43}.pop-up__body-single{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end;width:100%}.pop-up__body-single-skip{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;width:100%}.pop-up__content{color:#fff;margin-bottom:10px}.pop-up__buttons{-ms-flex-pack:justify;justify-content:space-between}.pop-up__btn,.pop-up__buttons{display:-ms-flexbox;display:flex;text-transform:uppercase}.pop-up__btn{-ms-flex-align:center;align-items:center;border-radius:3px;font-size:12px;padding:3px 10px;cursor:pointer}.pop-up__btn--skip{color:#5c9ead;padding:2px 0}.pop-up__btn--previous{margin-right:4px;border:1px solid #5c9ead;color:#5c9ead}.pop-up__btn--last{color:#fff;background-color:#5c9ead}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();