import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from '../shared';
import { TrialExpiredComponent, UpdateProfileComponent, UserDeactivatedComponent } from './auth-modals';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthComponent } from './auth.component';
import { ForgotPasswordComponent } from './forgot-password';
import { LoginComponent } from './login';
import { ResetPasswordComponent } from './reset-password';
import { SignupComponent } from './signup';

import { AuthModalHolderService } from './auth-modal-holder.service';
import { SignupSuccessComponent } from './signup/signup-success/signup-success.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, AuthRoutingModule, SharedModule],
  declarations: [
    LoginComponent,
    SignupComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AuthComponent,
    TrialExpiredComponent,
    UpdateProfileComponent,
    UserDeactivatedComponent,
    SignupSuccessComponent
  ],
  exports: [LoginComponent, SignupComponent, ForgotPasswordComponent],
  providers: [AuthModalHolderService],
  entryComponents: [TrialExpiredComponent, UpdateProfileComponent, UserDeactivatedComponent]
})
export class AuthModule {}
