import { Observable, of } from 'rxjs';
import { startWith, tap } from 'rxjs/operators';

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { HeaderMetaDataOptions } from 'appy-gas-core';

import { RequestCache } from '../request-cache.service';

/**
 * If request is cachable (e.g., package search) and
 * response is in cache return the cached response as observable.
 * If has 'x-refresh' header that is true,
 * then also re-run the package search, using response from next(),
 * returning an observable that emits the cached response first.
 *
 * If not in cache or not cachable,
 * pass request through to next()
 */
@Injectable()
export class CachingInterceptor implements HttpInterceptor {
  constructor(private cache: RequestCache) {}

  private static checkIfUrlIsCacheable(headerMetaData: HeaderMetaDataOptions): boolean {
    return !!(headerMetaData && headerMetaData.cache);
  }

  /** Is this request cachable? */
  private static isCacheable(req: HttpRequest<any>, headerMetaData: HeaderMetaDataOptions): boolean {
    // Only GET requests are cachable
    return req.method === 'GET' && CachingInterceptor.checkIfUrlIsCacheable(headerMetaData);
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const headerMetaData: HeaderMetaDataOptions = JSON.parse(req.headers.get('X-META-DATA'));

    // continue if not cachable.
    if (!CachingInterceptor.isCacheable(req, headerMetaData)) {
      return next.handle(req);
    }
    const cachedResponse = this.cache.get(req, headerMetaData);
    // cache-then-refresh
    // The cache-then-refresh option is triggered by the presence of a custom refreshCache header meta data.
    if (headerMetaData && headerMetaData.refreshCache) {
      const results$ = sendRequest(req, next, this.cache, headerMetaData);
      return cachedResponse ? results$.pipe(startWith(cachedResponse)) : results$;
    }
    // cache-or-fetch
    return cachedResponse ? of(cachedResponse) : sendRequest(req, next, this.cache, headerMetaData);
  }
}

/**
 * Get server response observable by sending request to `next()`.
 * Will add the response to the cache on the way out.
 */
function sendRequest(
  req: HttpRequest<any>,
  next: HttpHandler,
  cache: RequestCache,
  headerMetaData: HeaderMetaDataOptions
): Observable<HttpEvent<any>> {
  return next.handle(req).pipe(
    tap((event) => {
      if (event instanceof HttpResponse) {
        cache.put(req, event, headerMetaData); // Update the cache.
      }
    })
  );
}
