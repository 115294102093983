import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  GasDayRange,
  MarketAreaConfig,
  MarketAreaViewService,
  VirtualPointData,
  VirtualPointSearchParams
} from 'appy-gas-core';
import { MarketAreaViewPositionEnum } from '../../shared/enums';
import { GetMarketArea, GetPointDetails } from './market-area-view.actions';

@Injectable()
export class MarketAreaViewFacadeService {
  constructor(private marketAreaViewService: MarketAreaViewService, private toastr: ToastrService) {}

  @Dispatch()
  public getPointDetails(
    params: VirtualPointSearchParams,
    marketAreaPosition: MarketAreaViewPositionEnum
  ): Observable<GetPointDetails> {
    return this.marketAreaViewService.getPointDetails(params).pipe(
      catchError((err) => {
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((data: VirtualPointData) => new GetPointDetails(data, marketAreaPosition))
    );
  }

  @Dispatch()
  public getMarketArea(
    areaId: number,
    gasDayRange: GasDayRange,
    marketAreaPosition: MarketAreaViewPositionEnum
  ): Observable<GetMarketArea> {
    return this.marketAreaViewService.getMarketArea(areaId, gasDayRange).pipe(
      catchError((err) => {
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((marketAreaConfig: MarketAreaConfig) => new GetMarketArea(marketAreaConfig, marketAreaPosition))
    );
  }
}
