// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".browser-popover{width:100%;padding:10px;text-align:center;background-color:#f9c00c;z-index:9;min-width:1150px}.browser-popover h3{margin-left:-215px}.browser-popover .close-icon{position:absolute;right:20px;top:6px;padding:10px;opacity:1;transition:all .3s;cursor:pointer;text-decoration:underline}.browser-popover .close-icon:hover{opacity:.7}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();