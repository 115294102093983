import { Action, State, StateContext } from '@ngxs/store';
import mapValues from 'lodash/mapValues';

import { FlowsMapTabType, VirtualPoint, VirtualPointType, WidgetPhysicalPointModel } from 'appy-gas-core';

import { FlowsMapWidgetsVisibilityModel, FlowsMapWidgetTypesEnum } from '../../flows-map/interfaces';
import { UserPointTypesEnum } from '../../shared/enums';
import { PointOperatorsFilter } from '../../shared/interfaces';
import {
  ChangeFlowsMapPointTypes,
  ChangePointWidgetPointTypes,
  GetPhysicalPoints,
  GetPhysicalPointsFailed,
  GetPhysicalPointsSuccess,
  GetVirtualPoints,
  GetVirtualPointsFailed,
  GetVirtualPointsSuccess,
  SetActiveFilterTab,
  SetSelectedChartPoints,
  SetSelectedDate,
  SetSelectedTypesFilter,
  UpdateFlowsMapWidgetsVisibilityState,
  UpdateSelectedPointsFilter
} from './flows-map.actions';

export interface FlowsMapStateModel {
  pointsWidgetTabTypeSwitcher: VirtualPointType;
  pointsSwitcher: UserPointTypesEnum;
  pointsWidgetFilter: PointOperatorsFilter;
  virtualPoints: {
    data: VirtualPoint[];
    loading: boolean;
    loaded: boolean;
  };
  physicalPoints: {
    data: WidgetPhysicalPointModel[];
    loading: boolean;
    loaded: boolean;
  };
  selectedDate: number;
  activeFilterTab: FlowsMapTabType;
  selectedChartPoints: VirtualPoint[];
  selectedTypesFilter: VirtualPointType[];
  widgetsVisibilityState: FlowsMapWidgetsVisibilityModel;
}

@State<FlowsMapStateModel>({
  name: 'flowsMap',
  defaults: {
    pointsWidgetTabTypeSwitcher: VirtualPointType.IP,
    pointsSwitcher: UserPointTypesEnum.ALL_POINTS,
    pointsWidgetFilter: undefined,
    virtualPoints: {
      data: undefined,
      loading: false,
      loaded: false
    },
    physicalPoints: {
      data: undefined,
      loading: false,
      loaded: false
    },
    selectedDate: new Date().getTime(),
    activeFilterTab: FlowsMapTabType.COMMERCIAL,
    selectedChartPoints: undefined,
    selectedTypesFilter: [VirtualPointType.VP, VirtualPointType.VS, VirtualPointType.LNG, VirtualPointType.VIP],
    widgetsVisibilityState: {
      [FlowsMapWidgetTypesEnum.DATE_SELECTOR]: { id: FlowsMapWidgetTypesEnum.DATE_SELECTOR, hidden: true },
      [FlowsMapWidgetTypesEnum.COMMERCIAL_CHART]: { id: FlowsMapWidgetTypesEnum.COMMERCIAL_CHART, hidden: true },
      [FlowsMapWidgetTypesEnum.MY_POINTS]: { id: FlowsMapWidgetTypesEnum.MY_POINTS, hidden: true },
      [FlowsMapWidgetTypesEnum.LEGEND]: { id: FlowsMapWidgetTypesEnum.LEGEND, hidden: false }
    }
  }
})
export class FlowsMapState {
  @Action(GetVirtualPoints)
  private getVirtualPoints(ctx: StateContext<FlowsMapStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ virtualPoints: { ...state.virtualPoints, loading: true } });
  }

  @Action(GetVirtualPointsSuccess)
  private getVirtualPointsSuccess(
    { patchState }: StateContext<FlowsMapStateModel>,
    { virtualPoints }: GetVirtualPointsSuccess
  ): void {
    patchState({ virtualPoints: { data: virtualPoints, loaded: true, loading: false } });
  }

  @Action(GetVirtualPointsFailed)
  private getVirtualPointsFailed(ctx: StateContext<FlowsMapStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ virtualPoints: { ...state.virtualPoints, loaded: false, loading: false } });
  }

  @Action(UpdateSelectedPointsFilter)
  private updateSelectedPointsFilter(
    { patchState }: StateContext<FlowsMapStateModel>,
    { pointsWidgetFilter }: UpdateSelectedPointsFilter
  ): void {
    patchState({ pointsWidgetFilter });
  }

  @Action(GetPhysicalPoints)
  private getPhysicalPoints(ctx: StateContext<FlowsMapStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ physicalPoints: { ...state.physicalPoints, loading: true } });
  }

  @Action(GetPhysicalPointsSuccess)
  private getPhysicalPointsSuccess(
    { patchState }: StateContext<FlowsMapStateModel>,
    { physicalPoints }: GetPhysicalPointsSuccess
  ): void {
    patchState({ physicalPoints: { data: physicalPoints, loaded: true, loading: false } });
  }

  @Action(GetPhysicalPointsFailed)
  private getPhysicalPointsFailed(ctx: StateContext<FlowsMapStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ physicalPoints: { ...state.physicalPoints, loaded: false, loading: false } });
  }

  @Action(SetSelectedDate)
  private setSelectedDate({ patchState }: StateContext<FlowsMapStateModel>, { selectedDate }: SetSelectedDate): void {
    patchState({ selectedDate });
  }

  @Action(SetActiveFilterTab)
  private setActiveFilterTab(
    { patchState }: StateContext<FlowsMapStateModel>,
    { activeFilterTab }: SetActiveFilterTab
  ): void {
    patchState({ activeFilterTab });
  }

  @Action(SetSelectedChartPoints)
  private setSelectedChartPoints(
    { patchState }: StateContext<FlowsMapStateModel>,
    { selectedChartPoints }: SetSelectedChartPoints
  ): void {
    patchState({ selectedChartPoints });
  }

  @Action(SetSelectedTypesFilter)
  private setSelectedTypesFilter(
    { patchState }: StateContext<FlowsMapStateModel>,
    { selectedTypesFilter }: SetSelectedTypesFilter
  ): void {
    patchState({ selectedTypesFilter });
  }

  @Action(UpdateFlowsMapWidgetsVisibilityState)
  private updateFlowsMapWidgetsVisibilityState(
    ctx: StateContext<FlowsMapStateModel>,
    { selectedWidget }: UpdateFlowsMapWidgetsVisibilityState
  ): void {
    const state = ctx.getState();

    ctx.patchState({
      widgetsVisibilityState: mapValues(state.widgetsVisibilityState, (widget) => {
        const hidden = widget.id === selectedWidget.id ? selectedWidget.hidden : true;
        return {
          ...widget,
          hidden
        };
      })
    });
  }

  @Action(ChangePointWidgetPointTypes)
  private getActiveMATabType(
    { patchState }: StateContext<FlowsMapStateModel>,
    { pointsWidgetTabTypeSwitcher }: ChangePointWidgetPointTypes
  ): void {
    patchState({
      pointsWidgetTabTypeSwitcher
    });
  }

  @Action(ChangeFlowsMapPointTypes)
  private changePointTypes(
    { patchState }: StateContext<FlowsMapStateModel>,
    { pointsSwitcher }: ChangeFlowsMapPointTypes
  ): void {
    patchState({
      pointsSwitcher
    });
  }
}
