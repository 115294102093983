export enum LocalStorageKeysEnum {
  DO_NOT_SHOW_FEATURES_PRIVACY_POLICY_POP_UP = 'doNotShowFeaturesPrivacyPolicyPopUp',
  DO_NOT_SHOW_ROUTE_CALCULATOR_PRIVACY_POLICY_POP_UP = 'doNotShowRouteCalculatorPrivacyPolicyPopUp',
  DO_NOT_SHOW_PORTFOLIO_POP_UP_ON_MAP = 'doNotShowPortfolioPopUpOnMap',
  DO_NOT_SHOW_NEW_FEATURES_MODAL = 'doNotShowTHECommercialVideoModal',
  USER_ROLE_INFO_MODAL = 'userRoleInfoModal',
  USER_ROLE_EXCLUSIVE_MODAL = 'userRoleExclusiveModal',
  MERGED_GERMANY_POP_UP = 'mergedGermanyPopUp',
  USER_SELECTED_HUB_PRICES_LEGEND = 'USER_SELECTED_HUB_PRICES_LEGEND',
}
