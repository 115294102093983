import { NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule, CheckboxModule, SvgIconModule } from 'appy-gas-core';

import { NewFeaturesCarouselComponent } from './new-features-carousel/new-features-carousel.component';
import { WelcomePopoverComponent } from './welcome-popover.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    CheckboxModule,
    SvgIconModule,
    NgbCarouselModule
  ],
  declarations: [WelcomePopoverComponent, NewFeaturesCarouselComponent],
  exports: [WelcomePopoverComponent, NewFeaturesCarouselComponent]
})
export class WelcomePopoverModule {}
