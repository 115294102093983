import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

import { AuthModalType } from './auth-modal-type.enum';

export interface ModalHolderContext {
  type: AuthModalType;
  config: any;
}

@Injectable()
export class AuthModalHolderService {
  private currentModalConfig: BehaviorSubject<ModalHolderContext> = new BehaviorSubject<ModalHolderContext>(null);

  public onPut: Observable<ModalHolderContext> = this.currentModalConfig.asObservable();

  public put(modalType: AuthModalType, config: any = {}): void {
    this.currentModalConfig.next({ type: modalType, config });
  }
}
