import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { CalendarEvent } from 'angular-calendar';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  AvailabilityService,
  ChartDataItemModel,
  ErrorTypeListEnum,
  EventDetailsModel,
  EventDetailsModelUI,
  EventDetailsPointInfoModel,
  MaintenanceEventsModel,
  MaintenanceMapPoint,
  OtherEventModel,
  RangeContext,
  RelatedPointsMaintenanceModel,
  RelatedPointsModel,
  RelatedPointsUIModel
} from 'appy-gas-core';
import { ImpactChartDataUIModel } from '../../availability-pro/availability-pro-general-info/impact-widget/interfaces/impact-chart-data.model';
import { UserPointTypesEnum } from '../../shared/enums';
import {
  GetAllEventPoints,
  GetAllEventPointsFailed,
  GetAllEventPointsSuccess,
  GetAllMaintenancePoints,
  GetAvailabilityData,
  GetEventDetails,
  GetEventDetailsLoading,
  GetImpactChartData,
  GetImpactChartDataLoading,
  GetOtherEvents,
  GetOtherEventsLoading,
  GetPortfolioMaintenancePoints,
  GetPortfolioMaintenancePointsFailed,
  GetPortfolioMaintenancePointsSuccess,
  GetRelatedPoints,
  GetRelatedPointsLoading,
  GetSelectedNorwayEvents,
  GetSelectedPEGSuperPointsEvents,
  GetSelectedPointEvents,
  GetSelectedPointEventsFailed,
  GetSelectedPointEventsSuccess,
  GetSelectedRouteDetails,
  GetSelectedRouteDetailsFailed,
  GetSelectedRouteDetailsSuccess
} from './availability.actions';
import { AvailabilitySelectors } from './availability.selectors';

@Injectable()
export class AvailabilityFacade {
  @Select(AvailabilitySelectors.allMaintenancePoints)
  public maintenancePoints$: Observable<MaintenanceMapPoint[]>;

  @Select(AvailabilitySelectors.allEventPoints)
  public allEventPoints$: Observable<MaintenanceEventsModel[]>;

  @Select(AvailabilitySelectors.filteredMaintenancePoints)
  public filteredMaintenancePoints$: Observable<MaintenanceMapPoint[]>;

  @Select(AvailabilitySelectors.relatedPointsLoading)
  public relatedPointsLoading$: Observable<boolean>;

  @Select(AvailabilitySelectors.relatedPoints)
  public relatedPoints$: Observable<RelatedPointsMaintenanceModel[]>;

  @Select(AvailabilitySelectors.relatedPointsInfo)
  public relatedPointsInfo$: Observable<RelatedPointsUIModel[]>;

  @Select(AvailabilitySelectors.impactChartDataLoading)
  public impactChartDataLoading$: Observable<boolean>;

  @Select(AvailabilitySelectors.impactChartData)
  public impactChartData$: Observable<ImpactChartDataUIModel>;

  @Select(AvailabilitySelectors.eventDetailsLoading)
  public eventDetailsLoading$: Observable<boolean>;

  @Select(AvailabilitySelectors.eventDetails)
  public eventDetails$: Observable<EventDetailsModel>;

  @Select(AvailabilitySelectors.eventDetailsUI)
  public eventDetailsUI$: Observable<EventDetailsModelUI>;

  @Select(AvailabilitySelectors.pointInfo)
  public pointInfo$: Observable<EventDetailsPointInfoModel>;

  @Select(AvailabilitySelectors.otherEventsLoading)
  public otherEventsLoading$: Observable<boolean>;

  @Select(AvailabilitySelectors.otherEventsUI)
  public otherEventsUI$: Observable<CalendarEvent[]>;

  @Select(AvailabilitySelectors.isNorwayPointSelectedStatus)
  public isNorwayPointSelected$: Observable<boolean>;

  @Select(AvailabilitySelectors.isMergedGermanyMAState)
  public isMergedGermanyMAState$: Observable<boolean>;

  constructor(private availabilityService: AvailabilityService, private toastr: ToastrService, private store: Store) {}

  @Dispatch()
  public getAvailabilityData(range: RangeContext): Observable<GetAvailabilityData> {
    return this.availabilityService.loadAvailabilityData(range).pipe(
      catchError((err) => {
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((data: [MaintenanceMapPoint[], any[]]) => new GetAvailabilityData(data))
    );
  }

  @Dispatch()
  public getMaintenancePoints(range: RangeContext): Observable<GetAllMaintenancePoints> {
    return this.availabilityService.loadMaintenancePoints(range).pipe(
      catchError((err) => {
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((data: MaintenanceMapPoint[]) => new GetAllMaintenancePoints(data))
    );
  }

  @Dispatch()
  public getMaintenanceProPoints(range: RangeContext): Observable<GetAllMaintenancePoints> {
    return this.availabilityService.loadMaintenanceProPoints(range).pipe(
      catchError((err) => {
        if (err.status === ErrorTypeListEnum.FORBIDDEN) {
          return;
        }

        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((data: MaintenanceMapPoint[]) => new GetAllMaintenancePoints(data))
    );
  }

  @Dispatch()
  public getAllEventPoints(range: RangeContext): Observable<GetAllEventPointsSuccess> {
    this.store.dispatch(new GetAllEventPoints());

    return this.availabilityService.loadMaintenanceProAllPoints(range).pipe(
      catchError((err) => {
        this.store.dispatch(new GetAllEventPointsFailed());
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((data: MaintenanceEventsModel[]) => new GetAllEventPointsSuccess(data))
    );
  }

  @Dispatch()
  public getMaintenancePortfolioPoints(range: RangeContext): Observable<GetPortfolioMaintenancePoints> {
    this.store.dispatch(new GetPortfolioMaintenancePoints());

    return this.availabilityService.loadMaintenancePortfolioPoints(range).pipe(
      catchError((err) => {
        this.store.dispatch(new GetPortfolioMaintenancePointsFailed());
        return throwError(err);
      }),
      map((data: MaintenanceMapPoint[]) => new GetPortfolioMaintenancePointsSuccess(data))
    );
  }

  @Dispatch()
  public getSelectedPointEvents(
    appyGasId: string,
    vipPointMarketAreasIds: number[]
  ): Observable<GetSelectedPointEventsSuccess> {
    const dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);
    const pointType = this.store.selectSnapshot(AvailabilitySelectors.selectedPointType);
    this.store.dispatch(new GetSelectedPointEvents());

    if (pointType === UserPointTypesEnum.MY_PORTFOLIO) {
      return this.availabilityService.loadMaintenancePointEventsPortfolio(appyGasId, dateRange).pipe(
        catchError((err) => {
          this.store.dispatch(new GetSelectedPointEventsFailed());
          this.toastr.error(err.status, err.statusText);
          return throwError(err);
        }),
        map(
          (pointEvents: MaintenanceEventsModel) =>
            new GetSelectedPointEventsSuccess(pointEvents, vipPointMarketAreasIds)
        )
      );
    } else {
      return this.availabilityService.loadMaintenancePointEvents(appyGasId, dateRange).pipe(
        catchError((err) => {
          if (err.status === ErrorTypeListEnum.FORBIDDEN) {
            return;
          }

          this.store.dispatch(new GetSelectedPointEventsFailed());
          this.toastr.error(err.status, err.statusText);
          return throwError(err);
        }),
        map(
          (pointEvents: MaintenanceEventsModel) =>
            new GetSelectedPointEventsSuccess(pointEvents, vipPointMarketAreasIds)
        )
      );
    }
  }

  @Dispatch()
  public getSelectedNorwayEvents(
    appyGasIds: string[],
    vipPointMarketAreasIds: number[]
  ): Observable<GetSelectedNorwayEvents> {
    const dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);

    return this.availabilityService.loadMaintenanceRouteEvents(appyGasIds, dateRange).pipe(
      catchError((err) => {
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((pointEvents: MaintenanceEventsModel[]) => new GetSelectedNorwayEvents(pointEvents, vipPointMarketAreasIds))
    );
  }

  @Dispatch()
  public getSelectedPEGSuperPointsEvents(
    appyGasIds: string[],
    vipPointMarketAreasIds: number[]
  ): Observable<GetSelectedPEGSuperPointsEvents> {
    const dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);

    return this.availabilityService.loadMaintenanceRouteEvents(appyGasIds, dateRange).pipe(
      catchError((err) => {
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map(
        (pointEvents: MaintenanceEventsModel[]) =>
          new GetSelectedPEGSuperPointsEvents(pointEvents, vipPointMarketAreasIds)
      )
    );
  }

  @Dispatch()
  public getSelectedRouteDetails(appyGasIds: string[]): Observable<GetSelectedRouteDetailsSuccess> {
    const dateRange = this.store.selectSnapshot(AvailabilitySelectors.gasDayRange);
    this.store.dispatch(new GetSelectedRouteDetails());

    return this.availabilityService.loadMaintenanceRouteEvents(appyGasIds, dateRange).pipe(
      catchError((err) => {
        this.store.dispatch(new GetSelectedRouteDetailsFailed());
        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((pointEvents: MaintenanceEventsModel[]) => new GetSelectedRouteDetailsSuccess(pointEvents))
    );
  }

  @Dispatch()
  public getRelatedPoints(
    pointId: number,
    virtualPointId: string,
    range: RangeContext,
    dataSourceId: number
  ): Observable<GetRelatedPoints> {
    this.store.dispatch(new GetRelatedPointsLoading(pointId));
    return this.availabilityService.getRelatedPoints(pointId, virtualPointId, range, dataSourceId).pipe(
      catchError((err) => {
        if (err.status === ErrorTypeListEnum.FORBIDDEN) {
          return;
        }

        this.toastr.error(err.status, err.statusText);
        return throwError(err);
      }),
      map((relatedPoints: RelatedPointsModel) => new GetRelatedPoints(relatedPoints, pointId))
    );
  }

  @Dispatch()
  public getEventDetails(
    pointId: number,
    virtualPointId: string,
    range: RangeContext,
    capacityType: string,
    direction: string,
    dataSourceId: number
  ): Observable<GetEventDetails> {
    this.store.dispatch(new GetEventDetailsLoading(pointId));
    return this.availabilityService
      .getEventDetails(pointId, virtualPointId, range, capacityType, direction, dataSourceId)
      .pipe(
        catchError((err) => {
          if (err.status === ErrorTypeListEnum.FORBIDDEN) {
            return;
          }

          this.toastr.error(err.status, err.statusText);
          return throwError(err);
        }),
        map((eventDetails: EventDetailsModel) => new GetEventDetails(eventDetails, pointId))
      );
  }

  @Dispatch()
  public getOtherEvents(
    pointId: number,
    virtualPointId: string,
    range: RangeContext,
    capacityType: string,
    direction: string,
    dataSourceId: number
  ): Observable<GetOtherEvents> {
    this.store.dispatch(new GetOtherEventsLoading(pointId));
    return this.availabilityService
      .getOtherEvents(pointId, virtualPointId, range, capacityType, direction, dataSourceId)
      .pipe(
        catchError((err) => {
          if (err.status === ErrorTypeListEnum.FORBIDDEN) {
            return;
          }

          this.toastr.error(err.status, err.statusText);
          return throwError(err);
        }),
        map((otherEvents: OtherEventModel[]) => new GetOtherEvents(otherEvents, pointId))
      );
  }

  @Dispatch()
  public getImpactChartData(
    pointId: number,
    virtualPointId: string,
    range: RangeContext,
    capacityType: string,
    direction: string,
    dataSourceId: number
  ): Observable<GetImpactChartData> {
    this.store.dispatch(new GetImpactChartDataLoading(pointId));
    return this.availabilityService
      .getImpactChartData(pointId, virtualPointId, range, capacityType, direction, dataSourceId)
      .pipe(
        catchError((err) => {
          if (err.status === ErrorTypeListEnum.FORBIDDEN) {
            return;
          }

          this.toastr.error(err.status, err.statusText);
          return throwError(err);
        }),
        map((impactChartData: ChartDataItemModel[]) => new GetImpactChartData(impactChartData, pointId))
      );
  }
}
