import { format } from 'date-fns';

import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-restricted-market-area-classic',
  templateUrl: './restricted-market-area-classic.component.html',
  styleUrls: ['./restricted-market-area-classic.component.scss']
})
export class RestrictedMarketAreaClassicComponent {
  public activatedDate: Date;
  public proceedToNextStep: () => void;

  constructor(private modalService: ModalService) {}

  public get activatedDateMonth(): string {
    return format(this.activatedDate, 'MMMM');
  }

  public get activatedDateYear(): string {
    return format(this.activatedDate, 'yyyy');
  }

  public hideModal(): void {
    this.modalService.hide();
  }

  public switchToExclusive(): void {
    this.modalService.hide();
    this.proceedToNextStep();
  }
}
