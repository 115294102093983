import { Component } from '@angular/core';

import exclusiveProfileIcon from 'appy-gas-core/dist/assets/svg/icons/header/exclusive-profile-new.svg';

@Component({
  selector: 'appy-gas-new-features-carousel',
  templateUrl: './new-features-carousel.component.html',
  styleUrls: ['./new-features-carousel.component.scss']
})
export class NewFeaturesCarouselComponent {
  public readonly exclusiveProfileIcon = exclusiveProfileIcon;
}
