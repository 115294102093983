import { Action, State, StateContext } from '@ngxs/store';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';

import { IApiUser, MarketAreaNameConfig, User } from 'appy-gas-core';

import { BadgeModel } from '../../profile/interfaces/badge.model';
import { PortfolioInformationModel } from '../../profile/interfaces/portfolio-information.model';
import { TransactionHistoryModel } from '../../profile/interfaces/transaction-history.model';
import { UserBadgesModel } from '../../profile/interfaces/user-badges.model';
import { duplicatedPortfolioPointMAExceptions } from '../../shared';
import { UpdateUserRoleResponseTypes } from '../../shared/enums/update-user-role-response-types.enum';
import * as actions from './profile.actions';

export interface ProfileStateModel {
  apiUsersForm: {
    model: IApiUser;
  };
  userProfile: User;
  apiUsersList: {
    data: IApiUser[];
    loading: boolean;
    loaded: boolean;
  };
  portfolioInformation: {
    data: PortfolioInformationModel;
    loading: boolean;
    loaded: boolean;
  };
  savedPointsIds: {
    data: number[];
    loading: boolean;
    loaded: boolean;
  };
  appiesCount: {
    data: number;
    loading: boolean;
    loaded: boolean;
  };
  appiesTransactionHistory: {
    data: TransactionHistoryModel[];
    loading: boolean;
    loaded: boolean;
  };
  balanceStatus: UpdateUserRoleResponseTypes;
  userBadges: {
    data: UserBadgesModel[];
    loading: boolean;
    loaded: boolean;
  };
  allBadges: {
    data: BadgeModel[];
    loading: boolean;
    loaded: boolean;
  };
}

@State<ProfileStateModel>({
  name: 'profile',
  defaults: {
    apiUsersForm: {
      model: undefined
    },
    userProfile: undefined,
    apiUsersList: {
      data: undefined,
      loading: false,
      loaded: false
    },
    portfolioInformation: undefined,
    savedPointsIds: {
      data: undefined,
      loading: false,
      loaded: false
    },
    appiesCount: {
      data: undefined,
      loading: false,
      loaded: false
    },
    appiesTransactionHistory: {
      data: undefined,
      loading: false,
      loaded: false
    },
    balanceStatus: undefined,
    userBadges: {
      data: undefined,
      loading: false,
      loaded: false
    },
    allBadges: {
      data: undefined,
      loading: false,
      loaded: false
    }
  }
})
export class ProfileState {
  private static distinctDuplicatedMAPoints(pointId: number): string {
    return duplicatedPortfolioPointMAExceptions[pointId] || '';
  }

  @Action(actions.GetApiUsersList)
  private getApiUsersList(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiUsersList: { ...state.apiUsersList, loading: true }
    });
  }

  @Action(actions.GetApiUsersListSuccess)
  private getApiUsersListSuccess(
    ctx: StateContext<ProfileStateModel>,
    { apiUsersList }: actions.GetApiUsersListSuccess
  ): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiUsersList: { data: apiUsersList, loading: false, loaded: true }
    });
  }

  @Action(actions.GetApiUsersListFail)
  private getApiUsersListFail(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiUsersList: { ...state.apiUsersList, loading: false, loaded: false }
    });
  }

  @Action(actions.GetPortfolioInformation)
  private getPortfolioInformation(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      portfolioInformation: { ...state.portfolioInformation, loading: true }
    });
  }

  @Action(actions.GetPortfolioInformationSuccess)
  private getPortfolioInformationSuccess(
    ctx: StateContext<ProfileStateModel>,
    { portfolioInformation }: actions.GetPortfolioInformationSuccess
  ): void {
    const mapWithFullPointName = portfolioInformation.points.map((point) => {
      return {
        ...point,
        pointNameWithOperatorIndex: `${point.name} - ${point.operatorId}`,
        marketArea: `${MarketAreaNameConfig[point.marketAreaId]}${ProfileState.distinctDuplicatedMAPoints(point.id)}`
      };
    });
    const groupByPointNameWithOperatorIndex = groupBy(mapWithFullPointName, 'pointNameWithOperatorIndex');
    const duplicatedPoints = compact(
      flatten(
        map(groupByPointNameWithOperatorIndex, (points) => {
          if (points.length > 1) {
            return [...points];
          }
        })
      )
    );
    portfolioInformation.points = portfolioInformation.points.map((point) => {
      const findDuplicate = duplicatedPoints.find((duplicatedPoint) => point.id === duplicatedPoint.id);
      return findDuplicate ? findDuplicate : point;
    });

    const state = ctx.getState();
    ctx.setState({
      ...state,
      portfolioInformation: { data: portfolioInformation, loading: false, loaded: true }
    });
  }

  @Action(actions.GetPortfolioInformationFail)
  private getPortfolioInformationFail(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      portfolioInformation: { ...state.portfolioInformation, loading: false, loaded: false }
    });
  }

  @Action(actions.GetUserProfile)
  private getUserProfileInformation(
    { patchState }: StateContext<ProfileStateModel>,
    { user }: actions.GetUserProfile
  ): void {
    patchState({ userProfile: user });
  }

  @Action(actions.GetUserPortfolioPoints)
  private getUserPortfolioPoints(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      savedPointsIds: { ...state.savedPointsIds, loading: true }
    });
  }

  @Action(actions.GetUserPortfolioPointsSuccess)
  private getUserPortfolioPointsSuccess(
    ctx: StateContext<ProfileStateModel>,
    { savedPointsIds }: actions.GetUserPortfolioPointsSuccess
  ): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      savedPointsIds: { data: savedPointsIds, loading: false, loaded: true }
    });
  }

  @Action(actions.GetUserPortfolioPointsFail)
  private getUserPortfolioPointsFail(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      savedPointsIds: { ...state.savedPointsIds, loading: false, loaded: false }
    });
  }

  @Action(actions.SaveUserPortfolioPoints)
  private saveUserPortfolioPoints(
    ctx: StateContext<ProfileStateModel>,
    { savedPointsIds }: actions.SaveUserPortfolioPoints
  ): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      savedPointsIds: { ...state.savedPointsIds, data: savedPointsIds }
    });
  }

  @Action(actions.SaveApiUser)
  private saveApiUser(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiUsersList: { ...state.apiUsersList, loading: true }
    });
  }

  @Action(actions.SaveApiUserSuccess)
  private saveApiUserSuccess(ctx: StateContext<ProfileStateModel>, { apiUser }: actions.SaveApiUserSuccess): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiUsersList: { data: [apiUser, ...state.apiUsersList.data], loading: false, loaded: true }
    });
  }

  @Action(actions.SaveApiUserFail)
  private saveApiUserFail(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      apiUsersList: { ...state.apiUsersList, loading: false, loaded: false }
    });
  }

  @Action(actions.GetWalletInformation)
  private getWalletInformation(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      appiesCount: { ...state.appiesCount, loading: true },
      appiesTransactionHistory: { ...state.appiesTransactionHistory, loading: true }
    });
  }

  @Action(actions.GetWalletInformationSuccess)
  private getWalletInformationSuccess(
    ctx: StateContext<ProfileStateModel>,
    { appiesCount, appiesTransactionHistory }: actions.GetWalletInformationSuccess
  ): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      appiesCount: { data: appiesCount, loading: false, loaded: true },
      appiesTransactionHistory: { data: appiesTransactionHistory, loading: false, loaded: true }
    });
  }

  @Action(actions.GetWalletInformationFail)
  private getWalletInformationFail(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      appiesCount: { ...state.appiesCount, loading: false, loaded: false },
      appiesTransactionHistory: { ...state.appiesTransactionHistory, loading: false, loaded: false }
    });
  }

  @Action(actions.UpdateUserProfileNewsLetter)
  private updateUserProfileNewsLetter(
    ctx: StateContext<ProfileStateModel>,
    { newsLetter }: actions.UpdateUserProfileNewsLetter
  ): void {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      userProfile: { ...state.userProfile, newsLetter }
    });
  }

  @Action(actions.SetWalletBalanceStatus)
  private setWalletBalanceStatus(
    { patchState }: StateContext<ProfileStateModel>,
    { balanceStatus }: actions.SetWalletBalanceStatus
  ): void {
    patchState({ balanceStatus });
  }

  @Action(actions.GetUserBadges)
  private getUserBadges(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ userBadges: { ...state.userBadges, loading: true } });
  }

  @Action(actions.GetUserBadgesSuccess)
  private getUserBadgesSuccess(
    { patchState }: StateContext<ProfileStateModel>,
    { userBadges }: actions.GetUserBadgesSuccess
  ): void {
    patchState({ userBadges: { data: userBadges, loaded: true, loading: false } });
  }

  @Action(actions.GetUserBadgesFailed)
  private getUserBadgesFailed(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      userBadges: { ...state.userBadges, loaded: false, loading: false }
    });
  }

  @Action(actions.GetAllBadges)
  private getAllBadges(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ allBadges: { ...state.allBadges, loading: true } });
  }

  @Action(actions.GetAllBadgesSuccess)
  private getAllBadgesSuccess(
    { patchState }: StateContext<ProfileStateModel>,
    { allBadges }: actions.GetAllBadgesSuccess
  ): void {
    patchState({ allBadges: { data: allBadges, loaded: true, loading: false } });
  }

  @Action(actions.GetAllBadgesFailed)
  private getAllBadgesFailed(ctx: StateContext<ProfileStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      allBadges: { ...state.allBadges, loaded: false, loading: false }
    });
  }
}
