import { MarketAreaConfig, MarketAreaViewGeneralInfo, VirtualPointData } from 'appy-gas-core';

import { MarketAreaViewPositionEnum } from '../../shared/enums';

export class SetMarketAreaViewGeneralInfo {
  public static readonly type = '[Market Area View Module] Set Market Area View General Info';
  constructor(public marketAreaViewGeneralInfo: MarketAreaViewGeneralInfo, public marketAreaPosition: string) {}
}

export class SetMarketAreaDate {
  public static readonly type = '[Market Area View Module] Set Market Area Date';
  constructor(public date: Date, public marketAreaPosition: MarketAreaViewPositionEnum) {}
}

export class GetPointDetails {
  public static readonly type = '[Market Area View Module] Get Point Details';
  constructor(public virtualPointData: VirtualPointData, public marketAreaPosition: MarketAreaViewPositionEnum) {}
}

export class ResetPointDetails {
  public static readonly type = '[Market Area View Module] Reset Point Details';
  constructor(public marketAreaPosition: MarketAreaViewPositionEnum) {}
}

export class GetMarketArea {
  public static readonly type = '[Market Area View Module] Get Market Area';
  constructor(public marketAreaConfig: MarketAreaConfig, public marketAreaPosition: MarketAreaViewPositionEnum) {}
}
