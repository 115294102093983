export enum PointTypesEnum {
  PRODUCTION = 1,
  CONSUMPTION,
  IP,
  STORAGE,
  LNG,
  VIP,
  VIRTUAL_STORAGE,
  VIRTUAL_PRODUCTION,
  VIRTUAL_CONSUMPTION,
  SUPER_POINT,
  SUPER_POINT_REAL
}
