import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { MarketAreaActivePoint, Operators } from 'appy-gas-core';
import { getMetadataHeaders } from '../http-auth/headers.helper';

@Injectable()
export class CommonEntitiesService {
  constructor(private httpClient: HttpClient) {}

  public getVirtualPointsActive(date: number): Observable<MarketAreaActivePoint[]> {
    return this.httpClient.get<MarketAreaActivePoint[]>(`/eepoints/virtual/active`, {
      headers: getMetadataHeaders({ auth: true, cache: true, cacheMaxAge: 72_000_00 }),
      params: { timeFrom: date.toString() }
    });
  }

  public loadOperators(): Observable<Operators[]> {
    return this.httpClient.get<Operators[]>('/operators', { headers: getMetadataHeaders({ auth: true }) });
  }
}
