// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".search-form{position:relative}.search-form__input{box-shadow:inset 0 0 3px rgba(177,185,195,.606997);border:1px solid #b1b9c3;border-radius:3px;height:40px;width:315px;padding:9px 30px 10px 43px;font-size:12px}.search-form__input:focus{outline:none;border-color:appy-gas-color(\"accent\")}.search-form__input:-ms-input-placeholder{color:#737b85;font-size:12px;line-height:14px}.search-form__input::placeholder{color:#737b85;font-size:12px;line-height:14px}.search-form__icon-close,.search-form__icon-search{position:absolute}.search-form__icon-close{right:15px;top:14px}.search-form__icon-close:hover{cursor:pointer}.search-form__icon-search{left:13px;top:12px}.search-form__icon-search:after{content:\"\";border-right:1px solid #b1b9c3;height:16px;position:absolute;top:0;right:-9px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();