// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".features-carousel{height:520px;padding:0 10px;text-align:center}.features-carousel ngb-carousel{height:100%}.features-carousel__slide-heading{color:#4f4f4f;height:74px;font-size:28px;font-weight:600;margin:20px auto}.features-carousel__slide-content{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;margin-top:15px}.features-carousel__slide-img{height:422px;width:100%}.features-carousel ::ng-deep .carousel-indicators li{margin:0 .5rem;width:8px;height:8px;border:1px solid #b1b9c3;border-radius:50%;-moz-border-radius:50%;-webkit-border-radius:50%;-o-border-radius:50%;cursor:pointer}.features-carousel ::ng-deep .carousel-indicators li.active{background-color:#7200da;border-color:#7200da}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();