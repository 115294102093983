// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".promotion-banner{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;background-color:#fff;border-radius:30px;box-shadow:0 4px 4px rgba(0,0,0,.25);font-family:Montserrat;font-weight:500;font-size:14px;padding:6px 17px 6px 10px}.promotion-banner__info-icon{fill:#7200da;margin-right:7px}.promotion-banner__highlight-text{color:#7200da;font-weight:700}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();