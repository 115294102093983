import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';

import { CookiesPopoverService } from './cookies-popover.service';

import closeIcon from 'appy-gas-core/dist/assets/svg/icons/close.svg';
import cookiesIcon from 'appy-gas-core/dist/assets/svg/icons/cookies.svg';

const popoverDelay = 15000;
const animationDuration = 500;

@Component({
  selector: 'appy-gas-cookies-popover',
  templateUrl: './cookies-popover.component.html',
  styleUrls: ['./cookies-popover.component.scss'],
  animations: [
    trigger('slideInOut', [
      // What happens when toggleState is true
      state(
        'invisible',
        style({
          transform: 'translate(0, 75px)'
        })
      ),
      state(
        'visible',
        style({
          transform: 'translate(0, 0)'
        })
      ),
      transition('invisible <=> visible', animate(`${animationDuration}ms ease-in-out`))
    ])
  ]
})
export class CookiesPopoverComponent implements OnInit {
  public visible = false;
  public state = 'invisible';
  public icons: any = {
    close: closeIcon,
    cookies: cookiesIcon
  };

  constructor(private cookiesPopoverService: CookiesPopoverService) {}

  public ngOnInit(): void {
    if (!this.cookiesPopoverService.isCookiesAccepted()) {
      setTimeout(() => this.togglePopover(), popoverDelay);
    }
  }

  public acceptCookies(): void {
    this.cookiesPopoverService.acceptCookies();
    this.state = 'invisible';
    setTimeout(() => {
      this.visible = false;
    }, animationDuration);
  }

  private togglePopover(): void {
    this.visible = true;
    setTimeout(() => {
      this.state = 'visible';
    }, animationDuration);
  }
}
