import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';

import { Component, Injector, OnInit } from '@angular/core';

import { TokenType } from 'appy-gas-core';

import { LocalStorageKeysEnum } from '../../../shared/enums/local-storage-keys.enum';

import { UserSelectors } from '../../../store';
import { APPYGAS_TOKENS, AuthenticationService } from '../../http-auth';
import { WelcomePopoverComponent, welcomePopoverModalConfig, welcomePopoverState } from '../../welcome-popover';

@Component({
  selector: 'appy-gas-welcome-popover-controller',
  templateUrl: './welcome-popover-controller.component.html'
})
export class WelcomePopoverControllerComponent implements OnInit {
  constructor(
    private authenticationService: AuthenticationService,
    private injector: Injector,
    private modalService: NgbModal,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.handleAuthSubscription();
  }

  private handleAuthSubscription(): void {
    this.authenticationService.authenticate.subscribe((value: boolean) => {
      const isTokenExists = !!window.localStorage.getItem(APPYGAS_TOKENS[TokenType.ACCESS]);
      const isRegisteredPopup =
        window.localStorage.getItem(LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL) ===
        welcomePopoverState.IS_REGISTERED;
      const isHiddenPopup =
        window.localStorage.getItem(LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL) ===
        welcomePopoverState.IS_HIDDEN;

      if (!value && !isHiddenPopup) {
        window.localStorage.removeItem(LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL);
      }

      if ((isRegisteredPopup && isTokenExists) || isHiddenPopup) {
        return;
      }
      this.store.select(UserSelectors.getUser).subscribe((user) => {
        if (value && user) {
          setTimeout(() => {
            this.modalService.open(WelcomePopoverComponent, welcomePopoverModalConfig);
          }, 1500);
        }
      });
    });
  }
}
