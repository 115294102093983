import { Component, Input } from '@angular/core';

@Component({
  selector: 'appy-gas-expandable-section',
  templateUrl: './expandable-section.component.html',
  styleUrls: ['./expandable-section.component.scss']
})
export class ExpandableSectionComponent {
  @Input() public title;
  @Input() public showIcon = true;
  public expand = false;

  public toggleExpand(): void {
    this.expand = !this.expand;
  }
}
