import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-switch-declined',
  templateUrl: './switch-declined.component.html',
  styleUrls: ['./switch-declined.component.scss']
})
export class SwitchDeclinedComponent {
  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }
}
