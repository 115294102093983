// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".link-with-icon{cursor:pointer;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.link-with-icon__text{text-decoration:none;line-height:1rem;margin-right:.25rem}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();