// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".privacy-policy-pop-up{width:470px;height:210px;box-shadow:0 5px 10px rgba(0,0,0,.1);border-radius:3px;background-color:#7200da}.privacy-policy-pop-up__main-content{font-size:12px;line-height:16px;font-family:Open Sans,Segoe UI,sans-serif;height:150px;display:-ms-flexbox;display:flex;color:#fff}.privacy-policy-pop-up__text{padding:20px 15px 0 0}.privacy-policy-pop-up__text a{text-decoration:underline}.privacy-policy-pop-up__icon{padding:20px}.privacy-policy-pop-up__button-container{display:-ms-flexbox;display:flex;-ms-flex-pack:end;justify-content:flex-end}.privacy-policy-pop-up__button{margin:5px 15px;background-color:#eceef2!important}.privacy-policy-pop-up__button:hover{background-color:#52019d!important;border:1px solid #eceef2!important}.privacy-policy-pop-up appy-gas-checkbox::ng-deep .appy-gas-checkbox__label{color:#fff;font-size:13px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();