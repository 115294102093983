export enum VariableIdsEnum {
  STORAGE_TECHNICAL_ENTRY = 7,
  STORAGE_BOOKED_FIRM_ENTRY = 171,
  STORAGE_BOOKED_INTERRUPTIBLE_ENTRY = 181,
  STORAGE_TECHNICAL_EXIT = 8,
  STORAGE_BOOKED_FIRM_EXIT = 172,
  STORAGE_BOOKED_INTERRUPTIBLE_EXIT = 182,
  IP_TECHNICAL_ENTRY = 31,
  IP_BOOKED_FIRM_ENTRY = 45,
  IP_BOOKED_INTERRUPTIBLE_ENTRY = 55,
  IP_TECHNICAL_EXIT = 32,
  IP_BOOKED_FIRM_EXIT = 46,
  IP_BOOKED_INTERRUPTIBLE_EXIT = 56,
  REMAINING_CAPACITY = 100001,
  IP_CLEAN_NOM_ENTRY = 108,
  IP_CLEAN_NOM_EXIT = 109,
  STORAGE_CLEAN_NOM_ENTRY = 77,
  STORAGE_CLEAN_NOM_EXIT = 78
}
