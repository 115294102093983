// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".date-range-slider__container{display:-ms-flexbox;display:flex;-ms-flex-align:baseline;align-items:baseline}.date-range-slider__control{cursor:pointer}.date-range-slider__control.disabled{pointer-events:none}.date-range-slider .arrow{border:solid #737b85;border-width:0 2px 2px 0;display:inline-block;padding:4px;height:4px;margin:10px}.date-range-slider .arrow.disabled{border-color:#eceef2}.date-range-slider .arrow.arrow-left{transform:rotate(135deg)}.date-range-slider .arrow.arrow-right{transform:rotate(-45deg)}.date-range-slider__values-container{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:end;justify-content:flex-end}.date-range-slider__values{margin-left:5px;color:#7200da;font-weight:700;font-size:14px}.date-range-slider ::ng-deep .ngx-slider{margin:0;height:6px}.date-range-slider ::ng-deep .ngx-slider .ngx-slider-bar{background:#eceef2;height:2px}.date-range-slider ::ng-deep .ngx-slider .ngx-slider-selection{background:#7200da;height:8px;top:13px;left:1px}.date-range-slider ::ng-deep .ngx-slider .ngx-slider-tick{width:1px;height:4px;margin-left:0;background:#737b85;top:2px}.date-range-slider ::ng-deep .ngx-slider .ngx-slider-tick.ngx-slider-selected{background:#7200da}.date-range-slider ::ng-deep .ngx-slider .ngx-slider-limit,.date-range-slider ::ng-deep .ngx-slider .ngx-slider-model-high,.date-range-slider ::ng-deep .ngx-slider .ngx-slider-model-value,.date-range-slider ::ng-deep .ngx-slider .ngx-slider-pointer{display:none!important}.date-range-slider ::ng-deep .ngx-slider .ngx-slider-limit:after,.date-range-slider ::ng-deep .ngx-slider .ngx-slider-model-high:after,.date-range-slider ::ng-deep .ngx-slider .ngx-slider-model-value:after,.date-range-slider ::ng-deep .ngx-slider .ngx-slider-pointer:after{display:none}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();