import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Select, Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";

import {
  BlockPositionsUIModel,
  DashboardService,
  ErrorTypeListEnum,
  HubPricesResult,
  LastAuctionStatsResult,
  MaintenancePlan,
  MaintenanceUpdate,
  PeriodType,
  PipelineLngRadioTypes,
  SpreadAlert,
  SupplyChartItem,
  Spreads,
  SpreadFilter,
  SpreadMarketArea,

  FilterForEditInterface
} from "appy-gas-core";

import { PointOperatorsFilter } from "../../shared/interfaces";
import {
  DeleteFullSpreadFilter,
  GetAllFilterSpreads,
  GetAllFilterSpreadsFailed,
  GetAllFilterSpreadsLoaded,
  GetAllFilterSpreadsLoading,
  GetBlockPositions,
  GetFullSpreadFilter,
  GetFullSpreadFilterFailed,
  GetFullSpreadFilterLoaded,
  GetFullSpreadFilterLoading,
  GetHubPrices,
  GetHubPricesFailed,
  GetHubPricesLoaded,
  GetHubPricesLoading,
  GetLastAuctionStats,
  GetLastAuctionStatsFailed,
  GetLastAuctionStatsLoaded,
  GetLastAuctionStatsLoading,
  GetLngCountries,
  GetLngCountriesFailed,
  GetLngCountriesLoaded,
  GetLngCountriesLoading,
  GetMaintenancePlan,
  GetMaintenancePlanFailed,
  GetMaintenancePlanLoaded,
  GetMaintenancePlanLoading,
  GetMaintenanceUpdate,
  GetMaintenanceUpdateFailed,
  GetMaintenanceUpdateSuccess,
  GetPipelineCountries,
  GetPipelineCountriesFailed,
  GetPipelineCountriesLoaded,
  GetPipelineCountriesLoading,
  GetPipelineCountriesVsLngSupply,
  GetPipelineCountriesVsLngSupplyFailed,
  GetPipelineCountriesVsLngSupplyLoaded,
  GetPipelineCountriesVsLngSupplyLoading,
  GetPreviewSpreads,
  GetPreviewSpreadsFailed,
  GetPreviewSpreadsLoaded,
  GetPreviewSpreadsLoading,
  GetSpreadFilters,
  GetSpreadFiltersFailed,
  GetSpreadFiltersLoaded,
  GetSpreadFiltersLoading,
  GetSpreadMarketAreas,
  GetSpreadMarketAreasFailed,
  GetSpreadMarketAreasLoaded,
  GetSpreadMarketAreasLoading,
  GetSpreads,
  GetSpreadsFailed,
  GetSpreadsLoaded,
  GetSpreadsLoading,
} from "./dashboard.actions";
import { DashboardSelectors } from "./dashboard.selectors";

@Injectable()
export class DashboardFacade {
  @Select(DashboardSelectors.filteredByMergeGermanyDateHubPrices)
  public hubPrices$: Observable<HubPricesResult>;

  @Select(DashboardSelectors.hubPricesIsLoading)
  public hubPricesIsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.lngCountries)
  public lngCountries$: Observable<SupplyChartItem[]>;

  @Select(DashboardSelectors.lngCountriesIsLoading)
  public lngCountriesIsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.maintenancePlanAll)
  public maintenancePlanAll$: Observable<MaintenancePlan[]>;

  @Select(DashboardSelectors.getMaintenancePlanAllFilters)
  public getMaintenancePlanAllFilters$: Observable<PointOperatorsFilter>;

  @Select(DashboardSelectors.getMaintenancePlanMyPortfolioFilters)
  public getMaintenancePlanMyPortfolioFilters$: Observable<PointOperatorsFilter>;

  @Select(DashboardSelectors.maintenancePlanIsLoading)
  public maintenancePlanIsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.maintenanceUpdateAll)
  public maintenanceUpdateAll$: Observable<MaintenanceUpdate[]>;

  @Select(DashboardSelectors.maintenanceUpdateLoading)
  public maintenanceUpdateLoading$: Observable<boolean>;

  @Select(DashboardSelectors.maintenanceUpdateMyPortfolio)
  public maintenanceUpdateMyPortfolio$: Observable<MaintenanceUpdate[]>;

  @Select(DashboardSelectors.getMaintenanceUpdateAllFilters)
  public getMaintenanceUpdateAllFilters$: Observable<PointOperatorsFilter>;

  @Select(DashboardSelectors.getMaintenanceUpdateMyPortfolioFilters)
  public getMaintenanceUpdateMyPortfolioFilters$: Observable<PointOperatorsFilter>;

  @Select(DashboardSelectors.pipelineCountries)
  public pipelineCountries$: Observable<SupplyChartItem[]>;

  @Select(DashboardSelectors.pipelineCountriesIsLoading)
  public pipelineCountriesIsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.pipelineCountriesVsLng)
  public pipelineCountriesVsLngSupply$: Observable<SupplyChartItem[]>;

  @Select(DashboardSelectors.pipelineCountriesVsLngIsLoading)
  public pipelineCountriesVsLngSupplyIsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.lastAuctionStats)
  public lastAuctionStats$: Observable<LastAuctionStatsResult>;

  @Select(DashboardSelectors.lastAuctionStatsIsLoading)
  public lastAuctionStatsIsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.spreads)
  public spreads$: Observable<Spreads>;

  @Select(DashboardSelectors.spreadsLoading)
  public spreadsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.spreadFilters)
  public spreadFilters$: Observable<SpreadFilter[]>;

  @Select(DashboardSelectors.spreadFiltersLoading)
  public spreadFiltersLoading$: Observable<boolean>;

  @Select(DashboardSelectors.allFilterSpreads)
  public allFilterSpreads$: Observable<Spreads>;

  @Select(DashboardSelectors.allFilterSpreadsLoading)
  public allFilterSpreadsLoading$: Observable<boolean>;

  @Select(DashboardSelectors.spreadFilterForEdit)
  public spreadFilterForEdit$: Observable<FilterForEditInterface>;

  @Select(DashboardSelectors.spreadFilterForEditLoading)
  public spreadFilterForEditLoading$: Observable<boolean>;

  @Select(DashboardSelectors.spreadMarketAreas)
  public spreadMarketAreas$: Observable<SpreadMarketArea[]>;

  @Select(DashboardSelectors.spreadMarketAreasLoading)
  public spreadMarketAreasLoading$: Observable<boolean>;

  @Select(DashboardSelectors.blockPositions)
  public blockPositions$: Observable<BlockPositionsUIModel>;

  constructor(private dashboardService: DashboardService, private toastr: ToastrService, private store: Store) {}

  @Dispatch()
  public getHubPrices(date: number, period?: PeriodType): Observable<GetHubPrices> {
    this.store.dispatch(new GetHubPricesLoading());

    return this.dashboardService.loadHubPrices(date, period).pipe(
      catchError((err) => {
        if (err.status === ErrorTypeListEnum.FORBIDDEN) {
          return;
        }

        this.store.dispatch(new GetHubPricesFailed());
        this.toastr.error(err.status, `Error while loading hub prices: ${err.statusText}`);
        return throwError(err);
      }),
      map((data: HubPricesResult) => {
        this.store.dispatch(new GetHubPricesLoaded());
        return new GetHubPrices(data);
      })
    );
  }

  @Dispatch()
  public getLngCountries(period: PipelineLngRadioTypes): Observable<GetLngCountries> {
    this.store.dispatch(new GetLngCountriesLoading());

    return this.dashboardService.loadLngCountries(period).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetLngCountriesFailed());
        return throwError(err);
      }),
      map((data: SupplyChartItem[]) => {
        this.store.dispatch(new GetLngCountriesLoaded());
        return new GetLngCountries(data);
      })
    );
  }

  @Dispatch()
  public getMaintenancePlan(): Observable<GetMaintenancePlan> {
    this.store.dispatch(new GetMaintenancePlanLoading());

    return this.dashboardService.loadMaintenancePlans().pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetMaintenancePlanFailed());
        return throwError(err);
      }),
      map((data: MaintenancePlan[]) => {
        this.store.dispatch(new GetMaintenancePlanLoaded());
        return new GetMaintenancePlan(data);
      })
    );
  }

  @Dispatch()
  public getSpreads(): Observable<GetSpreads> {
    this.store.dispatch(new GetSpreadsLoading());

    return this.dashboardService.loadSpreads().pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetSpreadsFailed());
        return throwError(err);
      }),
      map((data: Spreads) => {
        this.store.dispatch(new GetSpreadsLoaded());
        return new GetSpreads(data);
      })
    );
  }

  @Dispatch()
  public getAllFilterSpreads(filterId: number): Observable<GetAllFilterSpreads> {
    this.store.dispatch(new GetAllFilterSpreadsLoading());

    return this.dashboardService.loadSpreadsByFilterId(filterId).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetAllFilterSpreadsFailed());
        return throwError(err);
      }),
      map((data: Spreads) => {
        this.store.dispatch(new GetAllFilterSpreadsLoaded());
        return new GetAllFilterSpreads(data);
      })
    );
  }

  @Dispatch()
  public getPreviewSpreads(): Observable<GetPreviewSpreads> {
    this.store.dispatch(new GetPreviewSpreadsLoading());

    return this.dashboardService.loadSpreads().pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetPreviewSpreadsFailed());
        return throwError(err);
      }),
      map((data: Spreads) => {
        this.store.dispatch(new GetPreviewSpreadsLoaded());
        return new GetPreviewSpreads(data);
      })
    );
  }

  @Dispatch()
  public getSpreadFilters(userId: number): Observable<GetSpreadFilters> {
    this.store.dispatch(new GetSpreadFiltersLoading());

    return this.dashboardService.loadSpreadFilters(userId).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetSpreadFiltersFailed());
        return throwError(err);
      }),
      map((data: SpreadFilter[]) => {
        this.store.dispatch(new GetSpreadFiltersLoaded());
        return new GetSpreadFilters(data);
      })
    );
  }

  @Dispatch()
  public getSpreadMarketAreas(): Observable<GetSpreadMarketAreas> {
    this.store.dispatch(new GetSpreadMarketAreasLoading());

    return this.dashboardService.loadSpreadMarketAreas().pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetSpreadMarketAreasFailed());
        return throwError(err);
      }),
      map((data: SpreadMarketArea[]) => {
        this.store.dispatch(new GetSpreadMarketAreasLoaded());
        return new GetSpreadMarketAreas(data);
      })
    );
  }

  @Dispatch()
  public getFilterForEdit(userId: number, filterId: number): Observable<GetFullSpreadFilter> {
    this.store.dispatch(new GetFullSpreadFilterLoading());

    return this.dashboardService.loadFullFilterInfo(userId, filterId).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetFullSpreadFilterFailed());
        return throwError(err);
      }),
      map((data: FilterForEditInterface) => {
        this.store.dispatch(new GetFullSpreadFilterLoaded());
        return new GetFullSpreadFilter(data);
      })
    );
  }

  @Dispatch()
  public deleteFullSpreadFilter(): Observable<any> {
    return this.store.dispatch(new DeleteFullSpreadFilter());
  }

  @Dispatch()
  public getMaintenanceUpdate(): Observable<GetMaintenanceUpdateSuccess> {
    this.store.dispatch(new GetMaintenanceUpdate());

    return this.dashboardService.loadMaintenanceUpdates().pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetMaintenanceUpdateFailed());

        return throwError(err);
      }),
      map((data: MaintenanceUpdate[]) => new GetMaintenanceUpdateSuccess(data))
    );
  }

  @Dispatch()
  public getPipelineCountries(period: PipelineLngRadioTypes): Observable<GetPipelineCountries> {
    this.store.dispatch(new GetPipelineCountriesLoading());

    return this.dashboardService.loadPipelineCountries(period).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetPipelineCountriesFailed());
        return throwError(err);
      }),
      map((data: SupplyChartItem[]) => {
        this.store.dispatch(new GetPipelineCountriesLoaded());
        return new GetPipelineCountries(data);
      })
    );
  }

  @Dispatch()
  public getPipelineCountriesVsLngSupply(period: PipelineLngRadioTypes): Observable<GetPipelineCountriesVsLngSupply> {
    this.store.dispatch(new GetPipelineCountriesVsLngSupplyLoading());

    return this.dashboardService.loadPipelineCountriesVsLngSupply(period).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetPipelineCountriesVsLngSupplyFailed());
        return throwError(err);
      }),
      map((data: SupplyChartItem[]) => {
        this.store.dispatch(new GetPipelineCountriesVsLngSupplyLoaded());
        return new GetPipelineCountriesVsLngSupply(data);
      })
    );
  }

  @Dispatch()
  public getLastAuctionStats(period: PeriodType): Observable<GetLastAuctionStats> {
    this.store.dispatch(new GetLastAuctionStatsLoading());

    return this.dashboardService.loadLastAuctionStats(period).pipe(
      catchError((err) => {
        this.handleError(err);
        this.store.dispatch(new GetLastAuctionStatsFailed());
        return throwError(err);
      }),
      map((data: LastAuctionStatsResult) => {
        this.store.dispatch(new GetLastAuctionStatsLoaded());
        return new GetLastAuctionStats(data);
      })
    );
  }

  @Dispatch()
  public getBlockPositions(userId: number): Observable<GetBlockPositions> {
    return this.dashboardService.loadBlockPositions(userId).pipe(
      catchError((err) => {
        this.handleError(err);
        return throwError(err);
      }),
      map((data: BlockPositionsUIModel) => new GetBlockPositions(data))
    );
  }

  private handleError(err: HttpErrorResponse): void {
    if (err?.status === ErrorTypeListEnum.FORBIDDEN) {
      return;
    }

    this.toastr.error(err?.status?.toString(), err?.statusText);
  }
}
