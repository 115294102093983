import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import closeIcon from 'appy-gas-core/dist/assets/svg/icons/close.svg';
import searchIcon from 'appy-gas-core/dist/assets/svg/icons/search.svg';

@Component({
  selector: 'appy-gas-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() public groupInstance: FormGroup;
  @Input() public controlName: string;

  public readonly closeIcon: SVGElement = closeIcon;
  public readonly searchIcon: SVGElement = searchIcon;

  public clearInput(): void {
    this.groupInstance.get(this.controlName).setValue('');
  }
}
