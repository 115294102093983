import { CalendarEvent } from 'angular-calendar';
import { format as formatZoneTime, utcToZonedTime } from 'date-fns-tz';
import areIntervalsOverlapping from 'date-fns/areIntervalsOverlapping';
import format from 'date-fns/format';
import enGB from 'date-fns/locale/en-GB';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import groupBy from 'lodash/groupBy';
import head from 'lodash/head';
import mapValues from 'lodash/mapValues';
import round from 'lodash/round';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';

import { Injectable } from '@angular/core';

import {
  ChartDataItemModel,
  createUtcDate,
  differenceInDays,
  EventDetailsModel,
  EventDetailsModelUI,
  MaintenanceConfig,
  MaintenanceEventModel,
  MaintenanceEventsModel,
  MaintenanceEventUIModel,
  MaintenanceMapPoint,
  MappedMaintenanceEventUIModel,
  MarketAreaActivePoint,
  MarketAreaNameConfig,
  notAvailableCapacityUnitOperators,
  Operators,
  OtherEventModel,
  OtherEventModelUI,
  POINTS_TOOLTIP_INFO_CONFIG,
  RelatedPointsMaintenanceModel,
  RelatedPointsUIModel
} from 'appy-gas-core';
import { norwayDataSourceId, superPointIdsGRTTeregaOperator, virtualStorageIds } from '..';
import { EventClassNamesEnum } from '../../availability-pro/availability-pro-general-info/availability-pro-events-calendar/enums';
import { ImpactChartDataUIModel } from '../../availability-pro/availability-pro-general-info/impact-widget/interfaces/impact-chart-data.model';
import {
  ImpactRadioTypes,
  MaintenanceFiltersModel
} from '../../availability-pro/availability-pro-map/availability-pro-map-filter/interfaces';
import { superPointWithTwoOperators } from '../constants/app-exceptions';
import { units } from '../constants/units.stub';
import {
  AvailabilityImpactEnum,
  AvailabilityTabEventsDirection,
  CapacityTypeEnum,
  ImpactCapacityFillColor,
  OperatorSourcesEnum,
  OperatorTypesEnum,
  VariableIdsEnum
} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityHelperService {
  public static getImpactFillColor(capacityType: number, impactPercentages: number | string): string {
    if (impactPercentages === 0) {
      return ImpactCapacityFillColor.EMPTY;
    }

    switch (capacityType) {
      case CapacityTypeEnum.INTERRUPTIBLE:
        return ImpactCapacityFillColor.INTERRUPTIBLE;
      case CapacityTypeEnum.FIRM:
        return ImpactCapacityFillColor.FIRM;
      default:
        return ImpactCapacityFillColor.FIRM;
    }
  }

  public static getMappedAllPointEvents(
    events: MaintenanceEventsModel[],
    operators: Operators[],
    eePoints: MarketAreaActivePoint[]
  ): MappedMaintenanceEventUIModel[] {
    const uniqPointEvents = uniqBy(
      flatten(
        events.map((item) => {
          return item.events.map((event) => ({ ...event, virtualPointId: item.virtualPointId }));
        })
      ),
      'eePointId'
    );

    return uniqPointEvents.map((event: MaintenanceEventModel) => {
      const operator = operators.find((value) => value.id === event.operatorId);
      const point = eePoints.find((value) => value.eePointId === event.eePointId);
      const marketAreasIds = MaintenanceConfig[event.virtualPointId]?.fullMarketAreasIds || [
        event.marketAreaId,
        event.marketAreaId
      ];

      return {
        ...event,
        operatorName: operator.name,
        pointName: point.name,
        marketAreasIds
      };
    });
  }

  public static getMaintenanceUIEvents(
    operators: Operators[],
    selectedEvents: MaintenanceEventModel[],
    eePoints: MarketAreaActivePoint[],
    maintenanceFiltersForm?: MaintenanceFiltersModel,
    isNorwayPointSelected?: boolean
  ): MaintenanceEventUIModel[] {
    const events = maintenanceFiltersForm
      ? AvailabilityHelperService.filterMaintenanceEvents(selectedEvents, maintenanceFiltersForm, isNorwayPointSelected)
      : selectedEvents;
    const sortedByRange = sortBy(events, ['maintenanceStart', 'maintenanceEnd']);

    return sortedByRange.map((eventDetails: MaintenanceEventModel) => {
      let impactOnTac: string | number;
      let impactOnFirm: string | number;
      const operator = operators.find((value) => value.id === eventDetails.operatorId);
      const point = eePoints.find((value) => value.eePointId === eventDetails.eePointId);

      impactOnTac = round(eventDetails.impactCapacityInPercents * 100, 0);

      if (
        notAvailableCapacityUnitOperators.includes(operator.name) ||
        (virtualStorageIds.includes(eventDetails.virtualPointId) && eventDetails.capacityType === OperatorTypesEnum.SSO)
      ) {
        impactOnFirm = AvailabilityImpactEnum.NOT_AVAILABLE;
      } else {
        impactOnFirm = round(eventDetails.impactBookedPercentage * 100, 0);
      }

      return {
        marketAreaName: MarketAreaNameConfig[eventDetails.marketAreaId],
        // TODO uncomment this when super points with two operators removed
        // operatorName: operator.name,
        operatorName: superPointIdsGRTTeregaOperator.includes(eventDetails.eePointId)
          ? superPointWithTwoOperators[eventDetails.eePointId]
          : operator.name,
        operatorTypeId: eventDetails.operatorTypeId,
        dataSourceId: eventDetails.dataSourceId,
        operatorId: eventDetails.operatorId,
        pointName: point.name,
        pointId: eventDetails.eePointId,
        duration:
          !!eventDetails.maintenanceStart && !!eventDetails.maintenanceEnd
            ? `${format(eventDetails.maintenanceStart, 'dd.MM')} - ${format(eventDetails.maintenanceEnd, 'dd.MM')}`
            : '-',
        expectedInterruption: eventDetails.expectedInterruption,
        expectedInterruptionUnit: eventDetails.expectedInterruptionUnit,
        impactOnTac,
        impactOnFirm,
        impactOnTacFillColor: AvailabilityHelperService.getImpactFillColor(eventDetails.capacityType, impactOnTac),
        impactOnFirmFillColor: AvailabilityHelperService.getImpactFillColor(eventDetails.capacityType, impactOnFirm),
        isOneMADirection: !eventDetails.marketAreasIds || eventDetails.marketAreasIds.length === 0,
        flowDirection: AvailabilityHelperService.getFlowDirection(
          eventDetails.marketAreasIds,
          eventDetails.marketAreaId,
          eventDetails.directionId
        ),
        maintenanceEnd: eventDetails.maintenanceEnd,
        maintenanceStart: eventDetails.maintenanceStart,
        virtualPointId: eventDetails.virtualPointId,
        capacityType: eventDetails.capacityType,
        directionId: eventDetails.directionId,
        tooltipData: AvailabilityHelperService.getEventTooltip(eventDetails.eePointId),
        hasMaintenance: !!eventDetails.maintenanceStart && !!eventDetails.maintenanceEnd,
        isRemainingPercentsSetByOperator: eventDetails.isRemainingPercentsSetByOperator,
        isRemainingCapacitySetByOperator: eventDetails.isRemainingCapacitySetByOperator
      };
    });
  }

  public static getFlowDirection(
    relatedMarketAreaIds: number[] = [],
    eventMarketAreaId: number,
    direction: number
  ): string {
    const isOneMADirection = relatedMarketAreaIds.length === 0;

    if (isOneMADirection) {
      return AvailabilityTabEventsDirection.ENTRY;
    }

    if (head(relatedMarketAreaIds) === eventMarketAreaId) {
      return direction > 0 ? AvailabilityTabEventsDirection.EXIT : AvailabilityTabEventsDirection.ENTRY;
    } else {
      return direction < 0 ? AvailabilityTabEventsDirection.EXIT : AvailabilityTabEventsDirection.ENTRY;
    }
  }

  public static filterMaintenancePoints(
    maintenancePoint: MaintenanceMapPoint,
    maintenanceFiltersForm: MaintenanceFiltersModel,
    isPointsGasscoEventsIncluded?: boolean
  ): MaintenanceMapPoint {
    const { tso, sso, firm, interruptible, impact } = maintenanceFiltersForm;
    let filteredMaintenances = maintenancePoint.maintenanceGeneral;

    filteredMaintenances = filteredMaintenances.filter((item) => {
      if (!tso) {
        return item.operatorType !== OperatorTypesEnum.TSO;
      }
      return true;
    });

    filteredMaintenances = filteredMaintenances.filter((item) => {
      if (!sso) {
        return item.operatorType !== OperatorTypesEnum.SSO;
      }
      return true;
    });

    filteredMaintenances = filteredMaintenances.filter((item) => {
      if (!firm) {
        return item.maintenanceType < 0;
      }
      return true;
    });

    filteredMaintenances = filteredMaintenances.filter((item) => {
      if (!interruptible) {
        return item.maintenanceType > 0;
      }
      return true;
    });

    filteredMaintenances = filteredMaintenances.filter((item) => {
      switch (impact) {
        case ImpactRadioTypes.LESS:
          return item.impactInPercents < 0.8;
        case ImpactRadioTypes.EQUALS:
          return item.impactInPercents === 1;
        default:
          return ImpactRadioTypes.ALL;
      }
    });

    if (isPointsGasscoEventsIncluded) {
      maintenancePoint.maintenanceFilteredByGassco = filteredMaintenances;
    } else {
      maintenancePoint.maintenanceGeneralFiltered = filteredMaintenances.filter((maintenance) => {
        return maintenance.dataSourceId !== norwayDataSourceId;
      });
    }

    return maintenancePoint;
  }

  private static filterMaintenanceEvents(
    maintenanceEvents: MaintenanceEventModel[],
    maintenanceFiltersForm: MaintenanceFiltersModel,
    isNorwayPointSelected: boolean
  ): MaintenanceEventModel[] {
    const { tso, sso, firm, interruptible, impact } = maintenanceFiltersForm;

    maintenanceEvents = maintenanceEvents.filter((item) => {
      if (!tso) {
        return item.operatorTypeId !== OperatorTypesEnum.TSO;
      }
      return true;
    });

    maintenanceEvents = maintenanceEvents.filter((item) => {
      if (!sso) {
        return item.operatorTypeId !== OperatorTypesEnum.SSO;
      }
      return true;
    });

    maintenanceEvents = maintenanceEvents.filter((item) => {
      if (!firm) {
        return item.capacityType !== CapacityTypeEnum.FIRM;
      }
      return true;
    });

    maintenanceEvents = maintenanceEvents.filter((item) => {
      if (!interruptible) {
        return item.capacityType !== CapacityTypeEnum.INTERRUPTIBLE;
      }
      return true;
    });

    maintenanceEvents = maintenanceEvents.filter((item) => {
      switch (impact) {
        case ImpactRadioTypes.LESS:
          return item.impactCapacityInPercents < 0.8;
        case ImpactRadioTypes.EQUALS:
          return item.impactCapacityInPercents === 1;
        default:
          return ImpactRadioTypes.ALL;
      }
    });

    if (!isNorwayPointSelected) {
      return maintenanceEvents.filter((event) => event.dataSourceId !== norwayDataSourceId);
    } else {
      return maintenanceEvents;
    }
  }

  public static transformRelatedPoints(
    relatedPoints: RelatedPointsMaintenanceModel[],
    eePoints: MarketAreaActivePoint[],
    operators: Operators[]
  ): RelatedPointsUIModel[] {
    return relatedPoints.map((point) => {
      const events = point.maintenances.map((maintenance) => ({
        maintenanceStart: maintenance.maintenanceStart,
        maintenanceEnd: maintenance.maintenanceEnd,
        directionId: maintenance.directionId,
        capacityType: maintenance.capacityType,
        dataSourceId: maintenance.dataSourceId
      }));
      const eePoint = eePoints.find(({ eePointId }) => eePointId === point.id);

      return {
        id: point.id,
        name: eePoint?.name,
        operatorName: operators.find(({ id }) => id === eePoint?.operatorId)?.name,
        events
      };
    });
  }

  public static transformEventDetails(eventDetails: EventDetailsModel): EventDetailsModelUI {
    const diffInDays = differenceInDays(eventDetails.maintenanceStart, eventDetails.maintenanceEnd);
    const expectedInterruptionUnitName = units.find(
      (unit) => unit.id === eventDetails?.expectedInterruptionUnit
    )?.abbreviaton;
    const remainingCapacityUnitName = units.find(
      (unit) => unit.id === eventDetails?.remainingCapacityUnit
    )?.abbreviaton;

    const bookedCapacityUnitName = units.find((unit) => unit.id === eventDetails?.bookedCapacityUnit)?.abbreviaton;

    return {
      ...eventDetails,
      maintenanceEndFormatted: AvailabilityHelperService.getZonedTime(eventDetails.maintenanceEnd),
      maintenanceStartFormatted: AvailabilityHelperService.getZonedTime(eventDetails.maintenanceStart),
      expectedInterruptionUnitName,
      remainingCapacityUnitName,
      bookedCapacityUnitName,
      diffInDays
    };
  }

  public static getCalendarEventWithAppropriateClasses(
    events: OtherEventModel[],
    points: MarketAreaActivePoint[]
  ): CalendarEvent[] {
    const sortedByDate = sortBy(events, ['maintenanceStart']);
    const clonedEvents = cloneDeep(sortedByDate) as OtherEventModelUI[];

    clonedEvents.forEach((event, index) => {
      event.rowClass = index === 0 ? EventClassNamesEnum.FIRST_ROW : '';

      const eventsToCompare = clonedEvents.slice(0, index);
      const overlappedEventsClasses = eventsToCompare
        .filter((ev) => {
          return areIntervalsOverlapping(
            { start: event.maintenanceStart, end: event.maintenanceEnd },
            { start: ev.maintenanceStart, end: ev.maintenanceEnd },
            { inclusive: true }
          );
        })
        .map((overlappedEvent) => overlappedEvent.rowClass);
      if (overlappedEventsClasses.length) {
        if (!overlappedEventsClasses.includes(EventClassNamesEnum.FIRST_ROW)) {
          event.rowClass = EventClassNamesEnum.FIRST_ROW;
        } else if (!overlappedEventsClasses.includes(EventClassNamesEnum.SECOND_ROW)) {
          event.rowClass = EventClassNamesEnum.SECOND_ROW;
        } else if (!overlappedEventsClasses.includes(EventClassNamesEnum.THIRD_ROW)) {
          event.rowClass = EventClassNamesEnum.THIRD_ROW;
        } else if (!overlappedEventsClasses.includes(EventClassNamesEnum.FOURTH_ROW)) {
          event.rowClass = EventClassNamesEnum.FOURTH_ROW;
        } else {
          event.rowClass = EventClassNamesEnum.HIDDEN_ROW;
        }
      } else {
        event.rowClass = EventClassNamesEnum.FIRST_ROW;
      }
      return event;
    });

    return clonedEvents.map((event) => {
      return {
        start: new Date(event.maintenanceStart),
        end: new Date(event.maintenanceEnd),
        title: '',
        cssClass: event.isActive
          ? (event.rowClass += ` ${EventClassNamesEnum.CURRENT_EVENT}`)
          : (event.rowClass += ` ${EventClassNamesEnum.OTHER_EVENT}`),
        meta: {
          pointName: points.find((point) => event.eePointId === point.eePointId).name
        }
      };
    });
  }

  public static transformImpactChartData(
    pointData: ChartDataItemModel[],
    eventDetails: EventDetailsModel
  ): ImpactChartDataUIModel {
    const pointDataTimeReduction = pointData.map((point) => {
      return { ...point, validityStart: new Date(point.validityStart).setHours(7, 0, 0, 0) };
    });
    const groupedByVariableId = groupBy(pointDataTimeReduction, 'eePointVariableId');
    const groupedByVariableIdAndDate = mapValues(groupedByVariableId, (dataItems: ChartDataItemModel[]) => {
      return dataItems.reduce((acc, currentItem) => {
        return {
          ...acc,
          [currentItem.validityStart]: { value: currentItem.value, unit: currentItem.unitId }
        };
      }, {});
    });

    return {
      newTac: groupedByVariableIdAndDate[VariableIdsEnum.REMAINING_CAPACITY],
      originalTac:
        groupedByVariableIdAndDate[VariableIdsEnum.IP_TECHNICAL_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.IP_TECHNICAL_EXIT] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_TECHNICAL_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_TECHNICAL_EXIT],
      bookedFirm:
        groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_FIRM_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_FIRM_EXIT] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_FIRM_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_FIRM_EXIT],
      bookedInterruptible:
        groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_INTERRUPTIBLE_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.IP_BOOKED_INTERRUPTIBLE_EXIT] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_INTERRUPTIBLE_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_BOOKED_INTERRUPTIBLE_EXIT],
      flow:
        groupedByVariableIdAndDate[VariableIdsEnum.IP_CLEAN_NOM_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.IP_CLEAN_NOM_EXIT] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_CLEAN_NOM_ENTRY] ||
        groupedByVariableIdAndDate[VariableIdsEnum.STORAGE_CLEAN_NOM_EXIT],
      eventDetails
    };
  }

  public static filterRemainingTacByAvailableOperators(
    pointItem: ChartDataItemModel,
    expectedInterruption: number
  ): boolean {
    return (
      expectedInterruption === 0 &&
      pointItem.eePointVariableId === VariableIdsEnum.REMAINING_CAPACITY &&
      pointItem.dataSourceId === OperatorSourcesEnum.GUD
    );
  }

  public static getEventTooltip(pointId: number): string {
    return POINTS_TOOLTIP_INFO_CONFIG[pointId] || '';
  }

  public static getZonedUTCTime(timestamp: number): string {
    const date = createUtcDate(timestamp);
    return formatZoneTime(date, 'dd.MM.y, h:mm a (zzz)', { timeZone: 'Europe/Paris', locale: enGB });
  }

  public static getZonedTime(date: number): string {
    const utcZonedDate = utcToZonedTime(date, 'Europe/Paris');
    return formatZoneTime(utcZonedDate, 'dd.MM.y, h:mm a (zzz)', { timeZone: 'Europe/Paris', locale: enGB });
  }
}
