import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { AuthenticationService } from './authentication.service';

import { User } from 'appy-gas-core';

import { UserFacadeService } from '../../store';

@Injectable()
export class CurrentUserResolverService implements Resolve<User> {
  constructor(private authService: AuthenticationService, private userFacadeService: UserFacadeService) {}

  public resolve(route: ActivatedRouteSnapshot): Observable<User> {
    return this.authService.getUser().pipe(tap((user) => this.userFacadeService.setUserInfo(user)));
  }
}
