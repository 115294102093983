import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import head from 'lodash/head';
import isPlainObject from 'lodash/isPlainObject';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { ApiUsersService, IApiUser, User } from 'appy-gas-core/dist';

import { BadgeModel } from '../../profile/interfaces/badge.model';
import {
  FullPortfolioInformationModel,
  PortfolioInformationModel
} from '../../profile/interfaces/portfolio-information.model';
import { TransactionHistoryModel } from '../../profile/interfaces/transaction-history.model';
import { UserBadgesModel } from '../../profile/interfaces/user-badges.model';
import {
  WalletExceptionsNonExistingContractModel,
  WalletInformationModel
} from '../../profile/interfaces/wallet-information.model';
import { ProfileService } from '../../profile/profile.service';
import { ProfileHelperService } from '../../shared/services/profile-helper.service';
import { UserSelectors } from '../user';
import * as actions from './profile.actions';
import { ProfileSelectors } from './profile.selectors';
import { ProfileState, ProfileStateModel } from './profile.state';

@Injectable({
  providedIn: 'root'
})
export class ProfileFacade {
  @Select(ProfileState)
  public profileData$: Observable<ProfileStateModel>;

  @Select(ProfileSelectors.portfolioInformation)
  public portfolioInformation$: Observable<PortfolioInformationModel>;

  @Select(ProfileSelectors.userProfile)
  public userProfile$: Observable<User>;

  @Select(ProfileSelectors.savedPointsIds)
  public savedPointsIds$: Observable<number[]>;

  @Select(ProfileSelectors.fullPortfolioInformation)
  public fullPortfolioInformation$: Observable<FullPortfolioInformationModel>;

  @Select(ProfileSelectors.appiesCount)
  public appiesCount$: Observable<number>;

  @Select(ProfileSelectors.appiesTransactionHistory)
  public appiesTransactionHistory$: Observable<TransactionHistoryModel[]>;

  constructor(private apiUsersService: ApiUsersService, private profileService: ProfileService, private store: Store) {}

  @Dispatch()
  public getApiUsersList(userId: number): Observable<actions.GetApiUsersListSuccess> {
    this.store.dispatch(new actions.GetApiUsersList());

    return this.apiUsersService.getApiUsersList(userId).pipe(
      catchError((error) => this.store.dispatch(new actions.GetApiUsersListFail(error))),
      map((apiUsersList) => new actions.GetApiUsersListSuccess(apiUsersList))
    );
  }

  @Dispatch()
  public getPortfolioInformation(): Observable<actions.GetPortfolioInformationSuccess> {
    this.store.dispatch(new actions.GetPortfolioInformation());

    return this.profileService.getPortfolioInformation().pipe(
      catchError((error) => this.store.dispatch(new actions.GetPortfolioInformationFail(error))),
      // TODO remove when super points with two operators removed
      map((portfolioInformation: PortfolioInformationModel) =>
        ProfileHelperService.addSuperPointsExceptions(portfolioInformation)
      ),
      map(
        (portfolioInformation: PortfolioInformationModel) =>
          new actions.GetPortfolioInformationSuccess(portfolioInformation)
      )
    );
  }

  @Dispatch()
  public getUserPortfolioPoints(userId: number): Observable<actions.GetUserPortfolioPointsSuccess> {
    this.store.dispatch(new actions.GetUserPortfolioPoints());

    return this.profileService.getUserPortfolioPoints(userId).pipe(
      catchError((error) => this.store.dispatch(new actions.GetUserPortfolioPointsFail(error))),
      map((points: number[]) => ProfileHelperService.addSuperPointExceptionIds(points)),
      map((points: number[]) => new actions.GetUserPortfolioPointsSuccess(points))
    );
  }

  @Dispatch()
  public saveUserPortfolioPoints(pointIds: number[]): actions.SaveUserPortfolioPoints {
    return new actions.SaveUserPortfolioPoints(pointIds);
  }

  @Dispatch()
  public getUserProfile(): Observable<actions.GetUserProfile> {
    return this.store.select(UserSelectors.getUser).pipe(map((user: User) => new actions.GetUserProfile(user)));
  }

  @Dispatch()
  public saveApiUser(userId: number, apiUsersFormValue: any): Observable<actions.SaveApiUserSuccess> {
    this.store.dispatch(new actions.SaveApiUser());

    return this.apiUsersService.saveApiUser(userId, apiUsersFormValue).pipe(
      catchError((error) => this.store.dispatch(new actions.SaveApiUserFail(error))),
      map((apiUser: IApiUser) => new actions.SaveApiUserSuccess(apiUser))
    );
  }

  @Dispatch()
  public getWalletInformation(): Observable<actions.GetWalletInformationSuccess | actions.SetWalletBalanceStatus> {
    this.store.dispatch(new actions.GetWalletInformation());

    return this.profileService.getWalletInformation().pipe(
      catchError((error) => {
        this.store.dispatch(new actions.GetWalletInformationFail(error));

        return throwError(error);
      }),
      map((walletInformation: WalletInformationModel | WalletExceptionsNonExistingContractModel[]) => {
        if (isPlainObject(walletInformation)) {
          const { contractBalance, transactionHistory } = walletInformation as WalletInformationModel;

          return new actions.GetWalletInformationSuccess(contractBalance, transactionHistory);
        } else {
          const exceptionData = head(walletInformation as WalletExceptionsNonExistingContractModel[]);
          const { key } = exceptionData;

          return new actions.SetWalletBalanceStatus(key);
        }
      })
    );
  }

  @Dispatch()
  public getUserBadges(userId: number): Observable<actions.GetUserBadgesSuccess> {
    this.store.dispatch(new actions.GetUserBadges());

    return this.profileService.getUserBadges(userId).pipe(
      catchError((error) => {
        this.store.dispatch(new actions.GetUserBadgesFailed(error));

        return throwError(error);
      }),
      map((badges: UserBadgesModel[]) => new actions.GetUserBadgesSuccess(badges))
    );
  }

  @Dispatch()
  public getAllBadges(): Observable<actions.GetAllBadgesSuccess> {
    this.store.dispatch(new actions.GetAllBadges());

    return this.profileService.getAllBadges().pipe(
      catchError((error) => {
        this.store.dispatch(new actions.GetAllBadgesFailed(error));

        return throwError(error);
      }),
      map((badges: BadgeModel[]) => new actions.GetAllBadgesSuccess(badges))
    );
  }
}
