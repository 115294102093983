import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

import { Injectable } from '@angular/core';

import { MarketAreaMap } from 'appy-gas-core';

import { nordStreamOperatorId, pointIdsNotIncludedInPortfolio, TAPOperatorId } from '..';
import { PortfolioInformationModel } from '../../profile/interfaces/portfolio-information.model';
import {
  pointToSupplementingPointMapper,
  supplementingPointToPointMapper,
  supplementingTeregaSuperPoints,
  supplementingTeregaSuperPointsIds
} from '../constants/app-exceptions';

@Injectable({
  providedIn: 'root'
})
export class ProfileHelperService {
  public static filterUselessProfileInfo(portfolioInformation: PortfolioInformationModel): PortfolioInformationModel {
    if (portfolioInformation) {
      portfolioInformation.marketAreas = portfolioInformation.marketAreas.filter((marketArea) => {
        // tslint:disable-next-line:max-line-length
        // TODO: remove 'MarketAreaMap.NCG_H, MarketAreaMap.NCG_L, MarketAreaMap.GASPOOL_H, MarketAreaMap.GASPOOL_L' from filter before merge Germany release;
        return ![
          MarketAreaMap.RUSSIA,
          MarketAreaMap.ALBANIA,
          MarketAreaMap.NCG_H,
          MarketAreaMap.NCG_L,
          MarketAreaMap.GASPOOL_H,
          MarketAreaMap.GASPOOL_L
        ].includes(marketArea.marketArea.id);
      });
      portfolioInformation.operators = portfolioInformation.operators.filter((operator) => {
        return ![nordStreamOperatorId, TAPOperatorId].includes(operator.operator.id);
      });
      // TODO: remove forEach logic before merge Germany release;
      portfolioInformation.operators.forEach((operator) => {
        operator.pointIDs = operator.pointIDs.filter((pointId) => !pointIdsNotIncludedInPortfolio.includes(pointId));
      });
      portfolioInformation.points = portfolioInformation.points.filter((point) => {
        return !pointIdsNotIncludedInPortfolio.includes(point.id);
      });
    }

    return portfolioInformation;
  }

  // TODO remove when super points with two operators removed
  public static addSuperPointsExceptions(portfolioInformation: PortfolioInformationModel): PortfolioInformationModel {
    return {
      ...portfolioInformation,
      superPoints: [...portfolioInformation.superPoints, ...supplementingTeregaSuperPoints],
      operators: portfolioInformation.operators.map((operator) => {
        // 5 - id for Terega operator
        if (operator.operator.id === 5) {
          return { ...operator, pointIDs: [...operator.pointIDs, ...supplementingTeregaSuperPointsIds] };
        }
        return operator;
      }),
      marketAreas: portfolioInformation.marketAreas.map((marketArea) => {
        // 33 - id for PEG MA where Terega operator operates
        if (marketArea.marketArea.id === 33) {
          return { ...marketArea, pointIDs: [...marketArea.pointIDs, ...supplementingTeregaSuperPointsIds] };
        }
        return marketArea;
      })
    };
  }

  // TODO remove when super points with two operators removed
  public static parseSuperPointExceptionIds(pointIds: number[]): number[] {
    return uniq(pointIds.map((pointId) => supplementingPointToPointMapper[pointId] || pointId));
  }

  // TODO remove when super points with two operators removed
  public static addSuperPointExceptionIds(pointIds: number[]): number[] {
    const additionalPointIds = compact(pointIds.map((pointId) => pointToSupplementingPointMapper[pointId]));

    return [...pointIds, ...additionalPointIds];
  }
}
