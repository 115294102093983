import { APP_BASE_HREF } from '@angular/common';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthenticationGuard, TechnicalMaintenanceComponent } from './core';

import { PageNotFoundComponent, PageNotFoundModule, Role } from 'appy-gas-core';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full'
  },
  {
    path: 'technical-maintenance',
    component: TechnicalMaintenanceComponent
  },
  {
    path: 'auth',
    loadChildren: './auth/auth.module#AuthModule'
  },
  {
    path: 'flows-map',
    loadChildren: './flows-map/flows-map.module#FlowsMapModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        only: Role.All,
        except: [Role.Expired]
      },
      title: 'Flows Map'
    }
  },
  {
    path: 'data-export',
    loadChildren: './data-export/data-export.module#DataExportModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        except: [Role.Expired]
      },
      title: 'Data Export'
    }
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        except: [Role.Guest, Role.Expired]
      },
      title: 'Dashboard'
    }
  },
  {
    path: 'route-calculator',
    loadChildren: './route-calculator/route-calculator.module#RouteCalculatorModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        except: [Role.Guest, Role.Expired]
      },
      title: 'Route Calculator'
    }
  },
  {
    path: 'market-area-view',
    loadChildren: './market-area-view/market-area-view.module#MarketAreaViewModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        except: [Role.Guest, Role.Expired]
      },
      title: 'Market Area Details'
    }
  },
  {
    path: 'availability-pro',
    loadChildren: './availability-pro/availability-pro.module#AvailabilityProModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        except: [Role.Guest, Role.Expired]
      },
      title: 'Availability Pro'
    }
  },
  {
    path: 'profile',
    loadChildren: './profile/profile.module#ProfileModule',
    canActivate: [AuthenticationGuard],
    data: {
      permissions: {
        except: Role.Guest
      },
      title: 'Profile'
    }
  },
  {
    path: 'static',
    loadChildren: './static-pages/static-pages.module#StaticPagesModule'
  },
  // Fallback when no prior route is matched
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent
  }
];

export const routesProviders: any[] = [
  {
    provide: APP_BASE_HREF,
    useValue: '/'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    }),
    PageNotFoundModule.forRoot()
  ],
  providers: [...routesProviders],
  exports: [RouterModule]
})
export class AppRoutingModule {}
