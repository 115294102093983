import { Options } from '@m0t0r/ngx-slider';

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import calendarIcon from 'appy-gas-core/dist/assets/svg/icons/calendar.svg';
import { DateRangeModel } from '../../interfaces/date-range.model';

@Component({
  selector: 'appy-gas-date-range-slider',
  templateUrl: './date-range-slider.component.html',
  styleUrls: ['./date-range-slider.component.scss']
})
export class DateRangeSliderComponent implements OnInit {
  @Input() private readonly minValue: number;
  @Input() private readonly maxValue: number;
  @Input() private readonly rangeFloor: number;
  @Input() private readonly rangeCeil: number;
  @Input() private readonly step = 86400000;

  @Output() public readonly valueChangeEmitter: EventEmitter<DateRangeModel> = new EventEmitter<DateRangeModel>();

  public readonly calendarIcon = calendarIcon;

  public dateFrom: number;
  public dateTo: number;
  public options: Options;

  public ngOnInit(): void {
    this.setSliderConfig();
  }

  public handleRangeChange(): void {
    setTimeout(() => {
      const { dateFrom, dateTo } = this;
      this.valueChangeEmitter.emit({ dateFrom, dateTo });
    }, 0);
  }

  public changeRange(value: number): void {
    this.dateFrom = this.dateFrom + value;
    this.dateTo = this.dateTo + value;
  }

  private setSliderConfig(): void {
    this.dateFrom = this.minValue;
    this.dateTo = this.maxValue;
    this.options = {
      floor: this.rangeFloor,
      ceil: this.rangeCeil,
      step: this.step,
      draggableRangeOnly: true,
      animate: false,
      showTicks: true
    };
  }
}
