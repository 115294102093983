// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".welcome-popover{border-radius:16px;padding:25px}.welcome-popover__controls-wrapper{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;margin-top:20px}.welcome-popover__controls-block button{margin-left:10px}.welcome-popover__switch-btn{margin-bottom:15px;width:318px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();