import isAfter from 'date-fns/isAfter';

import { mergedGermanyMADate, mergedGermanyMAs, notMergedGermanyMAs } from '../constants/app-constant';
import {
  ListExceptMarketAreaView,
  virtualPointIdsAfterGermanyMerge,
  virtualPointIdsBeforeGermanyMerge
} from '../constants/app-exceptions';
import { SearchDropdownPoint } from '../interfaces';

export class HeaderHelperService {
  public static filterSearchResultByGermanyMergeState(
    searchPointsResult: SearchDropdownPoint[],
    selectedDate: number
  ): SearchDropdownPoint[] {
    const isGermanyMerged = isAfter(selectedDate, mergedGermanyMADate);

    return searchPointsResult
      .filter((searchItem) => {
        const filterKey = searchItem.marketAreaId !== 0 ? searchItem.marketAreaId : searchItem.id;

        return isGermanyMerged ? !notMergedGermanyMAs.includes(filterKey) : !mergedGermanyMAs.includes(filterKey);
      })
      .filter((searchItem) => {
        return isGermanyMerged
          ? !virtualPointIdsBeforeGermanyMerge.includes(searchItem.appyGasId)
          : !virtualPointIdsAfterGermanyMerge.includes(searchItem.appyGasId);
      })
      .filter((searchItem) => !ListExceptMarketAreaView.includes(searchItem.marketAreaId));
  }
}
