import each from 'lodash/each';
import { finalize } from 'rxjs/operators';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { passwordConfirmationValidator, passwordValidator } from 'appy-gas-core';

import { AuthenticationService } from '../../core/http-auth/authentication.service';

@Component({
  selector: 'appy-gas-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public error: string = null;
  public resetPasswordForm: FormGroup;
  public isLoading = false;
  public isPage = true;
  public email: string;

  private sub: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  public ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe((params) => {
      const { reset_code, email } = params;
      this.email = email;
      this.resetPasswordForm.controls.email.setValue(email);
      this.resetPasswordForm.controls.code.setValue(reset_code);
    });
  }

  public ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  public resetPassword(): void {
    if (!this.resetPasswordForm.valid) {
      each(this.resetPasswordForm.controls, (control) => control.markAsTouched());
      return;
    }
    this.isLoading = true;
    this.authenticationService
      .resetPassword(this.resetPasswordForm.value)
      .pipe(
        finalize(() => {
          this.resetPasswordForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        () => {
          this.router.navigate(['/auth/login', { passwordSuccessfullyChanged: true }], { replaceUrl: true });
        },
        (error) => (this.error = error)
      );
  }

  private createForm(): void {
    this.resetPasswordForm = this.formBuilder.group({
      email: [''],
      code: [''],
      password: ['', [Validators.required, passwordValidator]],
      confirmPassword: ['', [Validators.required, passwordConfirmationValidator]]
    });
  }
}
