import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';
import { LocalStorageKeysEnum } from '../../../enums/local-storage-keys.enum';

@Component({
  selector: 'appy-gas-you-are-exclusive-user',
  templateUrl: './you-are-exclusive-user.component.html',
  styleUrls: ['./you-are-exclusive-user.component.scss']
})
export class YouAreExclusiveUserComponent {
  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    localStorage.removeItem(LocalStorageKeysEnum.USER_ROLE_EXCLUSIVE_MODAL);
    this.modalService.hide();
  }
}
