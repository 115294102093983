import { Component, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[appy-gas-norway-icon]',
  templateUrl: './norway-icon.component.html'
})
export class NorwayIconComponent {
  public readonly defaultIconColor = '#F9C00C';

  @Input() public quantity: number;

  get quantityLength(): number {
    return this.quantity.toString().length;
  }
}
