import { Action, State, StateContext } from '@ngxs/store';

import { IDownloadFile } from 'appy-gas-core';

import { DataExportFormModel } from '../../data-export/interfaces';

import { GetExportData, GetExportDataFailed, GetExportDataSuccess } from './data-export.actions';

export interface DataExportStateModel {
  dataExportForm: {
    model: DataExportFormModel;
  };
  exportData: {
    data: IDownloadFile[];
    loading: boolean;
    loaded: boolean;
  };
}

@State<DataExportStateModel>({
  name: 'dataExport',
  defaults: {
    dataExportForm: undefined,
    exportData: {
      data: undefined,
      loading: false,
      loaded: false
    }
  }
})
export class DataExportState {
  @Action(GetExportData)
  private getExportData(ctx: StateContext<DataExportStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ exportData: { ...state.exportData, loading: true } });
  }

  @Action(GetExportDataSuccess)
  private getExportDataSuccess(
    { patchState }: StateContext<DataExportStateModel>,
    { exportData }: GetExportDataSuccess
  ): void {
    patchState({ exportData: { data: exportData, loaded: true, loading: false } });
  }

  @Action(GetExportDataFailed)
  private getExportDataFailed(ctx: StateContext<DataExportStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ exportData: { ...state.exportData, loaded: false, loading: false } });
  }
}
