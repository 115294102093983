import { Component, Input } from '@angular/core';

@Component({
  selector: 'appy-gas-percentage-circle',
  templateUrl: './percentage-circle.component.html',
  styleUrls: ['./percentage-circle.component.scss']
})
export class PercentageCircleComponent {
  @Input() public readonly percentages: number;
  @Input() public readonly color: string;
  @Input() public readonly dashed: boolean;
  @Input() public readonly noData: boolean;
  @Input() public readonly bgColor;
}
