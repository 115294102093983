import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { TechnicalMaintenanceComponent } from './technical-maintenance.component';
import { TechnicalMaintenanceGuard } from './technical-maintenance.guard';

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [TechnicalMaintenanceComponent],
  providers: [TechnicalMaintenanceGuard],
  exports: [TechnicalMaintenanceComponent]
})
export class TechnicalMaintenanceModule {}
