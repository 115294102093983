import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import privacyPolicyIcon from '../../../../assets/images/privacy-policy.svg';
import { LocalStorageKeysEnum } from '../../enums/local-storage-keys.enum';

@Component({
  selector: 'appy-gas-privacy-policy-popup',
  templateUrl: './privacy-policy-pop-up.component.html',
  styleUrls: ['./privacy-policy-pop-up.component.scss']
})
export class PrivacyPolicyPopUpComponent implements OnInit, OnDestroy {
  @Input() public description: string;
  @Input() public localStorageKey: LocalStorageKeysEnum;

  @Output() public gotItEmitter: EventEmitter<void> = new EventEmitter<void>();

  public readonly componentDestroyed$: Subject<void> = new Subject<void>();
  public readonly privacyPolicyForm = this.buildForm();
  public readonly privacyPolicyIcon: SVGElement = privacyPolicyIcon;

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit(): void {
    this.initFormChangeListener();
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  private buildForm(): FormGroup {
    return this.formBuilder.group({
      doNotShowAgain: false
    });
  }

  private initFormChangeListener(): void {
    this.privacyPolicyForm
      .get('doNotShowAgain')
      .valueChanges.pipe(takeUntil(this.componentDestroyed$))
      .subscribe((value) => {
        window.localStorage.setItem(this.localStorageKey, value);
      });
  }
}
