import { BehaviorSubject, Observable } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable()
export class RoleNavigationHandlerService {
  private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public onPut: Observable<string> = this.currentUrl.asObservable();

  public put(url: string): void {
    this.currentUrl.next(url);
  }
}
