import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import {
  MarketAreasMapService,
  RangeContext,
  VirtualPoint,
  VirtualPointService,
  VirtualPointType,
  WidgetPhysicalPointModel
} from 'appy-gas-core';

import {
  WidgetPhysicalPointIPModelUI,
  WidgetPhysicalPointLNGModelUI,
  WidgetPhysicalPointStorageModelUI
} from '../../flows-map/flows-map-area/flows-map-sidebar-pro/my-point-widget/interfaces';
import { TabPhysicalPointByGasDay } from '../../flows-map/interfaces';
import { PointOperatorsFilter } from '../../shared/interfaces';
import {
  GetPhysicalPoints,
  GetPhysicalPointsSuccess,
  GetVirtualPoints,
  GetVirtualPointsFailed,
  GetVirtualPointsSuccess
} from './flows-map.actions';
import { FlowsMapSelectors } from './flows-map.selectors';

@Injectable()
export class FlowsMapFacade {
  constructor(
    private virtualPointService: VirtualPointService,
    private marketAreasMapService: MarketAreasMapService,
    private store: Store
  ) {}

  @Select(FlowsMapSelectors.selectedPointsWidgetTabType)
  public selectedPointTabType$: Observable<VirtualPointType>;

  @Select(FlowsMapSelectors.selectedPointsFilter)
  public filterActivePoints$: Observable<PointOperatorsFilter>;

  @Select(FlowsMapSelectors.filteredActivePoints)
  public filteredActivePoints$: Observable<
    WidgetPhysicalPointStorageModelUI[] | WidgetPhysicalPointLNGModelUI[] | WidgetPhysicalPointIPModelUI[]
  >;

  @Select(FlowsMapSelectors.getSelectedTabPhysicalPointDataByGasDay)
  public getSelectedTabPhysicalPointDataByGasDay$: Observable<TabPhysicalPointByGasDay>;

  @Select(FlowsMapSelectors.physicalPointsLoading)
  public physicalPointsLoading$: Observable<boolean>;

  @Select(FlowsMapSelectors.isMergedGermanyMAState)
  public isMergedGermanyMAState$: Observable<boolean>;

  @Dispatch()
  public getVirtualPoints(dateRange: RangeContext, refreshCache: boolean): Observable<GetVirtualPointsSuccess> {
    this.store.dispatch(new GetVirtualPoints());

    return this.virtualPointService.loadVirtualPoints(dateRange, refreshCache).pipe(
      catchError((err) => {
        this.store.dispatch(new GetVirtualPointsFailed());
        return throwError(err);
      }),
      map((data: VirtualPoint[]) => new GetVirtualPointsSuccess(data))
    );
  }

  @Dispatch()
  public getPhysicalPoints(virtualPoint: VirtualPointType, timeFrom: number): Observable<GetPhysicalPointsSuccess> {
    this.store.dispatch(new GetPhysicalPoints());
    const virtualPointKey = VirtualPointType[virtualPoint].toLowerCase();

    return this.marketAreasMapService.loadPhysicalPoints(virtualPointKey, timeFrom).pipe(
      catchError((err) => {
        this.store.dispatch(new GetVirtualPointsFailed());
        return throwError(err);
      }),
      map((data: WidgetPhysicalPointModel[]) => new GetPhysicalPointsSuccess(data))
    );
  }
}
