import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Inject, Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { EnvVariables, ModalService, User } from 'appy-gas-core';

import { updateProfileModalConfig } from '../../auth';
import { AuthenticationService } from './authentication.service';

const REQUIRED_PROPERTIES_CONFIG = ['firstName', 'lastName', 'email'];

@Injectable()
export class EmptyPropGuard implements CanActivate {
  constructor(
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private injector: Injector,
    // tslint:disable-next-line:typedef
    @Inject(EnvVariables) private envVariables
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (state.url.startsWith('/profile')) {
      return true;
    }

    return this.authenticationService.getUser().pipe(
      map((user: User) => {
        if (user && this.isRequiredPropEmpty(user) && this.isUserRegisterBeforeRelease(user)) {
          this.modalService.show(this.injector, updateProfileModalConfig);
          return false;
        } else {
          return true;
        }
      })
    );
  }

  private isUserRegisterBeforeRelease(user: User): boolean {
    return user.registrationDate <= this.envVariables.userManagementReleaseDate.getTime();
  }

  private isRequiredPropEmpty(user: User): boolean {
    return REQUIRED_PROPERTIES_CONFIG.some((key) => user[key] === null || user[key] === '');
  }
}
