import { Component } from '@angular/core';

import statisticProIcon from 'appy-gas-core/dist/assets/svg/icons/statistic-pro.svg';

@Component({
  selector: 'appy-gas-statistic-pro-button',
  templateUrl: './statistic-pro-button.component.html',
  styleUrls: ['./statistic-pro-button.component.scss']
})
export class StatisticProButtonComponent {
  public readonly statisticProIcon = statisticProIcon;
}
