// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".restricted-market-area{padding:30px 50px}.restricted-market-area__header{font-size:30px;font-weight:600;font-family:Montserrat;margin-bottom:1.5rem}.restricted-market-area__description-block{display:-ms-flexbox;display:flex}.restricted-market-area__description{font-size:14px;line-height:16px}.restricted-market-area__description-img{height:127px;position:relative;left:95px;top:0}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();