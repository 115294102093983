import { Routes } from '@angular/router';

import { CurrentUserResolverService, EmptyPropGuard } from '../http-auth';
import { LayoutComponent } from '../layout/layout.component';
import { TechnicalMaintenanceGuard } from '../technical-maintenance/technical-maintenance.guard';
/**
 * Provides helper methods to create routes.
 */
export class Route {
  /**
   * Creates routes using the shell component and authentication.
   * @param routes The routes to add.
   */
  public static withLayout(routes: Routes): Routes {
    return [
      {
        path: '',
        component: LayoutComponent,
        resolve: {
          currentUser: CurrentUserResolverService
        },
        canActivate: [TechnicalMaintenanceGuard, EmptyPropGuard],
        children: routes
      }
    ];
  }
}
