import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-switch-to-exclusive-days',
  templateUrl: './switch-to-exclusive-days.component.html',
  styleUrls: ['../user-transition-modals.scss', './switch-to-exclusive-days.component.scss']
})
export class SwitchToExclusiveDaysComponent {
  public proceedToNextStep: () => void;

  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }

  public switchToExclusive(): void {
    this.proceedToNextStep();
    this.modalService.hide();
  }
}
