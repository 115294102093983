import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core/dist';

@Component({
  selector: 'appy-gas-navigate-with-save',
  templateUrl: './navigate-with-save.component.html',
  styleUrls: ['./navigate-with-save.component.scss']
})
export class NavigateWithSaveComponent {
  constructor(private modalService: ModalService) {}

  public exitWithoutSaving(): void {
    this.modalService.methods.navigateWithoutSaving();
  }

  public saveChanges(): void {
    this.modalService.methods.navigateWithSaving();
  }
}
