export enum OrderClassesEnum {
  FIRST = 'first',
  SECOND = 'second',
  THIRD = 'third',
  FOURTH = 'fourth',
  FIFTH = 'fifth',
  SIXTH = 'sixth',
  SEVENTH = 'seventh',
  EIGHTH = 'eighth',
  NINTH = 'ninth'
}
