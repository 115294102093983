import { Component, EventEmitter, Input, Output } from '@angular/core';

import { TutorialArrowSideEnum, TutorialDirectionEnum, TutorialStepEnum } from '../../../profile/enums';
import { TutorialPopUpModel } from '../../../profile/interfaces/tutorial-pop-up.model';

@Component({
  selector: 'appy-gas-progress-pop-up',
  templateUrl: './progress-pop-up.component.html',
  styleUrls: ['./progress-pop-up.component.scss']
})
export class ProgressPopUpComponent {
  @Output() public changeStep: EventEmitter<TutorialPopUpModel> = new EventEmitter<TutorialPopUpModel>();
  @Output() public skipPopup: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public closePopup: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public readonly arrowSide: TutorialArrowSideEnum;
  @Input() public readonly description: string;
  @Input() public readonly generalProgress: number;
  @Input() public readonly singlePopup = false;
  @Input() public readonly singleSkipPopup = false;
  @Input() public readonly stepIndex: TutorialStepEnum;
  @Input() set hideTutorial(value: boolean) {
    this.hiddenPopUp = value;
  }

  public hiddenPopUp: boolean;

  public readonly tutorialDirectionEnum = TutorialDirectionEnum;
  public readonly tutorialArrowSideEnum = TutorialArrowSideEnum;

  public get showPrevious(): boolean {
    return this.stepIndex !== TutorialStepEnum.FIRST;
  }

  public get lastSlide(): boolean {
    return this.stepIndex === TutorialStepEnum.SIXTH;
  }

  public skipTutorial(event: MouseEvent): void {
    this.hiddenPopUp = true;
    this.skipPopup.emit(true);
    event.stopPropagation();
  }

  public closeTutorial(event: MouseEvent): void {
    this.hiddenPopUp = true;
    this.closePopup.emit(true);
    event.stopPropagation();
  }

  public changeTutorialStep(direction: TutorialDirectionEnum): void {
    this.changeStep.emit({ direction, stepIndex: this.stepIndex });
  }
}
