import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core/dist';

@Component({
  selector: 'appy-gas-switch-to-exclusive-only',
  templateUrl: './switch-to-exclusive-only.component.html',
  styleUrls: ['../user-transition-modals.scss', './switch-to-exclusive-only.component.scss']
})
export class SwitchToExclusiveOnlyComponent {
  public proceedToNextStep: () => void;

  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }

  public switchToExclusive(): void {
    this.modalService.hide();
    this.proceedToNextStep();
  }
}
