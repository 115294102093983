// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".checkbox{display:inline-block;margin-right:1rem;margin-bottom:1rem}.checkbox__control{display:-ms-flexbox;display:flex;cursor:pointer;padding:.75rem;margin:0;background-color:#eceef2;color:#2c2e3d;font-weight:500;border-radius:3px;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;transition:background-color .3s ease}.checkbox__control:hover{background-color:#d7d9dd}.checkbox__control.selected{background-color:#7200da;color:#fff}.checkbox__control input[type=checkbox]{display:none}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();