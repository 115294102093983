import isNil from 'lodash/isNil';
import round from 'lodash/round';

import { Injectable } from '@angular/core';

import { WidgetPhysicalPointValue } from 'appy-gas-core';
import {
  WidgetPhysicalPointIPModelUI,
  WidgetPhysicalPointLNGModelUI,
  WidgetPhysicalPointModelUI,
  WidgetPhysicalPointStorageModelUI
} from '../../flows-map/flows-map-area/flows-map-sidebar-pro/my-point-widget/interfaces';
import {
  DirectionIdTypeEnum,
  IpPhysicalPointVariablesEnum,
  LNGPhysicalPointVariablesEnum,
  StoragePhysicalPointVariablesEnum
} from '../enums';

@Injectable({
  providedIn: 'root'
})
export class FlowsMapHelperService {
  public static getStorageAggregatedFields(
    storagePoint: WidgetPhysicalPointModelUI
  ): WidgetPhysicalPointStorageModelUI {
    const maxInjectionRate = storagePoint.values.find(
      (value) => value.eePointVariableId === StoragePhysicalPointVariablesEnum.TECHNICAL_ENTRY
    );
    const injectionNom = storagePoint.values.find(
      (value) => value.eePointVariableId === StoragePhysicalPointVariablesEnum.CLEAN_NOM_ENTRY
    );
    const maxWithdrawalRate = storagePoint.values.find(
      (value) => value.eePointVariableId === StoragePhysicalPointVariablesEnum.TECHNICAL_EXIT
    );
    const withdrawalNom = storagePoint.values.find(
      (value) => value.eePointVariableId === StoragePhysicalPointVariablesEnum.CLEAN_NOM_EXIT
    );
    const gasInStorage = storagePoint.values.find(
      (value) => value.eePointVariableId === StoragePhysicalPointVariablesEnum.VOLUME_STORED
    );
    const workingGasVolume = storagePoint.values.find(
      (value) => value.eePointVariableId === StoragePhysicalPointVariablesEnum.VOLUME_MAX
    );
    return {
      ...storagePoint,
      fillingLevel: FlowsMapHelperService.getFillingCalculations(gasInStorage, workingGasVolume),
      usageRateEntry: FlowsMapHelperService.getFillingCalculations(injectionNom, maxInjectionRate),
      usageRateExit: FlowsMapHelperService.getFillingCalculations(withdrawalNom, maxWithdrawalRate)
    };
  }

  public static getLNGAggregatedFields(LNGPoint: WidgetPhysicalPointModelUI): WidgetPhysicalPointLNGModelUI {
    const sendOutCapacity = LNGPoint.values.find(
      (value) => value.eePointVariableId === LNGPhysicalPointVariablesEnum.TECHNICAL_ENTRY
    );
    const storageCapacity = LNGPoint.values.find(
      (value) => value.eePointVariableId === LNGPhysicalPointVariablesEnum.VOLUME_MAX
    );
    const nominationEntry = LNGPoint.values.find(
      (value) => value.eePointVariableId === LNGPhysicalPointVariablesEnum.CLEAN_NOM_ENTRY
    );
    const volumeStored = LNGPoint.values.find(
      (value) => value.eePointVariableId === LNGPhysicalPointVariablesEnum.VOLUME_STORED
    );

    return {
      ...LNGPoint,
      sendOutRate: FlowsMapHelperService.getFillingCalculations(nominationEntry, sendOutCapacity),
      fillingLevel: FlowsMapHelperService.getFillingCalculations(volumeStored, storageCapacity)
    };
  }

  public static getIPAggregatedFields(IPPoint: WidgetPhysicalPointModelUI): WidgetPhysicalPointIPModelUI {
    const physicalFlow = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.PHYSICAL_FLOW
    );
    const nominationEntry = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.CLEAN_NOM_ENTRY
    );
    const nominationExit = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.CLEAN_NOM_EXIT
    );
    const tacEntry = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.TECHNICAL_ENTRY
    );
    const tacExit = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.TECHNICAL_EXIT
    );
    const bookedFirmEntry = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.BOOKED_ALL_FIRM_CAPACITY_ENTRY
    );
    const bookedFirmExit = IPPoint.values.find(
      (value) => value.eePointVariableId === IpPhysicalPointVariablesEnum.BOOKED_ALL_FIRM_CAPACITY_EXIT
    );
    const getUsageRateBookedFirm = (numValEntry, numValExit): number => {
      if (!physicalFlow) {
        return null;
      }

      if (physicalFlow.value > 0) {
        return FlowsMapHelperService.getFillingCalculations(numValEntry, tacEntry);
      } else {
        return FlowsMapHelperService.getFillingCalculations(numValExit, tacExit);
      }
    };
    const getDirection = (): DirectionIdTypeEnum => {
      if (!physicalFlow) {
        return null;
      }

      return physicalFlow?.value > 0 ? DirectionIdTypeEnum.ENTRY : DirectionIdTypeEnum.EXIT;
    };

    return {
      ...IPPoint,
      direction: getDirection(),
      usageRate: getUsageRateBookedFirm(nominationEntry, nominationExit),
      statusBookedFirm: getUsageRateBookedFirm(bookedFirmEntry, bookedFirmExit)
    };
  }

  private static getFillingCalculations(
    numValue: WidgetPhysicalPointValue,
    denomValue: WidgetPhysicalPointValue
  ): number {
    let calcResult: number;

    if (!isNil(numValue) && !isNil(denomValue) && denomValue?.value !== 0 && numValue?.value !== 0) {
      calcResult = round((numValue.value / denomValue.value) * 100, 0);
    } else if (!isNil(numValue) && !isNil(denomValue) && denomValue?.value === 0 && numValue?.value === 0) {
      calcResult = 0;
    } else {
      calcResult = null;
    }

    return calcResult && calcResult > 100 ? 100 : calcResult;
  }
}
