import { Component, OnInit } from '@angular/core';

import closeIcon from 'appy-gas-core/dist/assets/svg/icons/close.svg';
import { AuthenticationService } from '../../http-auth';

enum TechnicalDebtBannerState {
  IS_VISIBLE = 'is-visible',
  IS_HIDDEN = 'is-hidden'
}

@Component({
  selector: 'appy-gas-technical-debt-popover',
  templateUrl: './technical-debt-popover.component.html',
  styleUrls: ['./technical-debt-popover.component.scss']
})
export class TechnicalDebtPopoverComponent implements OnInit {
  public visible = false;
  public icons: any = {
    close: closeIcon
  };

  constructor(private authenticationService: AuthenticationService) {}

  public ngOnInit(): void {
    this.handleAuthSubscription();
  }

  public closePopover(): void {
    this.visible = false;
    localStorage.setItem('APPYGAS_TECHNICAL_DEBT', TechnicalDebtBannerState.IS_HIDDEN);
  }

  private handleAuthSubscription(): void {
    this.authenticationService.authenticate.subscribe((value: boolean) => {
      const bannerVisibility = localStorage.getItem('APPYGAS_TECHNICAL_DEBT');

      if (value === false) {
        this.visible = value;
        localStorage.setItem('APPYGAS_TECHNICAL_DEBT', TechnicalDebtBannerState.IS_VISIBLE);
      }

      if (bannerVisibility === TechnicalDebtBannerState.IS_HIDDEN) {
        this.visible = false;
        return;
      }

      this.visible = value;
    });
  }
}
