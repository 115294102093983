import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ErrorTypeListEnum, Logger, ModalService } from 'appy-gas-core';
import { ToastrService } from 'ngx-toastr';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthenticationService } from '../authentication.service';

const log = new Logger('HttpWebService');
const TOAST_MESSAGES = {
  NEW_LOGIN: 'New login was identified, this session is no longer valid'
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private static defaultErrorHandler(error: HttpErrorResponse): Observable<any> {
    log.error('Request error', error);
    return throwError(error);
  }

  constructor(
    private authService: AuthenticationService,
    public modalService: ModalService,
    public toastService: ToastrService,
    public router: Router
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        // Intercept if user uses few devices
        if (event instanceof HttpResponse && event.status === ErrorTypeListEnum.NO_CONTENT) {
          log.warn(TOAST_MESSAGES.NEW_LOGIN, event.statusText);
          this.handle204DoubleUserSession();
        }
      }),
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case ErrorTypeListEnum.BAD_REQUEST:
            return this.handleInvalidGrantError(error);
          case ErrorTypeListEnum.FORBIDDEN:
            return ErrorInterceptor.defaultErrorHandler(error);
          default:
            return ErrorInterceptor.defaultErrorHandler(error);
        }
      })
    );
  }

  private handle204DoubleUserSession(): void {
    // Users DataLayer for GA
    if (this.authService && this.authService.user && this.authService.user.email) {
      // window['dataLayer'] = window['dataLayer'] || [];
      window['dataLayer'].push({ email: this.authService.user.email });
    }

    this.authService.logout();
    this.router.navigate(['/auth/login'], { queryParams: { doubleUserSession: true } });
    // 'User with the same credentials logged in on another machine!'
    this.toastService.warning(TOAST_MESSAGES.NEW_LOGIN, '', {
      timeOut: 20000,
      messageClass: 'not-valid-session'
    });
    // throw new Error('User with the same credentials logged in on another machine! Double users session');
  }

  private handleInvalidGrantError(error: HttpErrorResponse): Observable<any> {
    if (error?.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      this.logout();
      return throwError(error.error);
    }
    log.error('Request error', error);
    return throwError(error);
  }

  private logout(): void {
    this.router.navigate(['/auth/login']);
  }
}
