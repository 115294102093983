import { Selector } from '@ngxs/store';
import isAfter from 'date-fns/isAfter';

import { dateToGasDayRange, MarketAreaActivePoint, MarketAreaViewGeneralInfo } from 'appy-gas-core';
import { MarketAreaViewHelperService } from '../../shared/services/market-area-view-helper.service';
import { CommonEntitiesSelectors } from '../common-entities';
import { MarketAreaViewState, MarketAreaViewStateModel } from './market-area-view.state';

export class MarketAreaViewSelectors {
  @Selector([MarketAreaViewState, CommonEntitiesSelectors.eePoints])
  public static leftMarketAreaData(
    state: MarketAreaViewStateModel,
    eePoints: MarketAreaActivePoint[]
  ): MarketAreaViewGeneralInfo {
    return { ...state.leftMarketAreaView, eePoints };
  }

  @Selector([MarketAreaViewState, CommonEntitiesSelectors.eePoints])
  public static rightMarketAreaData(
    state: MarketAreaViewStateModel,
    eePoints: MarketAreaActivePoint[]
  ): MarketAreaViewGeneralInfo {
    return { ...state.rightMarketAreaView, eePoints };
  }

  @Selector([MarketAreaViewSelectors.leftMarketAreaData])
  public static isMergedGermanyLeftMAState(leftMarketAreaData: MarketAreaViewGeneralInfo): boolean {
    return isAfter(leftMarketAreaData.date, dateToGasDayRange(new Date('2021-09-30')).timeFrom);
  }

  @Selector([MarketAreaViewSelectors.rightMarketAreaData])
  public static isMergedGermanyRightMAState(rightMarketAreaData: MarketAreaViewGeneralInfo): boolean {
    return isAfter(rightMarketAreaData.date, dateToGasDayRange(new Date('2021-09-30')).timeFrom);
  }

  @Selector([MarketAreaViewSelectors.leftMarketAreaData, MarketAreaViewSelectors.isMergedGermanyLeftMAState])
  public static leftMarketAreaDataFilteredVIPPoints(
    leftMarketAreaData: MarketAreaViewGeneralInfo,
    isMergedGermanyLeftMAState: boolean
  ): MarketAreaViewGeneralInfo {
    leftMarketAreaData.marketArea.virtualPoints = MarketAreaViewHelperService.filterVirtualPoints(
      isMergedGermanyLeftMAState,
      leftMarketAreaData.marketArea.virtualPoints
    );

    return leftMarketAreaData;
  }

  @Selector([MarketAreaViewSelectors.rightMarketAreaData, MarketAreaViewSelectors.isMergedGermanyRightMAState])
  public static rightMarketAreaDataFilteredVIPPoints(
    rightMarketAreaData: MarketAreaViewGeneralInfo,
    isMergedGermanyRightMAState: boolean
  ): MarketAreaViewGeneralInfo {
    rightMarketAreaData.marketArea.virtualPoints = MarketAreaViewHelperService.filterVirtualPoints(
      isMergedGermanyRightMAState,
      rightMarketAreaData.marketArea.virtualPoints
    );

    return rightMarketAreaData;
  }
}
