import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SvgIconModule } from 'appy-gas-core';

import { FooterComponent } from './footer';
import { LayoutComponent } from './layout.component';

@NgModule({
  imports: [CommonModule, RouterModule, SvgIconModule],
  declarations: [FooterComponent, LayoutComponent],
  exports: [LayoutComponent]
})
export class LayoutModule {}
