export enum EventClassNamesEnum {
  ONE_DAY = 'one-day-event',
  FIRST_DAY = 'first-day-event',
  LAST_DAY = 'last-day-event',
  CURRENT_EVENT = 'current-event',
  OTHER_EVENT = 'other-event',
  FIRST_ROW = 'row-1',
  SECOND_ROW = 'row-2',
  THIRD_ROW = 'row-3',
  FOURTH_ROW = 'row-4',
  HIDDEN_ROW = 'row-hidden'
}
