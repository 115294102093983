import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-exclusive-access-expired',
  templateUrl: './exclusive-access-expired.component.html',
  styleUrls: ['../user-transition-modals.scss']
})
export class ExclusiveAccessExpiredComponent {
  constructor(private modalService: ModalService, private router: Router) {}

  public hideModal(): void {
    this.modalService.hide();
  }

  public goToContactUsPage(): void {
    this.router.navigateByUrl('static/contact-us/upgrade-exclusive').then(() => this.hideModal());
  }
}
