import { Action, State, StateContext } from '@ngxs/store';

import { MarketAreaViewGeneralInfo } from 'appy-gas-core';
import {
  GetMarketArea,
  GetPointDetails,
  ResetPointDetails,
  SetMarketAreaDate,
  SetMarketAreaViewGeneralInfo
} from './market-area-view.actions';

export interface MarketAreaViewStateModel {
  leftMarketAreaView: MarketAreaViewGeneralInfo;
  rightMarketAreaView: MarketAreaViewGeneralInfo;
}

@State<MarketAreaViewStateModel>({
  name: 'marketAreaView',
  defaults: {
    leftMarketAreaView: undefined,
    rightMarketAreaView: undefined
  }
})
export class MarketAreaViewState {
  @Action(SetMarketAreaViewGeneralInfo)
  private setMarketAreaViewGeneralInfo(
    { patchState }: StateContext<MarketAreaViewStateModel>,
    { marketAreaViewGeneralInfo, marketAreaPosition }: SetMarketAreaViewGeneralInfo
  ): void {
    patchState({ [marketAreaPosition]: marketAreaViewGeneralInfo });
  }

  @Action(SetMarketAreaDate)
  private setMarketAreaDate(
    { patchState, getState }: StateContext<MarketAreaViewStateModel>,
    { date, marketAreaPosition }: SetMarketAreaDate
  ): void {
    const state = getState();
    patchState({ [marketAreaPosition]: { ...state[marketAreaPosition], date } });
  }

  @Action(GetPointDetails)
  private getPointDetails(
    { patchState, getState }: StateContext<MarketAreaViewStateModel>,
    { virtualPointData, marketAreaPosition }: GetPointDetails
  ): void {
    const state = getState();
    patchState({ [marketAreaPosition]: { ...state[marketAreaPosition], vip: virtualPointData } });
  }

  @Action(ResetPointDetails)
  private resetPointDetails(
    { patchState, getState }: StateContext<MarketAreaViewStateModel>,
    { marketAreaPosition }: ResetPointDetails
  ): void {
    const state = getState();
    patchState({ [marketAreaPosition]: { ...state[marketAreaPosition], vip: undefined } });
  }

  @Action(GetMarketArea)
  private getMarketArea(
    { patchState, getState }: StateContext<MarketAreaViewStateModel>,
    { marketAreaConfig, marketAreaPosition }: GetMarketArea
  ): void {
    const state = getState();
    patchState({
      [marketAreaPosition]: {
        ...state[marketAreaPosition],
        marketArea: marketAreaConfig.marketArea,
        date: marketAreaConfig.date
      }
    });
  }
}
