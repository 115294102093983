import { NgxSliderModule } from '@m0t0r/ngx-slider';
import { NgbDropdownModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { PlotlyModule } from 'angular-plotly.js';
import { ComponentsModule, PipesCoreModule } from 'appy-gas-core';
import { NgxPaginationModule } from 'ngx-pagination';
import { BadgeIconComponent } from './components/badge-icon/badge-icon.component';
import { CheckboxGroupComponent } from './components/checkbox-group';
import { DateRangeSliderComponent } from './components/date-range-slider/date-range-slider.component';
import { ExpandableSectionComponent } from './components/expandable-section';
import { LinkWithIconComponent } from './components/link-with-icon';
import { LoaderComponent } from './components/loader';
import { MaintenanceDashboardFilterComponent } from './components/maintenance-dashboard-filter';
import { NavigateWithSaveComponent } from './components/navigate-with-save/navigate-with-save.component';
import { PercentageCircleComponent } from './components/percentage-circle';
import { PrivacyPolicyPopUpComponent } from './components/privacy-policy-popup/privacy-policy-pop-up.component';
import { ProgressPopUpComponent } from './components/progress-pop-up/progress-pop-up.component';
import { RestrictElementComponent } from './components/restrict-element';
import { SearchInputComponent } from './components/search-input';
import { SearchPointsDropdownComponent } from './components/search-points-dropdown/search-points-dropdown.component';
import { ShowMoreComponent } from './components/show-more/show-more.component';
import { StatisticButtonComponent } from './components/statistic-button';
import { StatisticProButtonComponent } from './components/statistic-pro-button';
import {
  AccessExpiredComponent,
  ClassicUserExclusiveExpiredComponent,
  ExclusiveAccessExpiredComponent,
  InfoModalComponent,
  MaxSearchesComponent,
  RestrictedMarketAreaClassicComponent,
  RestrictedMarketAreaPremiumComponent,
  SwitchDeclinedComponent,
  SwitchToExclusiveComponent,
  SwitchToExclusiveDaysComponent,
  SwitchToExclusiveInfoComponent,
  SwitchToExclusiveOnlyComponent,
  TermsOfServiceUpdateComponent,
  TrialExclusiveAccessExpiredComponent,
  YouAreExclusiveUserComponent
} from './components/user-transition-modals';
import { AllowActionDirective } from './directives/allow-action';

import * as PlotlyJS from 'plotly.js/dist/plotly.js';

PlotlyModule.plotlyjs = PlotlyJS;

const sharedComponents = [
  CheckboxGroupComponent,
  LinkWithIconComponent,
  LoaderComponent,
  StatisticButtonComponent,
  StatisticProButtonComponent,
  ShowMoreComponent,
  ExpandableSectionComponent,
  AllowActionDirective,
  RestrictElementComponent,
  SearchInputComponent,
  PercentageCircleComponent,
  NavigateWithSaveComponent,
  DateRangeSliderComponent,
  MaintenanceDashboardFilterComponent,
  DateRangeSliderComponent,
  BadgeIconComponent,
  ProgressPopUpComponent,
  PrivacyPolicyPopUpComponent,
  SwitchToExclusiveComponent,
  SwitchToExclusiveOnlyComponent,
  InfoModalComponent,
  SwitchDeclinedComponent,
  AccessExpiredComponent,
  ExclusiveAccessExpiredComponent,
  TrialExclusiveAccessExpiredComponent,
  TermsOfServiceUpdateComponent,
  SwitchToExclusiveDaysComponent,
  MaxSearchesComponent,
  SwitchToExclusiveInfoComponent,
  ClassicUserExclusiveExpiredComponent,
  YouAreExclusiveUserComponent,
  RestrictedMarketAreaPremiumComponent,
  RestrictedMarketAreaClassicComponent,
  SearchPointsDropdownComponent
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    PipesCoreModule,
    ComponentsModule,
    NgxPaginationModule,
    PlotlyModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgxSliderModule
  ],
  declarations: sharedComponents,
  exports: [
    ...sharedComponents,
    RouterModule,
    PipesCoreModule,
    ComponentsModule,
    NgxPaginationModule,
    PlotlyModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgxSliderModule
  ],
  entryComponents: [
    NavigateWithSaveComponent,
    SwitchToExclusiveComponent,
    InfoModalComponent,
    SwitchDeclinedComponent,
    AccessExpiredComponent,
    ExclusiveAccessExpiredComponent,
    TrialExclusiveAccessExpiredComponent,
    TermsOfServiceUpdateComponent,
    SwitchToExclusiveOnlyComponent,
    SwitchToExclusiveDaysComponent,
    MaxSearchesComponent,
    SwitchToExclusiveInfoComponent,
    ClassicUserExclusiveExpiredComponent,
    YouAreExclusiveUserComponent,
    RestrictedMarketAreaPremiumComponent,
    RestrictedMarketAreaClassicComponent
  ]
})
export class SharedModule {}
