import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { ChartsService } from 'appy-gas-core';

import '../styles/styles.scss';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core';
import { HeaderModule } from './core/layout/header/header.module';
import { MaitenancePageComponent } from './core/maitenance-page/maitenance-page.component';
import { PromotionBannerComponent } from './shared/components/promotion-banner/promotion-banner.component';
import { StoreModule } from './store/store.module';

@NgModule({
  imports: [BrowserModule, AppRoutingModule, CoreModule, StoreModule, HeaderModule],
  declarations: [AppComponent, MaitenancePageComponent, PromotionBannerComponent],
  providers: [ChartsService],
  bootstrap: [AppComponent]
})
export class AppModule {}
