import { Action, State, StateContext } from '@ngxs/store';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import mapValues from 'lodash/mapValues';

import {
  ChartDataItemModel,
  EventDetailsModel,
  MaintenanceEventModel,
  MaintenanceEventsModel,
  MaintenanceMapPoint,
  OtherEventModel,
  RangeContext,
  RelatedPointsMaintenanceModel
} from 'appy-gas-core';

import {
  ImpactRadioTypes,
  MaintenanceFiltersModel
} from '../../availability-pro/availability-pro-map/availability-pro-map-filter/interfaces';
import { MaintenanceMapRoutePointUI } from '../../availability-pro/availability-pro-map/availability-pro-market-areas-map/interfaces';
import { WidgetTypesEnum } from '../../availability-pro/availability-pro-map/interfaces';
import { norwayDataSourceId } from '../../shared';
import { MAEventsTabDirection, UserPointTypesEnum } from '../../shared/enums';
import { PointOperatorsFilter } from '../../shared/interfaces';
import {
  ChangeAvailabilityPointTypes,
  GetAllEventPoints,
  GetAllEventPointsFailed,
  GetAllEventPointsSuccess,
  GetAllMaintenancePoints,
  GetAvailabilityData,
  GetEventDetails,
  GetEventDetailsLoading,
  GetImpactChartData,
  GetImpactChartDataLoading,
  GetOtherEvents,
  GetOtherEventsLoading,
  GetPortfolioMaintenancePoints,
  GetPortfolioMaintenancePointsFailed,
  GetPortfolioMaintenancePointsSuccess,
  GetRelatedPoints,
  GetRelatedPointsLoading,
  GetSelectedNorwayEvents,
  GetSelectedPEGSuperPointsEvents,
  GetSelectedPointEvents,
  GetSelectedPointEventsFailed,
  GetSelectedPointEventsSuccess,
  GetSelectedRouteDetails,
  GetSelectedRouteDetailsFailed,
  GetSelectedRouteDetailsSuccess,
  ResetPortfolioMaintenancePoints,
  ResetSelectedPointEvents,
  ResetSelectedRouteDetails,
  ResetSelectedRoutePoints,
  SetNavigatedFromExternalPageStatus,
  SetNavigatedFromGeneralInfoPageStatus,
  SetNorwayPointSelectedStatus,
  SetSelectedMapPointId,
  TogglePointInRoute,
  UpdateActiveMATabType,
  UpdateGasDayRange,
  UpdateMaintenanceFilters,
  UpdateSelectedPointEventsFilter,
  UpdateWidgetsVisibilityState
} from './availability.actions';

export interface GeneralInfoModel {
  relatedPoints: {
    [key: number]: {
      data: RelatedPointsMaintenanceModel[];
      loading: boolean;
      loaded: boolean;
    };
  };
  eventDetails: {
    [key: number]: {
      data: EventDetailsModel;
      loading: boolean;
      loaded: boolean;
    };
  };
  otherEvents: {
    [key: number]: {
      data: OtherEventModel[];
      loading: boolean;
      loaded: boolean;
    };
  };
  impactChartData: {
    [key: number]: {
      data: ChartDataItemModel[];
      loading: boolean;
      loaded: boolean;
    };
  };
}

export interface WidgetsVisibilityModel {
  [key: number]: { id: WidgetTypesEnum; hidden: boolean };
}

export interface AvailabilityStateModel {
  allMaintenancePoints: MaintenanceMapPoint[];
  allEventPoints: {
    data: MaintenanceEventsModel[];
    loading: boolean;
    loaded: boolean;
  };
  portfolioMaintenancePoints: {
    data: MaintenanceMapPoint[];
    loading: boolean;
    loaded: boolean;
  };
  selectedPointEvents: {
    data: MaintenanceEventModel[];
    loading: boolean;
    loaded: boolean;
  };
  selectedMapPointId: string;
  eventsWidgetFilter: PointOperatorsFilter;
  pointsSwitcher: UserPointTypesEnum;
  gasDayRange: RangeContext;
  selectedRouteEvents: {
    data: MaintenanceEventModel[];
    loading: boolean;
    loaded: boolean;
  };
  selectedRoutePoints: MaintenanceMapRoutePointUI[];
  maintenanceFilters: MaintenanceFiltersModel;
  generalInfo: GeneralInfoModel;
  widgetsVisibilityState: WidgetsVisibilityModel;
  activeMATabType: MAEventsTabDirection;
  isNavigatedFromExternalPage: boolean;
  isNavigatedFromGeneralInfoPage: boolean;
  isNorwayPointSelected: boolean;
}

@State<AvailabilityStateModel>({
  name: 'availability',
  defaults: {
    allMaintenancePoints: [],
    allEventPoints: {
      data: [],
      loading: false,
      loaded: false
    },
    portfolioMaintenancePoints: {
      data: [],
      loading: false,
      loaded: false
    },
    selectedPointEvents: {
      data: undefined,
      loading: false,
      loaded: false
    },
    selectedMapPointId: undefined,
    eventsWidgetFilter: undefined,
    pointsSwitcher: UserPointTypesEnum.ALL_POINTS,
    gasDayRange: {
      timeFrom: undefined,
      timeTo: undefined
    },
    selectedRouteEvents: {
      data: undefined,
      loading: false,
      loaded: false
    },
    selectedRoutePoints: [],
    maintenanceFilters: {
      sso: true,
      tso: true,
      firm: true,
      interruptible: false,
      impact: ImpactRadioTypes.ALL
    },
    generalInfo: {
      relatedPoints: undefined,
      eventDetails: undefined,
      otherEvents: undefined,
      impactChartData: undefined
    },
    widgetsVisibilityState: {
      [WidgetTypesEnum.GAS_PERIOD_CALENDAR]: { id: WidgetTypesEnum.GAS_PERIOD_CALENDAR, hidden: true },
      [WidgetTypesEnum.EVENTS]: { id: WidgetTypesEnum.EVENTS, hidden: true },
      [WidgetTypesEnum.PICK_A_ROUTE]: { id: WidgetTypesEnum.PICK_A_ROUTE, hidden: true },
      [WidgetTypesEnum.FILTERS]: { id: WidgetTypesEnum.FILTERS, hidden: true },
      [WidgetTypesEnum.LEGEND]: { id: WidgetTypesEnum.LEGEND, hidden: false }
    },
    activeMATabType: MAEventsTabDirection.ENTRY_DIRECTION,
    isNavigatedFromExternalPage: false,
    isNavigatedFromGeneralInfoPage: false,
    isNorwayPointSelected: false
  }
})
export class AvailabilityState {
  @Action(GetAllMaintenancePoints)
  private getAllMaintenancePoints(
    { patchState }: StateContext<AvailabilityStateModel>,
    { allMaintenancePoints }: GetAllMaintenancePoints
  ): void {
    patchState({ allMaintenancePoints });
  }

  @Action(GetAllEventPoints)
  private getEventPoints(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ allEventPoints: { ...state.allEventPoints, loading: true } });
  }

  @Action(GetAllEventPointsSuccess)
  private getEventPointsSuccess(
    { patchState }: StateContext<AvailabilityStateModel>,
    { allEventPoints }: GetAllEventPointsSuccess
  ): void {
    patchState({ allEventPoints: { data: allEventPoints, loaded: true, loading: false } });
  }

  @Action(GetAllEventPointsFailed)
  private getEventPointsFailed(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ allEventPoints: { ...state.allEventPoints, loaded: false, loading: false } });
  }

  @Action(GetPortfolioMaintenancePoints)
  private getPortfolioMaintenancePoints(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({ portfolioMaintenancePoints: { ...state.portfolioMaintenancePoints, loading: true } });
  }

  @Action(ResetPortfolioMaintenancePoints)
  private resetPortfolioMaintenancePoints({ patchState }: StateContext<AvailabilityStateModel>): void {
    patchState({ portfolioMaintenancePoints: { data: [], loaded: false, loading: false } });
  }

  @Action(GetPortfolioMaintenancePointsSuccess)
  private getPortfolioMaintenancePointsSuccess(
    { patchState }: StateContext<AvailabilityStateModel>,
    { portfolioMaintenancePoints }: GetPortfolioMaintenancePointsSuccess
  ): void {
    patchState({
      portfolioMaintenancePoints: { data: portfolioMaintenancePoints, loaded: true, loading: false }
    });
  }

  @Action(GetPortfolioMaintenancePointsFailed)
  private getPortfolioMaintenancePointsFailed(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      portfolioMaintenancePoints: { ...state.portfolioMaintenancePoints, loaded: false, loading: false }
    });
  }

  @Action(GetAvailabilityData)
  private getAvailabilityData(
    { patchState }: StateContext<AvailabilityStateModel>,
    { availabilityData }: GetAvailabilityData
  ): void {
    patchState({
      allMaintenancePoints: availabilityData[0]
    });
  }

  @Action(GetSelectedPointEvents)
  private getSelectedPointEvents(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      selectedPointEvents: { ...state.selectedPointEvents, loading: true }
    });
  }

  @Action(ResetSelectedPointEvents)
  private resetSelectedPointEvents({ patchState }: StateContext<AvailabilityStateModel>): void {
    patchState({
      selectedPointEvents: { data: undefined, loaded: false, loading: false }
    });
  }

  @Action(GetSelectedPointEventsSuccess)
  private getSelectedPointEventsSuccess(
    { patchState }: StateContext<AvailabilityStateModel>,
    { selectedPointEvents, vipPointMarketAreasIds }: GetSelectedPointEventsSuccess
  ): void {
    const events = selectedPointEvents?.events.length ? selectedPointEvents.events : [];
    const eventsDetailsWithRelatedMAIds = map(events, (eventsDetails) => ({
      ...eventsDetails,
      marketAreasIds: vipPointMarketAreasIds,
      virtualPointId: selectedPointEvents.virtualPointId
    }));

    patchState({ selectedPointEvents: { data: eventsDetailsWithRelatedMAIds, loaded: true, loading: false } });
  }

  @Action(GetSelectedPointEventsFailed)
  private getSelectedPointEventsFailed(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      selectedPointEvents: { ...state.selectedPointEvents, loaded: false, loading: false }
    });
  }

  @Action(UpdateSelectedPointEventsFilter)
  private updateSelectedPointEventsFilter(
    { patchState }: StateContext<AvailabilityStateModel>,
    { eventsWidgetFilter }: UpdateSelectedPointEventsFilter
  ): void {
    patchState({ eventsWidgetFilter });
  }

  @Action(GetSelectedNorwayEvents)
  private getSelectedNorway(
    { patchState }: StateContext<AvailabilityStateModel>,
    { selectedPointEvents, vipPointMarketAreasIds }: GetSelectedNorwayEvents
  ): void {
    const mappedNorwayEvents = flatten(
      selectedPointEvents.map((pointEvent) => {
        return pointEvent.events
          .map((event) => ({
            ...event,
            marketAreasIds: vipPointMarketAreasIds,
            virtualPointId: pointEvent.virtualPointId
          }))
          .filter((norwayEvent) => norwayEvent.dataSourceId === norwayDataSourceId);
      })
    );

    patchState({ selectedPointEvents: { data: mappedNorwayEvents, loaded: true, loading: false } });
  }

  @Action(GetSelectedPEGSuperPointsEvents)
  private getSelectedPEGSuperPointsEvents(
    { patchState }: StateContext<AvailabilityStateModel>,
    { selectedPointEvents, vipPointMarketAreasIds }: GetSelectedPEGSuperPointsEvents
  ): void {
    const mappedNorwayEvents = flatten(
      selectedPointEvents.map((pointEvent) => {
        return pointEvent.events.map((event) => ({
          ...event,
          marketAreasIds: vipPointMarketAreasIds,
          virtualPointId: pointEvent.virtualPointId
        }));
      })
    );

    patchState({ selectedPointEvents: { data: mappedNorwayEvents, loaded: true, loading: false } });
  }

  @Action(GetSelectedRouteDetails)
  private getSelectedRoute(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      selectedRouteEvents: { ...state.selectedRouteEvents, loading: true }
    });
  }

  @Action(ResetSelectedRouteDetails)
  private resetSelectedRoute({ patchState }: StateContext<AvailabilityStateModel>): void {
    patchState({
      selectedRouteEvents: { data: [], loaded: false, loading: false }
    });
  }

  @Action(GetSelectedRouteDetailsSuccess)
  private getSelectedRouteSuccess(
    { patchState }: StateContext<AvailabilityStateModel>,
    { selectedRouteEvents }: GetSelectedRouteDetailsSuccess
  ): void {
    const mappedRouteEvents = flatten(
      selectedRouteEvents.map((routeEvent) => {
        const eventsWithMaintenance = routeEvent.events.map((event) => ({
          ...event,
          virtualPointId: routeEvent.virtualPointId
        }));
        const eventsWithOutMaintenance = routeEvent.withoutMaintenances.map((event) => ({
          ...event,
          virtualPointId: routeEvent.virtualPointId
        }));
        return [...eventsWithMaintenance, ...eventsWithOutMaintenance];
      })
    );

    patchState({ selectedRouteEvents: { data: mappedRouteEvents, loaded: true, loading: false } });
  }

  @Action(GetSelectedRouteDetailsFailed)
  private getSelectedRouteFailed(ctx: StateContext<AvailabilityStateModel>): void {
    const state = ctx.getState();
    ctx.patchState({
      selectedRouteEvents: { ...state.selectedRouteEvents, loaded: false, loading: false }
    });
  }

  @Action(GetRelatedPointsLoading)
  private getRelatedPointsLoading(
    ctx: StateContext<AvailabilityStateModel>,
    { pointId }: GetRelatedPointsLoading
  ): void {
    const state = ctx.getState();
    const updatedPoints = {
      ...state.generalInfo.relatedPoints,
      [pointId]: { data: undefined, loaded: false, loading: true }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, relatedPoints: updatedPoints }
    });
  }

  @Action(GetRelatedPoints)
  private getRelatedPoints(
    ctx: StateContext<AvailabilityStateModel>,
    { relatedPoints, pointId }: GetRelatedPoints
  ): void {
    const state = ctx.getState();
    const updatedPoints = {
      ...state.generalInfo.relatedPoints,
      [pointId]: { data: relatedPoints.pointMaintenances, loaded: true, loading: false }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, relatedPoints: updatedPoints }
    });
  }

  @Action(GetEventDetailsLoading)
  private getEventDetailsLoading(ctx: StateContext<AvailabilityStateModel>, { pointId }: GetEventDetailsLoading): void {
    const state = ctx.getState();
    const updatedData = {
      ...state.generalInfo.eventDetails,
      [pointId]: { data: undefined, loaded: false, loading: true }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, eventDetails: updatedData }
    });
  }

  @Action(GetEventDetails)
  private getEventDetails(ctx: StateContext<AvailabilityStateModel>, { eventDetails, pointId }: GetEventDetails): void {
    const state = ctx.getState();
    const updatedPoints = {
      ...state.generalInfo.eventDetails,
      [pointId]: { data: eventDetails, loaded: true, loading: false }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, eventDetails: updatedPoints }
    });
  }

  @Action(GetOtherEventsLoading)
  private getOtherEventsLoading(ctx: StateContext<AvailabilityStateModel>, { pointId }: GetOtherEventsLoading): void {
    const state = ctx.getState();
    const updatedData = {
      ...state.generalInfo.otherEvents,
      [pointId]: { data: undefined, loaded: false, loading: true }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, otherEvents: updatedData }
    });
  }

  @Action(GetOtherEvents)
  private getOtherEvents(ctx: StateContext<AvailabilityStateModel>, { otherEvents, pointId }: GetOtherEvents): void {
    const state = ctx.getState();
    const updatedEvents = {
      ...state.generalInfo.otherEvents,
      [pointId]: { data: otherEvents, loaded: true, loading: false }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, otherEvents: updatedEvents }
    });
  }

  @Action(GetImpactChartDataLoading)
  private getImpactChartDataLoading(
    ctx: StateContext<AvailabilityStateModel>,
    { pointId }: GetImpactChartDataLoading
  ): void {
    const state = ctx.getState();
    const updatedData = {
      ...state.generalInfo.impactChartData,
      [pointId]: { data: undefined, loaded: false, loading: true }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, impactChartData: updatedData }
    });
  }

  @Action(GetImpactChartData)
  private getImpactChartData(
    ctx: StateContext<AvailabilityStateModel>,
    { impactChartData, pointId }: GetImpactChartData
  ): void {
    const state = ctx.getState();
    const updatedData = {
      ...state.generalInfo.impactChartData,
      [pointId]: { data: impactChartData, loaded: true, loading: false }
    };
    ctx.patchState({
      generalInfo: { ...state.generalInfo, impactChartData: updatedData }
    });
  }

  @Action(TogglePointInRoute)
  private addPointToRoute(ctx: StateContext<AvailabilityStateModel>, { virtualPoint }: TogglePointInRoute): void {
    const state = ctx.getState();
    const pointExist = state.selectedRoutePoints.some((routePoint) => routePoint.appyGasId === virtualPoint.appyGasId);

    if (!pointExist) {
      ctx.patchState({
        selectedRoutePoints: [...state.selectedRoutePoints, virtualPoint]
      });
    } else {
      ctx.patchState({
        selectedRoutePoints: state.selectedRoutePoints.filter(
          (routePoint) => routePoint.appyGasId !== virtualPoint.appyGasId
        )
      });
    }
  }

  @Action(ResetSelectedRoutePoints)
  private resetRoutePoints({ patchState }: StateContext<AvailabilityStateModel>): void {
    patchState({
      selectedRoutePoints: []
    });
  }

  @Action(UpdateMaintenanceFilters)
  private updateMaintenanceFilters(
    { patchState }: StateContext<AvailabilityStateModel>,
    { maintenanceFilters }: UpdateMaintenanceFilters
  ): void {
    patchState({
      maintenanceFilters
    });
  }

  @Action(ChangeAvailabilityPointTypes)
  private changePointTypes(
    { patchState }: StateContext<AvailabilityStateModel>,
    { pointsSwitcher }: ChangeAvailabilityPointTypes
  ): void {
    patchState({
      pointsSwitcher
    });
  }

  @Action(UpdateGasDayRange)
  private updateGasDayRange(
    { patchState }: StateContext<AvailabilityStateModel>,
    { gasDayRange }: UpdateGasDayRange
  ): void {
    patchState({
      gasDayRange
    });
  }

  @Action(UpdateWidgetsVisibilityState)
  private updateWidgetsVisibilityState(
    ctx: StateContext<AvailabilityStateModel>,
    { selectedWidget }: UpdateWidgetsVisibilityState
  ): void {
    const state = ctx.getState();

    ctx.patchState({
      widgetsVisibilityState: mapValues(state.widgetsVisibilityState, (widget) => {
        const hidden = widget.id === selectedWidget.id ? selectedWidget.hidden : true;
        return {
          ...widget,
          hidden
        };
      })
    });
  }

  @Action(UpdateActiveMATabType)
  private getActiveMATabType(
    { patchState }: StateContext<AvailabilityStateModel>,
    { activeMATabType }: UpdateActiveMATabType
  ): void {
    patchState({
      activeMATabType
    });
  }

  @Action(SetSelectedMapPointId)
  private SetSelectedMapPointId(
    { patchState }: StateContext<AvailabilityStateModel>,
    { selectedMapPointId }: SetSelectedMapPointId
  ): void {
    patchState({ selectedMapPointId });
  }

  @Action(SetNavigatedFromExternalPageStatus)
  private setNavigatedFromExternalPageStatus(
    { patchState }: StateContext<AvailabilityStateModel>,
    { isNavigatedFromExternalPage }: SetNavigatedFromExternalPageStatus
  ): void {
    patchState({ isNavigatedFromExternalPage });
  }

  @Action(SetNavigatedFromGeneralInfoPageStatus)
  private setNavigatedFromGeneralInfoPageStatus(
    { patchState }: StateContext<AvailabilityStateModel>,
    { isNavigatedFromGeneralInfoPage }: SetNavigatedFromGeneralInfoPageStatus
  ): void {
    patchState({ isNavigatedFromGeneralInfoPage });
  }

  @Action(SetNorwayPointSelectedStatus)
  private setNorwayPointSelectedStatus(
    { patchState }: StateContext<AvailabilityStateModel>,
    { isNorwayPointSelected }: SetNorwayPointSelectedStatus
  ): void {
    patchState({ isNorwayPointSelected });
  }
}
