import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select, Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { DataExportService, IDownloadFile } from 'appy-gas-core';

import { ExportedUrlFileModel } from '../../data-export/interfaces';
import { GetExportData, GetExportDataFailed, GetExportDataSuccess } from './data-export.actions';
import { DataExportSelectors } from './data-export.selectors';

@Injectable()
export class DataExportFacade {
  constructor(private dataExportService: DataExportService, private store: Store) {}

  @Select(DataExportSelectors.exportData)
  public exportData$: Observable<IDownloadFile[]>;

  @Dispatch()
  public getExportData(options: ExportedUrlFileModel): Observable<GetExportDataSuccess> {
    this.store.dispatch(new GetExportData());

    return this.dataExportService.loadDataInJson(options).pipe(
      catchError((err) => {
        this.store.dispatch(new GetExportDataFailed());
        return throwError(err);
      }),
      map((data: IDownloadFile[]) => new GetExportDataSuccess(data))
    );
  }
}
