import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

import { Store } from '@ngxs/store';
import { LocalStorageKeysEnum } from '../../shared/enums/local-storage-keys.enum';
import { UserRoleTransitionService } from '../../shared/services/user-role-transition.service';
import { UserSelectors } from '../../store';
import { welcomePopoverState } from './welcome-popover-state.config';

@Component({
  selector: 'appy-gas-welcome-popover',
  templateUrl: './welcome-popover.component.html',
  styleUrls: ['./welcome-popover.component.scss']
})
export class WelcomePopoverComponent implements OnInit {
  public welcomeForm: FormGroup;
  public showVideo = true;
  public isExclusiveUser$ = this.store.select(UserSelectors.isExclusiveUser);

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private userRoleTransitionService: UserRoleTransitionService,
    private store: Store
  ) {}

  public ngOnInit(): void {
    this.welcomeForm = this.formBuilder.group({
      showWelcomePopup: [false]
    });
  }

  public closeModal(): void {
    this.showVideo = false;
    this.activeModal.close();
    const checkValue = this.welcomeForm.get('showWelcomePopup').value;
    if (!checkValue) {
      window.localStorage.setItem(
        LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL,
        welcomePopoverState.IS_REGISTERED
      );
    } else {
      window.localStorage.setItem(LocalStorageKeysEnum.DO_NOT_SHOW_NEW_FEATURES_MODAL, welcomePopoverState.IS_HIDDEN);
    }
  }

  public switchToExclusive(): void {
    this.closeModal();
    this.userRoleTransitionService.switchToExclusive();
  }
}
