import { Component } from '@angular/core';

import { APP_LINKS } from '../core/config';

@Component({
  selector: 'appy-gas-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.scss']
})
export class AuthComponent {
  public links = APP_LINKS;
  public currentYear: number = new Date().getFullYear();
}
