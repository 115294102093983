import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { SearchModule, SvgIconModule } from 'appy-gas-core';

import { SharedModule } from '../../../shared';
import { HeaderComponent } from './header.component';

@NgModule({
  imports: [CommonModule, SharedModule, SvgIconModule, SearchModule, ReactiveFormsModule],
  declarations: [HeaderComponent],
  exports: [HeaderComponent]
})
export class HeaderModule {}
