import { Store } from '@ngxs/store';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { ModalService, Role, SearchService, User } from 'appy-gas-core';
import availabilityIcon from 'appy-gas-core/dist/assets/svg/icons/header/availability.svg';
import calculatorIcon from 'appy-gas-core/dist/assets/svg/icons/header/calculator.svg';
import homeIcon from 'appy-gas-core/dist/assets/svg/icons/header/dashboard.svg';
import exclusiveProfileIcon from 'appy-gas-core/dist/assets/svg/icons/header/exclusive-profile-new.svg';
import profileIcon from 'appy-gas-core/dist/assets/svg/icons/header/profile.svg';
import signOutIcon from 'appy-gas-core/dist/assets/svg/icons/header/sign-out.svg';
import walletIcon from 'appy-gas-core/dist/assets/svg/icons/header/wallet-icon.svg';
import search from 'appy-gas-core/dist/assets/svg/icons/search.svg';
import switchIcon from 'appy-gas-core/dist/assets/svg/icons/switch-icon.svg';

import {
  allowedUserRolesForSearchDropDown,
  headerNavigationLinks,
  noWalletForContractUsersKeys
} from '../../../shared';
import { accessExpiredConfig } from '../../../shared/components/user-transition-modals';
import { generalizedMappedUserRoles } from '../../../shared/constants/generalized-user-roles';
import { HeaderSearchArea } from '../../../shared/enums';
import { UpdateUserRoleResponseTypes } from '../../../shared/enums/update-user-role-response-types.enum';
import { SearchDropdownPoint } from '../../../shared/interfaces';
import { HeaderHelperService } from '../../../shared/services/header-helper.service';
import { UserRoleTransitionService } from '../../../shared/services/user-role-transition.service';
import {
  CommonEntitiesFacade,
  FlowsMapSelectors,
  ProfileFacade,
  ProfileSelectors,
  SetUserRole,
  UpdateUserProfileNewsLetter,
  UserSelectors
} from '../../../store';
import { AuthenticationService } from '../../http-auth';
import { upgradePremiumModalConfig } from '../../upgrade-premium';

interface NavigationLink {
  routerLink: string;
  icon: number;
  label: string;
  new?: boolean;
}

@Component({
  selector: 'appy-gas-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public activeSearch = false;
  public currentRole: string;
  public isContractUser: boolean;
  public isIE = window.navigator.userAgent.indexOf('MSIE ') > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  public isRestrictedHeaderActions = false;
  public licenseExpire: number;
  public marketAreasList: any[] = [];
  public searchForm: FormGroup;
  public suggestions: SearchDropdownPoint[] = [];

  public readonly appiesCount$: Observable<number> = this.store.select(ProfileSelectors.appiesCount);
  public readonly isContractUser$: Observable<boolean> = this.store.select(UserSelectors.isContractUser);
  public readonly isExclusiveUser$: Observable<boolean> = this.store.select(UserSelectors.isExclusiveUser);
  public readonly isSwitchableUser$: Observable<boolean> = this.store.select(UserSelectors.isSwitchableUser);

  private readonly user$ = this.store.select(UserSelectors.getUser);
  private readonly componentDestroyed$: Subject<void> = new Subject<void>();

  private routes = Object.freeze({
    [HeaderSearchArea.MA]: (params) => {
      this.router.navigate(['/flows-map']).then(() => {
        this.router.navigate([`/market-area-view/`], {
          queryParams: {
            firstArea: params.value.id,
            dateFirstArea: params.selectedDate
          }
        });
      });
    },
    [HeaderSearchArea.VIP]: (params) => {
      this.router.navigate(['/flows-map']).then(() => {
        this.router.navigate([`/market-area-view/`], {
          queryParams: {
            firstArea: params.value.marketAreaId,
            secondArea: params.value.marketAreaId,
            pointId: params.value.appyGasId,
            vipFirstArea: params.value.appyGasId,
            pointTypeIdFirstArea: params.value.eePointTypeId,
            vipSecondArea: params.value.appyGasId,
            pointTypeIdSecondArea: params.value.eePointTypeId,
            dateFirstArea: params.selectedDate
          }
        });
      });
    },
    [HeaderSearchArea.PIP]: (params) => {
      this.router.navigate(['/flows-map']).then(() => {
        this.router.navigate([`/market-area-view/`], {
          queryParams: {
            firstArea: params.value.marketAreaId,
            secondArea: params.parent.marketAreaId,
            pointId: params.parent.appyGasId,
            vipFirstArea: params.parent.appyGasId,
            pointTypeIdFirstArea: params.parent.eePointTypeId,
            vipSecondArea: params.parent.appyGasId,
            pointTypeIdSecondArea: params.parent.eePointTypeId,
            dateFirstArea: params.selectedDate
          }
        });
      });
    }
  });

  public navigationLinks: NavigationLink[] = headerNavigationLinks;

  public icons: any = {
    home: homeIcon,
    calculator: calculatorIcon,
    availability: availabilityIcon,
    signOut: signOutIcon,
    profile: profileIcon,
    exclusiveProfile: exclusiveProfileIcon,
    search,
    wallet: walletIcon,
    switchToExclusive: switchIcon
  };

  private get newsLetterValue(): boolean {
    return this.store.selectSnapshot(ProfileSelectors.userProfileNewsLetter);
  }

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private fb: FormBuilder,
    private searchService: SearchService,
    private modalService: ModalService,
    private store: Store,
    private injector: Injector,
    private commonEntitiesFacade: CommonEntitiesFacade,
    private profileFacade: ProfileFacade,
    private userRoleTransitionService: UserRoleTransitionService
  ) {}

  public ngOnInit(): void {
    this.getProfileInformation();
    this.getGeneralData();
    this.authSubscriptions();
    this.searchForm = this.fb.group({
      search: ['']
    });
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public onSubmit(form: FormGroup): void {
    const url = `/market-area-view/${form.value.search.id}`;
    this.router.navigate([url]);
  }

  public suggest(event: any, skip: unknown): void {
    const suggestions = this.marketAreasList.filter(
      (x) => x?.name.toLowerCase().includes(event.query.toLowerCase()) && x.name !== skip
    );

    this.suggestions = [];
    this.filterAndUpdateSuggestions(suggestions);
  }

  public focus(): void {
    this.authenticationService.allowRoles(allowedUserRolesForSearchDropDown).then((authorize) => {
      if (authorize) {
        if (!this.marketAreasList.length) {
          this.searchService.getMarketAreasForSearch().subscribe((data: SearchDropdownPoint[]) => {
            this.marketAreasList = data.filter(
              (searchItem) => !this.userRoleTransitionService.getRestrictedMAFeatureByMAId(searchItem.marketAreaId)
            );
            this.mapMarketAreaListNames();
            this.filterAndUpdateSuggestions(this.marketAreasList.filter((x) => x?.name !== ''));
          });
        }

        this.activeSearch = true;
      } else {
        this.modalService.show(this.injector, upgradePremiumModalConfig);
        return false;
      }
    });
  }

  public search(event: any, value: any): void {
    const parent = this.marketAreasList.find((item) => item.id === value.parentPointId);
    const selectedDate = this.store.selectSnapshot(FlowsMapSelectors.selectedDate);

    this.routes[value.resultType]({ value, parent, selectedDate });
    this.searchForm.reset();
  }

  public switchToExclusive(): void {
    this.userRoleTransitionService.switchToExclusive();
  }

  public showExpiredModal(): void {
    const config = {
      ...accessExpiredConfig,
      component: {
        ...accessExpiredConfig.component,
        options: {
          ...accessExpiredConfig.component.options,
          newsLetterValue: this.newsLetterValue,
          syncNewsLetterSubscription: (newsLetterValue) => this.syncNewsLetterSubscription(newsLetterValue)
        }
      }
    };
    this.modalService.show(this.injector, config);
  }

  public navigateToWallet(): void {
    this.router.navigateByUrl('profile/wallet');
  }

  private mapMarketAreaListNames(): void {
    this.marketAreasList.forEach((point) => {
      if (point.resultType === HeaderSearchArea.VIP) {
        const marketArea = this.marketAreasList.find((item) => point.marketAreaId === item.id);
        point.name = `${point.name} (${marketArea.name})`;
      }
      if (point.operatorName) {
        point.name = `${point.name} (${point.operatorName})`;
      }
    });
  }

  private syncNewsLetterSubscription(newsLetterValue: boolean): void {
    this.store.dispatch(new UpdateUserProfileNewsLetter(newsLetterValue));
  }

  private authSubscriptions(): void {
    this.authenticationService.roleExpDays.subscribe((expDays) => (this.licenseExpire = expDays));
    this.authenticationService.role
      .pipe(tap((role) => this.store.dispatch(new SetUserRole(role))))
      .subscribe((role) => {
        this.isRestrictedHeaderActions = Role.Expired === role;
        this.currentRole = generalizedMappedUserRoles.get(role);
      });
  }

  private getGeneralData(): void {
    this.isContractUser$
      .pipe(
        filter((val) => !!val),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe((val) => {
        this.isContractUser = val;
        const appiesCountAvailable = this.store.selectSnapshot(ProfileSelectors.appiesCount);
        const walletBalanceStatus = this.store.selectSnapshot(ProfileSelectors.walletBalanceStatus);

        if (noWalletForContractUsersKeys.includes(walletBalanceStatus)) {
          return;
        }

        if (!isNumber(appiesCountAvailable) && walletBalanceStatus !== UpdateUserRoleResponseTypes.BALANCE_NOT_FOUND) {
          this.profileFacade.getWalletInformation();
        }
      });
  }

  private getProfileInformation(): void {
    this.user$
      .pipe(
        filter((user: User) => !!user),
        distinctUntilChanged((prev, curr) => isEqual(prev, curr)),
        tap((user: User) => {
          this.profileFacade.getUserPortfolioPoints(user.id);
          this.commonEntitiesFacade.getEeVirtualPoints(new Date().getTime());
        }),
        takeUntil(this.componentDestroyed$)
      )
      .subscribe();
  }

  private filterAndUpdateSuggestions(suggestionsData: SearchDropdownPoint[]): void {
    const selectedDate = this.store.selectSnapshot(FlowsMapSelectors.selectedDate);

    this.suggestions = HeaderHelperService.filterSearchResultByGermanyMergeState(suggestionsData, selectedDate);
  }
}
