import { Component, Input } from '@angular/core';

import statisticIcon from 'appy-gas-core/dist/assets/svg/icons/statistic.svg';
@Component({
  selector: 'appy-gas-statistic-button',
  templateUrl: './statistic-button.component.html',
  styleUrls: ['./statistic-button.component.scss']
})
export class StatisticButtonComponent {
  @Input() public disabled: boolean;

  public icons: any = {
    statistic: statisticIcon
  };
}
