import { Injectable, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Route, Router, RouterStateSnapshot } from '@angular/router';

import { ModalService, Role, TokenType } from 'appy-gas-core';

import { AuthModalHolderService, AuthModalType } from '../../auth';
import { exclusiveAccessExpiredConfig } from '../../shared/components/user-transition-modals/exclusive-access-expired';
import { extendPremiumModalConfig } from '../extend-premium';
import { RoleNavigationHandlerService } from '../role-navigation-handler/role-navigation-handler.service';
import { RouterStateService } from '../router/router-state.service';
import { upgradePremiumModalConfig } from '../upgrade-premium';
import { AuthenticationService } from './authentication.service';
import { PermissionsRouterData } from './permissions-router-data.interface';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private injector: Injector,
    private routerState: RouterStateService,
    private modalHolder: AuthModalHolderService,
    private roleNavigationHandler: RoleNavigationHandlerService
  ) {}

  private static getPermissions(
    route: ActivatedRouteSnapshot | Route,
    state?: RouterStateSnapshot
  ): PermissionsRouterData {
    const purePermissions = !!route && route.data ? route.data['permissions'] : {};
    const permissions: PermissionsRouterData = {
      ...purePermissions
    };

    if (!!permissions.except && !Array.isArray(permissions.except)) {
      permissions.except = [permissions.except];
    }
    if (!!permissions.only && !Array.isArray(permissions.only)) {
      permissions.only = [permissions.only];
    }

    return permissions;
  }

  private static isPathAllowedRole(permissions: PermissionsRouterData, role: Role): boolean {
    return (
      (!!permissions.only && permissions.only.includes(role)) ||
      (!!permissions.except && !permissions.except.includes(role))
    );
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const authenticated = this.authenticationService.getToken(TokenType.ACCESS);
    const permissions: PermissionsRouterData = AuthenticationGuard.getPermissions(route, state);

    return this.hasPermissions(permissions, route, state).then((authorized) => {
      if (!authenticated && !authorized) {
        const modalConfig: any = {
          component: {
            options: {
              redirectUrl: state.url
            }
          },
          data: {}
        };
        // if it's direct link (no previous url)
        if (!this.routerState.getPreviousUrl()) {
          modalConfig.data.closable = false;
        }
        this.modalHolder.put(AuthModalType.LOGIN, modalConfig);
        return false;
      } else if (authenticated && !authorized) {
        if (AuthenticationGuard.isPathAllowedRole(permissions, Role.PremiumPlus)) {
          switch (this.authenticationService.roleSource.value) {
            case Role.Free:
            case Role.Trial:
              this.modalService.show(this.injector, upgradePremiumModalConfig);
              return;
            case Role.Premium:
              this.modalService.show(this.injector, extendPremiumModalConfig);
              return;
            case Role.Expired:
              this.roleNavigationHandler.put('/profile');
              this.modalService.show(this.injector, exclusiveAccessExpiredConfig);
          }
        } else {
          this.modalService.show(this.injector, upgradePremiumModalConfig);
        }
        return false;
      } else if ((authenticated || !authenticated) && authorized) {
        return true;
      }
    });
  }

  public canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const permissions = AuthenticationGuard.getPermissions(childRoute, state);
    return this.hasPermissions(permissions, childRoute, state);
  }

  private hasPermissions(
    permissions: PermissionsRouterData,
    route: ActivatedRouteSnapshot | Route,
    state?: RouterStateSnapshot
  ): Promise<boolean> {
    if (!!permissions.except && Array.isArray(permissions.except)) {
      return this.authenticationService.allowRoles(permissions.except).then((allow) => {
        if (allow) {
          return false;
        } else {
          if (permissions.only) {
            return this.checkOnlyPermissions(permissions, route, state);
          }
          return true;
        }
      });
    }

    if (permissions.only && Array.isArray(permissions.only)) {
      return this.checkOnlyPermissions(permissions, route, state);
    }
    return Promise.resolve(false);
  }

  private checkOnlyPermissions(
    purePermissions: any,
    route: ActivatedRouteSnapshot | Route,
    state?: RouterStateSnapshot
  ): Promise<boolean> {
    const permissions: PermissionsRouterData = {
      ...purePermissions
    };
    return this.authenticationService.allowRoles(permissions.only);
  }
}
