// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".update-profile__header-wrapper{padding:2rem 3.75rem;border-bottom:1px solid rgba(0,0,0,.125)}.update-profile__header{text-transform:none}.update-profile__controls-wrapper{padding:1.5rem 3.75rem 2.5rem;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();