// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".switch-declined{padding:30px 50px}.switch-declined__header{font-size:30px;font-weight:600;font-family:Montserrat;margin-bottom:1.5rem}.switch-declined__description-block{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-align:center;align-items:center;margin-bottom:15px}.switch-declined__description{font-size:12px;line-height:16px}.switch-declined__description-img{height:134px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();