// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".maintenance-page{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;font-family:Montserrat;height:100vh;padding-bottom:12%;background-color:#fff}.maintenance-page__title{font-size:36px;font-weight:600;color:#737b85;margin-top:20px;margin-bottom:40px}.maintenance-page__description{font-size:16px;line-height:24px;font-weight:500;color:#2c2f3d;text-align:center;margin-bottom:30px}.maintenance-page__gif{width:100px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();