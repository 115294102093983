import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core/dist';

@Component({
  selector: 'appy-gas-max-searches',
  templateUrl: './max-searches.component.html',
  styleUrls: ['../user-transition-modals.scss', './max-searches.component.scss']
})
export class MaxSearchesComponent {
  public proceedToNextStep: () => void;

  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }

  public switchToExclusive(): void {
    this.modalService.hide();
    this.proceedToNextStep();
  }
}
