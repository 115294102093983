import { VirtualPoint } from 'appy-gas-core';
import { virtualPointIdsAfterGermanyMerge, virtualPointIdsBeforeGermanyMerge } from '../constants/app-exceptions';

export class MarketAreaViewHelperService {
  public static filterVirtualPoints(isMergedGermanyMAState: boolean, virtualPoints: VirtualPoint[]): VirtualPoint[] {
    return isMergedGermanyMAState
      ? virtualPoints.filter((point) => !virtualPointIdsBeforeGermanyMerge.includes(point.appyGasId))
      : virtualPoints.filter((point) => !virtualPointIdsAfterGermanyMerge.includes(point.appyGasId));
  }
}
