// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":host{-ms-flex:1;flex:1}.promotion-banner__container{position:absolute;top:75px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;width:100%;z-index:10}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();