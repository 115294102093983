import { FlowsMapTabType, VirtualPoint, VirtualPointType, WidgetPhysicalPointModel } from 'appy-gas-core';

import { FlowsMapWidgetStateModel } from '../../flows-map/interfaces';
import { UserPointTypesEnum } from '../../shared/enums';
import { PointOperatorsFilter } from '../../shared/interfaces';

export class GetVirtualPoints {
  public static readonly type = '[Flows Map Module] Get Virtual Points';
}

export class GetVirtualPointsSuccess {
  public static readonly type = '[Flows Map Module] Get Virtual Points Success';
  constructor(public virtualPoints: VirtualPoint[]) {}
}

export class GetVirtualPointsFailed {
  public static readonly type = '[Flows Map Module] Get Virtual Points Failed';
}

export class GetPhysicalPoints {
  public static readonly type = '[Flows Map Module] Get Physical Points';
}

export class GetPhysicalPointsSuccess {
  public static readonly type = '[Flows Map Module] Get Physical Points Success';
  constructor(public physicalPoints: WidgetPhysicalPointModel[]) {}
}

export class GetPhysicalPointsFailed {
  public static readonly type = '[Flows Map Module] Get Physical Points Failed';
}

export class SetSelectedDate {
  public static readonly type = '[Flows Map Module] Set Selected Date';
  constructor(public selectedDate: number) {}
}

export class SetActiveFilterTab {
  public static readonly type = '[Flows Map Module] Set Active Filter Tab';
  constructor(public activeFilterTab: FlowsMapTabType) {}
}

export class SetSelectedChartPoints {
  public static readonly type = '[Flows Map Module] Set Selected Chart Points';
  constructor(public selectedChartPoints: VirtualPoint[]) {}
}

export class SetSelectedTypesFilter {
  public static readonly type = '[Flows Map Module] Set Selected Types Filter';
  constructor(public selectedTypesFilter: VirtualPointType[]) {}
}

export class UpdateFlowsMapWidgetsVisibilityState {
  public static readonly type = '[Flows Map Module] Update Flows Map Widgets Visibility State';
  constructor(public selectedWidget: FlowsMapWidgetStateModel) {}
}

export class ChangePointWidgetPointTypes {
  public static readonly type = '[Flows Map Module] Change Point Widget Point Types ';
  constructor(public pointsWidgetTabTypeSwitcher: VirtualPointType) {}
}

export class ChangeFlowsMapPointTypes {
  public static readonly type = '[Flows Map Module] Change Flows Map Point Types';
  constructor(public pointsSwitcher: UserPointTypesEnum) {}
}

export class UpdateSelectedPointsFilter {
  public static readonly type = '[Flows Map Module] Update Selected Points Filter';
  constructor(public pointsWidgetFilter: PointOperatorsFilter) {}
}
