import { Role, User } from 'appy-gas-core';

export class SetUserInfo {
  public static readonly type = '[User state] Set User Information';
  constructor(public user: User) {}
}

export class SetUserRole {
  public static readonly type = '[User state] Set User Role';
  constructor(public role: Role) {}
}
