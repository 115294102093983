// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "::ng-deep .dropdown-menu{border:0;box-shadow:0 4px 7px 0 rgba(0,0,0,.1);padding:0;margin-left:-5px;margin-top:8px}::ng-deep .dropdown-menu .tab-switcher__tab{line-height:16px;font-size:12px}::ng-deep .dropdown-menu .tab-switcher__active-line{min-width:90px}.checkbox-search-dropdown .dropdown-toggle:after{display:none}.checkbox-search-dropdown__toggle{cursor:pointer}.checkbox-search-dropdown__tab-switcher{max-width:200px}.checkbox-search-dropdown__list{overflow-y:auto;padding-left:0;max-height:120px;max-width:315px;width:100%;list-style:none}.checkbox-search-dropdown__list::-webkit-scrollbar{width:4px;background-color:#fff}.checkbox-search-dropdown__list::-webkit-scrollbar-thumb{border-radius:4px;background-color:#b1b9c3}.checkbox-search-dropdown__dropdown-item{font-size:12px;padding:0;cursor:pointer}.checkbox-search-dropdown__dropdown-item:hover{background-color:#eceef2}.checkbox-search-dropdown__dropdown-item-name{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;font-size:12px;color:#2c2f3d;margin-bottom:0;padding:7px 10px}.checkbox-search-dropdown__dropdown-item-name input[type=checkbox]{cursor:pointer;width:16px;min-width:16px;height:16px;margin-right:7px;border-radius:3px;border:1px solid #b1b9c3}.checkbox-search-dropdown__no-result{text-align:center;padding:14px;font-weight:400}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();