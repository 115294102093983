import { addDays, isAfter, isBefore, isSameMonth } from "date-fns";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import uniqWith from "lodash/uniqWith";

import { ChartSeriaItem, HubPriceDataItem, MarketAreaMap, MarketAreaNameConfig } from "appy-gas-core";
import { IPFilter, PointOperatorsFilter, TSOFilter } from "../shared/interfaces";
import { OrderClassesEnum, WidgetColsInRowEnum, WidgetRowsEnum } from "./enums";

export class DashboardHelperService {
  public static moveRow(currentIndex: number, moveUp: boolean, block: number[]): number[] {
    const movedBlock = [...block];
    const oldIndex = currentIndex;
    const newIndex = moveUp ? oldIndex - 1 : oldIndex + 1;
    const movedElement = movedBlock[oldIndex];

    movedBlock[oldIndex] = movedBlock[newIndex];
    movedBlock[newIndex] = movedElement;

    return movedBlock;
  }

  public static getColumnClass(blockPosition: number, block: number[]): string {
    const index = block.findIndex((position: number) => position === blockPosition);

    return DashboardHelperService.getWidgetColClass(index);
  }

  public static getWidgetRowClass(index: number): string {
    switch (index) {
      case WidgetRowsEnum.FIRST:
        return OrderClassesEnum.FIRST;
      case WidgetRowsEnum.SECOND:
        return OrderClassesEnum.THIRD;
      case WidgetRowsEnum.THIRD:
        return OrderClassesEnum.FIFTH;
      case WidgetRowsEnum.FOURTH:
        return OrderClassesEnum.SEVENTH;
      case WidgetRowsEnum.FIFTH:
        return OrderClassesEnum.NINTH;  
      default:
        return OrderClassesEnum.FIRST;
    }
  }

  public static getWidgetColClass(index: number): string {
    switch (index) {
      case WidgetColsInRowEnum.FIRST:
        return OrderClassesEnum.FIRST;
      case WidgetColsInRowEnum.SECOND:
        return OrderClassesEnum.SECOND;
      case WidgetColsInRowEnum.THIRD:
        return OrderClassesEnum.THIRD;
      default:
        return OrderClassesEnum.FIRST;
    }
  }

  public static getMaintenanceFilters(maintenancePlan: any[]): PointOperatorsFilter {
    const ip = uniqWith(
      maintenancePlan.map((item) => {
        return {
          name: `${item.pointName} (${item.operatorName})`,
          pointName: `${item.pointName}`,
          value: item.pointId,
          tsoId: item.operatorId,
        };
      }),
      isEqual
    ) as unknown as IPFilter[];
    const tso = uniqWith(
      maintenancePlan.map((item) => {
        return {
          name: item.operatorName,
          value: item.operatorId,
        };
      }),
      isEqual
    ) as unknown as TSOFilter[];
    return { ip: sortBy(ip, ["pointName"]), tso: sortBy(tso, ["name"]) };
  }

  public static filterHubPriceLegends(traceName: string, date: Date): boolean {
    const gaspoolAndNcgMergeDate = addDays(new Date("2021-09-30"), 1);
    const trsAndPegNordExpirationDate = addDays(new Date("2018-10-31"), 1);

    switch (traceName) {
      case MarketAreaNameConfig[MarketAreaMap.THE_H]:
        return isAfter(date, gaspoolAndNcgMergeDate);
      case MarketAreaNameConfig[MarketAreaMap.NCG_H]:
      case MarketAreaNameConfig[MarketAreaMap.GASPOOL_H]:
        return isSameMonth(gaspoolAndNcgMergeDate, date) || isBefore(date, gaspoolAndNcgMergeDate);
      case MarketAreaNameConfig[MarketAreaMap.TRS]:
        return isSameMonth(trsAndPegNordExpirationDate, date) || isBefore(date, trsAndPegNordExpirationDate);
      case MarketAreaNameConfig[MarketAreaMap.PEG_NORD]:
        return isSameMonth(trsAndPegNordExpirationDate, date) || isBefore(date, trsAndPegNordExpirationDate);
      case MarketAreaNameConfig[MarketAreaMap.PEG]:
        return isAfter(date, trsAndPegNordExpirationDate);
      default:
        return true;
    }
  }

  public static filterHubPriceDataByMergeGermanyDate(hubPricesData: HubPriceDataItem[]): HubPriceDataItem[] {
    const hubPriceMergedDate = addDays(new Date("2021-09-30"), 1);

    return hubPricesData.map((hubPrices) => {
      switch (hubPrices.name) {
        case MarketAreaNameConfig[MarketAreaMap.THE_H]:
          return {
            ...hubPrices,
            values: hubPrices.values.filter((value: ChartSeriaItem) => isAfter(value.date, hubPriceMergedDate)),
          };
        case MarketAreaNameConfig[MarketAreaMap.NCG_H]:
        case MarketAreaNameConfig[MarketAreaMap.GASPOOL_H]:
          return {
            ...hubPrices,
            values: hubPrices.values.filter((value: ChartSeriaItem) => isBefore(value.date, hubPriceMergedDate)),
          };
        default:
          return hubPrices;
      }
    });
  }
}
