import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from 'appy-gas-core';

@Component({
  selector: 'appy-gas-trial-expired',
  templateUrl: './trial-expired.component.html',
  styleUrls: ['./trial-expired.component.scss']
})
export class TrialExpiredComponent {
  constructor(private modalService: ModalService, private router: Router) {}

  public closeModal(): void {
    this.modalService.hide();
  }

  public upgradePremium(): void {
    this.closeModal();
    this.router.navigate(['/static/contact-us/upgrade-exclusive']);
  }

  public continueAsFree(): void {
    this.closeModal();
  }

  public deactivate(): void {
    this.router.navigate(['/profile']);
  }
}
