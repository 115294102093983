// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@media (-ms-high-contrast:none),screen and (-ms-high-contrast:active){:host{position:absolute;width:100%;bottom:0}}.appy-gas-footer{font-size:12px}.appy-gas-footer__wrapper{padding-top:1rem}.appy-gas-footer__icon{max-width:155px!important;max-height:45px!important}.appy-gas-footer__trade-mark{font-weight:400;color:#b1b9c3}.appy-gas-footer-nav__category{margin:0 1rem}.appy-gas-footer-nav__category-name{color:#2c2f3d;font-weight:600;font-size:14px}.appy-gas-footer-nav__category-name a.active{color:#7200da}.appy-gas-footer-nav__list{padding:0;list-style:none}.appy-gas-footer-nav__link{padding:.2rem 0;display:block}.appy-gas-footer-nav__link--active{color:#7200da}.appy-gas-footer-social-networks{border-left:1px solid #eceef2}.appy-gas-footer__social-icon{display:block;padding-left:1.2rem;background-position:0;background-repeat:no-repeat}.appy-gas-footer__social-icon--facebook{background-image:url(/assets/images/facebook.svg)}.appy-gas-footer__social-icon--twitter{background-image:url(/assets/images/twitter.svg)}.appy-gas-footer__social-icon--linked{background-image:url(/assets/images/linked-in.svg)}.appy-gas-footer__share-with-friend-link{padding:10px 12px;color:#fff;background-color:#7200da;border-radius:3px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;width:172px}.appy-gas-footer__share-with-friend-link appy-gas-svg-icon{display:inline-block;padding-right:5px}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();