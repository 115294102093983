import each from 'lodash/each';
import { finalize } from 'rxjs/operators';

import { Component, HostBinding } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { emailValidator } from 'appy-gas-core';

import { AuthenticationService } from '../../core/http-auth/authentication.service';
import { AuthModalHolderService } from '../auth-modal-holder.service';
import { AuthModalType } from '../auth-modal-type.enum';

@Component({
  selector: 'appy-gas-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  @HostBinding('class.is-page') public isPage = true;
  public error: string = null;
  public forgotForm: FormGroup;
  public isLoading = false;
  public emailWasSent = false;
  public title = 'Forgot your password';
  public description = `Enter the email address associated with your account,
  and we will send you an email with further instructions on how to change your password`;

  constructor(
    private modalHolder: AuthModalHolderService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService
  ) {
    this.createForm();
  }

  public forgot(): void {
    if (!this.forgotForm.valid) {
      each(this.forgotForm.controls, (control) => control.markAsTouched());
      return;
    }
    this.isLoading = true;
    this.authenticationService
      .forgotPassword(this.forgotForm.value)
      .pipe(
        finalize(() => {
          this.forgotForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        () => {
          this.title = 'Check your email!';
          this.description = `We’ve sent a message to <b>${this.forgotForm.controls.email.value}</b>. Open it and follow the instructions.`;
          this.emailWasSent = true;
        },
        (error) => (this.error = error)
      );
  }

  public signIn(): void {
    if (this.isPage) {
      this.router.navigate(['/auth/login']);
    } else {
      this.modalHolder.put(AuthModalType.LOGIN);
    }
  }

  private createForm(): void {
    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, emailValidator]]
    });
  }
}
