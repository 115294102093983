import { Component } from '@angular/core';

import { ModalService } from 'appy-gas-core/dist';

@Component({
  selector: 'appy-gas-switch-to-exclusive-info',
  templateUrl: './switch-to-exclusive-info.component.html',
  styleUrls: ['../user-transition-modals.scss', './switch-to-exclusive-info.component.scss']
})
export class SwitchToExclusiveInfoComponent {
  constructor(private modalService: ModalService) {}

  public hideModal(): void {
    this.modalService.hide();
  }
}
