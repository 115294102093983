import each from 'lodash/each';
import { finalize } from 'rxjs/operators';

import { Component, HostBinding, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  activityName,
  emailValidator,
  ModalService,
  nameValidator,
  passwordConfirmationValidator,
  passwordValidator,
  SignUpSuggestion
} from 'appy-gas-core';

import { AuthenticationService } from '../../core/http-auth/authentication.service';
import { AuthModalHolderService } from '../auth-modal-holder.service';
import { AuthModalType } from '../auth-modal-type.enum';
import { userDeactivatedModalConfig } from '../auth-modals';

enum SuggestionType {
  ACTIVITY = 'activity'
}

@Component({
  selector: 'appy-gas-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  @HostBinding('class.is-page') public isPage = true;
  public error: string = null;
  public signupForm: FormGroup;
  public isLoading = false;
  public title = 'Start navigating the European gas market with appygas';
  public description = 'Complete your account setup';
  public emailWasSent = false;
  public suggestionType = SuggestionType;
  public suggestions: { [suggestion: string]: SignUpSuggestion[] } = {
    [SuggestionType.ACTIVITY]: []
  };

  private static searchOptions(options: Map<number, string>): { id: number; label: string }[] {
    return Array.from(options, (map) => {
      return { id: map[0], label: map[1] };
    });
  }

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private injector: Injector,
    private modalHolder: AuthModalHolderService
  ) {
    this.createForm();
  }

  public signup(): void {
    if (!this.signupForm.valid) {
      each(this.signupForm.controls, (control) => control.markAsTouched());
      return;
    }

    this.isLoading = true;
    this.signupForm.value.activityId = this.signupForm.value.activity?.id || '';
    this.authenticationService
      .signup(this.signupForm.value)
      .pipe(
        finalize(() => {
          this.signupForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        () => {
          window.location.href = '/auth/signup/success?email=' + this.signupForm.controls.email.value;
          // this.router.navigate(['/auth/signup/success'], { queryParams: { email: this.signupForm.controls.email.value} })
          // .then(() => {
          //   window.location.href = '/auth/signup/success';
          //   // window.location.reload();
          // });
        //   this.title = 'Check your email!';
        //   this.description = `We’ve sent a message to <b>${this.signupForm.controls.email.value}</b>.
        //  Open it and follow the instruction.<br><br>If you have a Promo Code do not forget to use it in the Profile section.`;
        //   this.emailWasSent = true;
        },
        (error) => {
          if (error.status === 400) {
            this.error = error.error;
          } else if (error.status === 410) {
            this.modalService.show(this.injector, userDeactivatedModalConfig);
          } else {
            this.error = error.json();
          }
        }
      );
  }

  public signIn(): void {
    if (this.isPage) {
      this.router.navigate(['/auth/login']);
    } else {
      this.modalHolder.put(AuthModalType.LOGIN);
    }
  }

  public closeModal(): void {
    if (!this.isPage) {
      this.modalService.hide();
    }
  }

  public suggest(event: any, key: SuggestionType): void {
    this.suggestions[key] = SignupComponent.searchOptions(activityName);
  }

  private createForm(): void {
    this.signupForm = this.formBuilder.group({
      firstname: ['', [Validators.required, nameValidator]],
      lastname: ['', [Validators.required, nameValidator]],
      email: ['', [Validators.required, emailValidator]],
      password: ['', [Validators.required, passwordValidator]],
      confirmPassword: ['', [Validators.required, passwordConfirmationValidator]],
      activity: [''],
      phone: [''],
      newsLetter: [true]
    });
  }
}
