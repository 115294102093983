import { Directive, EventEmitter, HostListener, Injector, Input, OnInit, Output } from '@angular/core';

import { ModalService, Role } from 'appy-gas-core';

import { AuthenticationService } from '../../../core/http-auth/authentication.service';
import { upgradePremiumModalConfig } from '../../../core/upgrade-premium';

@Directive({
  selector: '[allowAction]' // tslint:disable-line
})
export class AllowActionDirective implements OnInit {
  @Input() public allowAction: string[];
  @Input() public forceRestrict: false;
  @Output() public onClick: EventEmitter<MouseEvent> = new EventEmitter();

  private allowedRoles: Role[];

  constructor(
    private modalService: ModalService,
    private authService: AuthenticationService,
    private injector: Injector
  ) {}

  public ngOnInit(): void {
    this.allowedRoles = this.allowAction.map((roleName) => Role[roleName]);
  }

  @HostListener('click', ['$event'])
  public clickEvent(event: MouseEvent): Promise<boolean> {
    return this.preventAction(event);
  }

  private preventAction(event: MouseEvent): Promise<boolean> {
    return this.authService.allowRoles(this.allowedRoles).then((authorize) => {
      if (authorize && !this.forceRestrict) {
        if (this.onClick) {
          this.onClick.emit(event);
        }
        return true;
      } else {
        this.modalService.show(this.injector, upgradePremiumModalConfig);
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    });
  }
}
