// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".statistic-button-pro-container{width:1.5rem;height:1.5rem;box-shadow:0 2px 5px 0 rgba(114,0,218,.19);-moz-box-shadow:0 2px 5px 0 rgba(114,0,218,.19);-webkit-box-shadow:0 2px 5px 0 rgba(114,0,218,.19);border-radius:50%;-moz-border-radius:50%;-webkit-border-radius:50%;-o-border-radius:50%;display:-ms-inline-flexbox;display:inline-flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;cursor:pointer;transition:all .5s}.statistic-button-pro-container:hover{box-shadow:0 2px 5px 0 rgba(114,0,218,.39);-moz-box-shadow:0 2px 5px 0 rgba(114,0,218,.39);-webkit-box-shadow:0 2px 5px 0 rgba(114,0,218,.39)}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();