export enum RouterPagesConfig {
  PROFILE = 'Profile',
  DASHBOARD = 'Dashboard',
  FLOWS_MAP = 'Flows Map',
  DATA_EXPORT = 'Data Export',
  ROUTE_CALCULATOR = 'Route Calculator',
  MARKET_AREA_VIEW = 'Market Area View',
  AVAILABILITY = 'Availability',
  AVAILABILITY_PRO = 'Availability Pro'
}
