// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":host{display:block;min-height:100vh;overflow:hidden;background-color:#fcfcfc}.auth-footer{text-align:center;font-size:12px;color:#b1b9c3;position:absolute;width:100%;height:60px;margin-top:-90px}.auth-footer__row{margin-bottom:9px}.auth-footer__contacts{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();