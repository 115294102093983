// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".profile-warning__header{padding:2rem 3.75rem;border-bottom:1px solid rgba(0,0,0,.125);text-transform:none}.profile-warning__controls-wrapper{padding:1.5rem 3.75rem 2.5rem}.profile-warning__controls-wrapper .btn:first-child{margin-right:1.87rem}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();