// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".transition-modal__header{padding:2rem 3.75rem .5rem}.transition-modal__main-block{padding:1.5rem 3.75rem 2.5rem}.transition-modal__main-img{position:relative;right:-20px}.transition-modal__description{font-size:12px;margin-bottom:35px}.transition-modal__left-sidebar{padding-right:10px}.transition-modal__right-sidebar{min-width:137px}.transition-modal__btn--submit img{margin:0 4px 0 0}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();