import { RouterState, RouterStateModel as RouterStateOuterModel } from '@ngxs/router-plugin';
import { Selector } from '@ngxs/store';

import { Params } from '@angular/router';

import { RouterStateModel } from './router-state.model';

export class RouterSelectors {
  @Selector([RouterState])
  public static params({ state }: RouterStateOuterModel<RouterStateModel>): Params {
    return state.params;
  }

  @Selector([RouterState])
  public static queryParams({ state }: RouterStateOuterModel<RouterStateModel>): Params {
    return state.queryParams;
  }

  @Selector([RouterState])
  public static url({ state }: RouterStateOuterModel<RouterStateModel>): string {
    return state.url;
  }
}
