import { IDownloadFile } from 'appy-gas-core';

export class GetExportData {
  public static readonly type = '[Data Export Module] Get Export Data';
}

export class GetExportDataSuccess {
  public static readonly type = '[Data Export Module] Get Export Data Success';
  constructor(public exportData: IDownloadFile[]) {}
}

export class GetExportDataFailed {
  public static readonly type = '[Data Export Module] Get Export Data Failed';
}
