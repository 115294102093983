import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Injectable } from '@angular/core';

import { MarketAreaActivePoint, Operators } from 'appy-gas-core';
import { CommonEntitiesService } from '../../core/common-entities/common-entities.service';
import { GetEePoints, GetOperators } from './common-entities.actions';
import { CommonEntitiesSelectors } from './common-entities.selectors';

@Injectable()
export class CommonEntitiesFacade {
  @Select(CommonEntitiesSelectors.operators)
  public operators$: Observable<Operators[]>;

  @Select(CommonEntitiesSelectors.eePoints)
  public eePoints$: Observable<MarketAreaActivePoint[]>;

  constructor(private commonEntitiesService: CommonEntitiesService) {}

  @Dispatch()
  public getEeVirtualPoints(dateTime: number): Observable<GetEePoints> {
    return this.commonEntitiesService.getVirtualPointsActive(dateTime).pipe(map((data) => new GetEePoints(data)));
  }

  @Dispatch()
  public getOperators(): Observable<GetOperators> {
    return this.commonEntitiesService.loadOperators().pipe(map((operators) => new GetOperators(operators)));
  }
}
