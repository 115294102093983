import { Action, State, StateContext } from '@ngxs/store';

import { Role, User } from 'appy-gas-core';
import { SetUserInfo, SetUserRole } from './user.actions';

export interface UserStateModel {
  user: User;
  role: Role;
}

@State<UserStateModel>({
  name: 'userInfo',
  defaults: {
    user: undefined,
    role: undefined
  }
})
export class UserState {
  @Action(SetUserInfo)
  private setUserInfo({ patchState }: StateContext<UserStateModel>, { user }: SetUserInfo): void {
    patchState({ user });
  }

  @Action(SetUserRole)
  private setUserRole({ patchState }: StateContext<UserStateModel>, { role }: SetUserRole): void {
    patchState({ role });
  }
}
