import { Store } from '@ngxs/store';
import addDays from 'date-fns/addDays';
import isAfter from 'date-fns/isAfter';

import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { GrantType, ModalService, Role, TokenType } from 'appy-gas-core';

import { AuthenticationService } from '../../core/http-auth';
import { getMetadataHeaders } from '../../core/http-auth/headers.helper';
import { AuthInterceptor } from '../../core/http-auth/http-interceptors/auth-interceptor';
import { ProfileSelectors, UserSelectors } from '../../store';
import {
  infoModalConfig,
  restrictedMarketAreaClassicConfig,
  restrictedMarketAreaPremiumConfig,
  switchDeclinedModalConfig,
  switchToExclusiveDaysConfig,
  switchToExclusiveInfoModalConfig,
  switchToExclusiveModalConfig,
  youAreExclusiveModalConfig
} from '../components/user-transition-modals';
import { restrictedFeatureMAs } from '../constants/app-exceptions';
import { ExclusiveDaysPeriodEnum } from '../enums/exclusive-days-period.enum';
import { LocalStorageKeysEnum } from '../enums/local-storage-keys.enum';
import { UpdateUserRoleResponseTypes } from '../enums/update-user-role-response-types.enum';
import { RestrictedMarketAreaFeature } from '../interfaces';
import { UpdateUserRoleErrorModel } from '../interfaces/update-user-role-error.model';

@Injectable({
  providedIn: 'root'
})
export class UserRoleTransitionService {
  private get userRole(): Role {
    return this.store.selectSnapshot(UserSelectors.getUserRole);
  }

  private get isTrialExclusiveUsed(): boolean {
    return this.store.selectSnapshot(UserSelectors.isTrialExclusiveUsed);
  }

  private get appiesCount(): number {
    return this.store.selectSnapshot(ProfileSelectors.appiesCount);
  }

  constructor(
    private authService: AuthenticationService,
    private http: HttpClient,
    private store: Store,
    private modalService: ModalService,
    private injector: Injector
  ) {
    this.handleDynamicTransitionModals();
  }

  public switchToExclusive(): void {
    if (this.userRole === Role.Classic) {
      this.switchForClassicUser();
      return;
    } else if (
      this.userRole === Role.PremiumPlus ||
      this.userRole === Role.Premium ||
      this.userRole === Role.Expired ||
      this.userRole === Role.Free ||
      this.userRole === Role.PreClassic ||
      this.userRole === Role.PromoClassic ||
      (this.userRole === Role.TrialClassic && this.isTrialExclusiveUsed)
    ) {
      this.showRestrictedFeatureModal();
      return;
    } else if (this.userRole === Role.TrialClassic && !this.isTrialExclusiveUsed) {
      this.switchForTrialClassicUser();
      return;
    }
    console.log('Unknown role', this.userRole);
  }

  public getRestrictedMAFeatureByAppyGasId(appyGasId: string): RestrictedMarketAreaFeature {
    const restrictedMarketArea = restrictedFeatureMAs.find((restrictedFeatureMA: RestrictedMarketAreaFeature) =>
      restrictedFeatureMA.VIPIds.includes(appyGasId)
    );
    if (this.isRestrictedMAIsValid(restrictedMarketArea)) {
      return restrictedMarketArea;
    }
  }

  public getRestrictedMAFeatureByMAId(marketAreaId: number): RestrictedMarketAreaFeature {
    const restrictedMarketArea = restrictedFeatureMAs.find(
      (restrictedMAData: RestrictedMarketAreaFeature) => restrictedMAData.marketAreaId === marketAreaId
    );
    if (this.isRestrictedMAIsValid(restrictedMarketArea)) {
      return restrictedMarketArea;
    }
  }

  public getRestrictedMAFeatureByPointId(pointId: number): RestrictedMarketAreaFeature {
    const restrictedMarketArea = restrictedFeatureMAs.find((restrictedFeatureMA: RestrictedMarketAreaFeature) =>
      restrictedFeatureMA.pointIds.includes(pointId)
    );
    if (this.isRestrictedMAIsValid(restrictedMarketArea)) {
      return restrictedMarketArea;
    }
  }

  public showRestrictedMAsFeatureModal(restrictedMA: RestrictedMarketAreaFeature): void {
    if ([Role.PremiumPlus, Role.Premium].includes(this.userRole)) {
      this.modalService.show(this.injector, restrictedMarketAreaPremiumConfig);
    } else {
      const config = {
        ...restrictedMarketAreaClassicConfig,
        component: {
          ...restrictedMarketAreaClassicConfig.component,
          options: {
            ...restrictedMarketAreaClassicConfig.component.options,
            activatedDate: restrictedMA.activatedDate,
            proceedToNextStep: () => this.switchToExclusive()
          }
        }
      };
      this.modalService.show(this.injector, config);
    }
  }

  private showRestrictedFeatureModal(): void {
    this.modalService.show(this.injector, switchToExclusiveInfoModalConfig);
  }

  private switchForClassicUser(): void {
    const config = {
      ...switchToExclusiveModalConfig,
      component: {
        ...switchToExclusiveModalConfig.component,
        options: {
          ...switchToExclusiveModalConfig.component.options,
          appiesCount: this.appiesCount,
          proceedToNextStep: (period: ExclusiveDaysPeriodEnum) => this.switchToExclusiveByPeriod(period)
        }
      }
    };
    this.modalService.show(this.injector, config);
  }

  private switchForTrialClassicUser(): void {
    const config = {
      ...switchToExclusiveDaysConfig,
      component: {
        ...switchToExclusiveDaysConfig.component,
        options: {
          ...switchToExclusiveDaysConfig.component.options,
          proceedToNextStep: () => this.switchToExclusiveForTrialUser()
        }
      }
    };
    this.modalService.show(this.injector, config);
  }

  private showNotEnoughMoneyModal(): void {
    this.modalService.show(this.injector, switchDeclinedModalConfig);
  }

  private switchToExclusiveByPeriod(period: ExclusiveDaysPeriodEnum): void {
    this.http
      .post(`/accounts/upgrade/exclusive/${period}`, {}, { headers: getMetadataHeaders({ auth: true }) })
      .subscribe(
        () => this.upgradeUser(period),
        ({ error }: { error: UpdateUserRoleErrorModel }) => {
          if (error.Key === UpdateUserRoleResponseTypes.NOT_ENOUGH_MONEY) {
            this.showNotEnoughMoneyModal();
          }
        }
      );
  }

  private switchToExclusiveForTrialUser(): void {
    this.http
      .post(`/accounts/upgrade/TrialExclusive`, {}, { headers: getMetadataHeaders({ auth: true }) })
      .subscribe(() => this.upgradeTrialUser());
  }

  private upgradeUser(period: ExclusiveDaysPeriodEnum): void {
    const refreshToken: string = AuthInterceptor.getToken(TokenType.REFRESH);
    this.authService
      .refreshToken({
        refresh_token: refreshToken,
        grant_type: GrantType.REFRESH_TOKEN
      })
      .subscribe(() => {
        localStorage.setItem(LocalStorageKeysEnum.USER_ROLE_INFO_MODAL, JSON.stringify(period));
      });
  }

  private upgradeTrialUser(): void {
    const refreshToken: string = AuthInterceptor.getToken(TokenType.REFRESH);
    this.authService
      .refreshToken({
        refresh_token: refreshToken,
        grant_type: GrantType.REFRESH_TOKEN
      })
      .subscribe(() => {
        localStorage.setItem(LocalStorageKeysEnum.USER_ROLE_EXCLUSIVE_MODAL, JSON.stringify(true));
      });
  }

  private showSuccessfulUpdateModal(accessDays: number): void {
    const dateAccessEnd = addDays(new Date(), accessDays);
    const config = {
      ...infoModalConfig,
      component: {
        ...infoModalConfig.component,
        options: {
          ...infoModalConfig.component.options,
          accessDays,
          dateAccessEnd
        }
      }
    };
    this.modalService.show(this.injector, config);
  }

  private showSuccessfulTrialUpdateModal(): void {
    this.modalService.show(this.injector, youAreExclusiveModalConfig);
  }

  private handleDynamicTransitionModals(): void {
    const showUserRoleInfoModal = localStorage.getItem(LocalStorageKeysEnum.USER_ROLE_INFO_MODAL);
    const showUserRoleExclusiveModal = localStorage.getItem(LocalStorageKeysEnum.USER_ROLE_EXCLUSIVE_MODAL);

    if (showUserRoleInfoModal) {
      this.showSuccessfulUpdateModal(JSON.parse(showUserRoleInfoModal));
    }

    if (showUserRoleExclusiveModal) {
      this.showSuccessfulTrialUpdateModal();
    }
  }

  public isRestrictedMAIsValid(restrictedMA: RestrictedMarketAreaFeature): boolean {
    return isAfter(restrictedMA?.activatedDate, new Date()) && restrictedMA?.roles.includes(this.userRole);
  }
}
