import { Router } from '@angular/router';

import { Component, Input } from '@angular/core';

@Component({
  selector: 'appy-gas-restrict-element',
  templateUrl: './restrict-element.component.html',
  styleUrls: ['./restrict-element.component.scss']
})
export class RestrictElementComponent {
  @Input() public background: string;
  @Input() public callToAction = true;
  @Input() public caption: string;
  @Input() public link: string;

  constructor(private router: Router) {}

  public upgradePremium(): void {
    this.router.navigate(['/static/contact-us/upgrade-exclusive']);
  }
}
