import { NgModule } from '@angular/core';
import { EnvVariables } from 'appy-gas-core';

import { developmentVariables } from './development';
import { productionVariables } from './production';
import { stagingVariables } from './staging';

declare const process: any; // Typescript compiler will complain without this

// tslint:disable-next-line:typedef
export function environmentFactory() {
  let variables;

  switch (process.env.NODE_ENV) {
    case 'production':
    case 'prod':
      variables = productionVariables;
      break;
    case 'stage':
      variables = stagingVariables;
      break;
    case 'development':
    case 'dev':
      variables = developmentVariables;
      break;
    default:
      variables = developmentVariables;
  }
  return variables;
}

@NgModule({
  providers: [
    {
      provide: EnvVariables,
      // useFactory instead of useValue so we can easily add more logic as needed.
      useFactory: environmentFactory
    }
  ]
})
export class EnvironmentsModule {}
