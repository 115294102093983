import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';

import {
  EnvironmentVariables,
  EnvVariables,
  ErrorTypeListEnum,
  GrantType,
  HeaderMetaDataOptions,
  InterceptorConfig,
  Logger,
  ModalService,
  SpinnerService,
  TokenType
} from 'appy-gas-core';

import { APPYGAS_TOKENS } from '../auth-config';
import { AuthenticationService } from '../authentication.service';

const log = new Logger('HttpWebService');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    @Inject(EnvVariables) public envVariables: EnvironmentVariables,
    private authService: AuthenticationService,
    private spinnerService: SpinnerService,
    public modalService: ModalService,
    private router: Router
  ) {
    this.config = new InterceptorConfig({ serverUrl: envVariables.serverUrl });
  }
  private config: InterceptorConfig;
  private refreshTokenInProgress = false;
  private token: string;
  private refreshTokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  private static errorHandler(response: Response): Observable<any> {
    log.error('Request error', response);
    return throwError(response);
  }

  public static getToken(type: TokenType): string {
    return localStorage.getItem(APPYGAS_TOKENS[type]); // TODO: create a localStorage service;
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerMetaData: HeaderMetaDataOptions = JSON.parse(req.headers.get('X-META-DATA'));

    this.token = AuthInterceptor.getToken(TokenType.ACCESS);
    if (headerMetaData?.auth) {
      req = this.addAuthenticationToken(req);
    } else {
      req.headers.delete(this.config.headerName);
    }

    if (!req.headers.get('Content-Type')) {
      req = req.clone({
        setHeaders: { 'Content-Type': 'application/json' }
      });
    }

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error?.status === ErrorTypeListEnum.UNAUTHORIZED) {
          const refreshToken: string = AuthInterceptor.getToken(TokenType.REFRESH);

          if (refreshToken) {
            this.spinnerService.show();
            if (this.refreshTokenInProgress) {
              return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap(() => next.handle(this.addAuthenticationToken(req)))
              );
            } else {
              this.refreshTokenInProgress = true;
              this.refreshTokenSubject.next(null);

              return this.refreshAccessToken().pipe(
                switchMap((accessToken: string) => {
                  if (accessToken) {
                    this.token = accessToken;
                    this.refreshTokenSubject.next(accessToken);
                    return next.handle(this.addAuthenticationToken(req));
                  }
                  this.refreshTokenInProgress = false;
                  this.logout();
                }),
                finalize(() => {
                  this.refreshTokenInProgress = false;
                  this.spinnerService.hide();
                }),
                catchError((errorRefreshToken) => {
                  if (errorRefreshToken.status === ErrorTypeListEnum.FORBIDDEN) {
                    return;
                  }

                  this.logout();
                  return AuthInterceptor.errorHandler(errorRefreshToken);
                })
              );
            }
          } else {
            this.logout();
          }
        } else {
          return throwError(error);
        }
      })
    );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    if (!this.token) {
      return request;
    }

    return request.clone({
      headers: request.headers.set(this.config.headerName, `${this.config.headerPrefix} ${this.token}`)
    });
  }

  private logout(): void {
    this.router.navigate(['/auth/login']);
    this.authService.logout();
  }

  private refreshAccessToken(): Observable<string> {
    const refreshToken: string = AuthInterceptor.getToken(TokenType.REFRESH);

    return this.authService.refreshToken({
      refresh_token: refreshToken,
      grant_type: GrantType.REFRESH_TOKEN
    });
  }
}
