import {
  ChartDataItemModel,
  EventDetailsModel,
  MaintenanceEventsModel,
  MaintenanceMapPoint,
  OtherEventModel,
  RangeContext,
  RelatedPointsModel
} from 'appy-gas-core';

import { MaintenanceFiltersModel } from '../../availability-pro/availability-pro-map/availability-pro-map-filter/interfaces';
import { MaintenanceMapRoutePointUI } from '../../availability-pro/availability-pro-map/availability-pro-market-areas-map/interfaces';
import { WidgetStateModel } from '../../availability-pro/availability-pro-map/interfaces';
import { MAEventsTabDirection, UserPointTypesEnum } from '../../shared/enums';
import { PointOperatorsFilter } from '../../shared/interfaces';

export class GetAllMaintenancePoints {
  public static readonly type = '[Availability Module] Get All Virtual Points';
  constructor(public allMaintenancePoints: MaintenanceMapPoint[]) {}
}

export class GetAllEventPoints {
  public static readonly type = '[Availability Module] Get All Event Points';
}

export class GetAllEventPointsSuccess {
  public static readonly type = '[Availability Module] Get All Event Points Success';
  constructor(public allEventPoints: MaintenanceEventsModel[]) {}
}

export class GetAllEventPointsFailed {
  public static readonly type = '[Availability Module] Get All Event Points Failed';
}

export class GetPortfolioMaintenancePoints {
  public static readonly type = '[Availability Module] Get Portfolio Virtual Points';
}

export class ResetPortfolioMaintenancePoints {
  public static readonly type = '[Availability Module] Reset Portfolio Virtual Points';
}

export class GetPortfolioMaintenancePointsSuccess {
  public static readonly type = '[Availability Module] Get Portfolio Virtual Points Success';
  constructor(public portfolioMaintenancePoints: MaintenanceMapPoint[]) {}
}

export class GetPortfolioMaintenancePointsFailed {
  public static readonly type = '[Availability Module] Get Portfolio Virtual Points Failed';
}

export class GetAvailabilityData {
  public static readonly type = '[Availability Module] Get Availability Data';
  constructor(public availabilityData: [MaintenanceMapPoint[], any[]]) {}
}

export class UpdateMaintenanceFilters {
  public static readonly type = '[Availability Module] Update Maintenance Filters';
  constructor(public maintenanceFilters: MaintenanceFiltersModel) {}
}

export class GetSelectedPointEvents {
  public static readonly type = '[Availability Module] Get Selected Point Events';
}

export class ResetSelectedPointEvents {
  public static readonly type = '[Availability Module] Reset Selected Point Events';
}

export class GetSelectedPointEventsSuccess {
  public static readonly type = '[Availability Module] Get Selected Point Events Success';
  constructor(public selectedPointEvents: MaintenanceEventsModel, public vipPointMarketAreasIds: number[]) {}
}

export class GetSelectedPointEventsFailed {
  public static readonly type = '[Availability Module] Get Selected Point Events Failed';
}

export class SetSelectedMapPointId {
  public static readonly type = '[Availability Module] Set Selected Map Point Id';
  constructor(public selectedMapPointId: string) {}
}

export class UpdateSelectedPointEventsFilter {
  public static readonly type = '[Availability Module] Update Selected Point Events Filter';
  constructor(public eventsWidgetFilter: PointOperatorsFilter) {}
}

export class GetSelectedNorwayEvents {
  public static readonly type = '[Availability Module] Get Selected Norway Events';
  constructor(public selectedPointEvents: MaintenanceEventsModel[], public vipPointMarketAreasIds: number[]) {}
}

export class GetSelectedPEGSuperPointsEvents {
  public static readonly type = '[Availability Module] Get Selected PEG Super Points Events';
  constructor(public selectedPointEvents: MaintenanceEventsModel[], public vipPointMarketAreasIds: number[]) {}
}

export class GetSelectedRouteDetails {
  public static readonly type = '[Availability Module] Get Selected Route Details';
}

export class ResetSelectedRouteDetails {
  public static readonly type = '[Availability Module] Reset Selected Route Details';
}

export class GetSelectedRouteDetailsSuccess {
  public static readonly type = '[Availability Module] Get Selected Route Details Success';
  constructor(public selectedRouteEvents: MaintenanceEventsModel[]) {}
}

export class GetSelectedRouteDetailsFailed {
  public static readonly type = '[Availability Module] Get Selected Route Details Failed';
}

export class GetRelatedPointsLoading {
  public static readonly type = '[Availability Module] Get Related Points Loading';
  constructor(public pointId: number) {}
}

export class GetRelatedPoints {
  public static readonly type = '[Availability Module] Get Related Points';
  constructor(public relatedPoints: RelatedPointsModel, public pointId: number) {}
}

export class GetEventDetailsLoading {
  public static readonly type = '[Availability Module] Get Event Details Loading';
  constructor(public pointId: number) {}
}

export class GetEventDetails {
  public static readonly type = '[Availability Module] Get Event Details';
  constructor(public eventDetails: EventDetailsModel, public pointId: number) {}
}

export class GetOtherEventsLoading {
  public static readonly type = '[Availability Module] Get Other Events Loading';
  constructor(public pointId: number) {}
}

export class GetOtherEvents {
  public static readonly type = '[Availability Module] Get Other Events';
  constructor(public otherEvents: OtherEventModel[], public pointId: number) {}
}

export class GetImpactChartData {
  public static readonly type = '[Availability Module] Get Impact Chart Data';
  constructor(public impactChartData: ChartDataItemModel[], public pointId: number) {}
}

export class GetImpactChartDataLoading {
  public static readonly type = '[Availability Module] Get Impact Chart Data Loading';
  constructor(public pointId: number) {}
}

export class TogglePointInRoute {
  public static readonly type = '[Availability Module] Toggle Point To Route';
  constructor(public virtualPoint: MaintenanceMapRoutePointUI) {}
}

export class ResetSelectedRoutePoints {
  public static readonly type = '[Availability Module] Reset Selected Route Points';
}

export class ChangeAvailabilityPointTypes {
  public static readonly type = '[Availability Module] Change Availability Point Types';
  constructor(public pointsSwitcher: UserPointTypesEnum) {}
}

export class UpdateGasDayRange {
  public static readonly type = '[Availability Module] Update Gas Day Range';
  constructor(public gasDayRange: RangeContext) {}
}

export class UpdateWidgetsVisibilityState {
  public static readonly type = '[Availability Module] Update Widgets Visibility State';
  constructor(public selectedWidget: WidgetStateModel) {}
}

export class UpdateActiveMATabType {
  public static readonly type = '[Availability Module] Update Active MA Tab Type ';
  constructor(public activeMATabType: MAEventsTabDirection) {}
}

export class SetNavigatedFromExternalPageStatus {
  public static readonly type = '[Availability Module] Set Navigated From External Page Status';
  constructor(public isNavigatedFromExternalPage: boolean) {}
}

export class SetNavigatedFromGeneralInfoPageStatus {
  public static readonly type = '[Availability Module] Set Navigated From General Info Page Status';
  constructor(public isNavigatedFromGeneralInfoPage: boolean) {}
}

export class SetNorwayPointSelectedStatus {
  public static readonly type = '[Availability Module] Set Norway Point Selected Status';
  constructor(public isNorwayPointSelected: boolean) {}
}
