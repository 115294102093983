// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".expandable-section__icon{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;width:30px;height:30px;font-size:1.5rem;border-radius:50%;border:1px solid #737b85;color:#737b85;font-style:normal;transition:transform .3s ease-in;cursor:pointer}.expandable-section__title{-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;padding:1rem 0;border-bottom:1px solid #b1b9c3}.expandable-section__title.active,.expandable-section__title:hover,.expandable-section__title:hover .expandable-section__icon{color:#7200da;border-color:#7200da}.expandable-section__title.active .expandable-section__icon{color:#7200da;border-color:#7200da;transform:rotate(45deg);transition:transform .3s ease-in}", ""]);
// Exports
module.exports = exports;


/*** EXPORTS FROM exports-loader ***/
module.exports = module.exports.toString();