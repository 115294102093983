import each from 'lodash/each';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';

import { Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { emailValidator, ModalService, Role } from 'appy-gas-core';

import { AuthenticationService } from '../../core/http-auth/authentication.service';
import { AuthModalHolderService } from '../auth-modal-holder.service';
import { AuthModalType } from '../auth-modal-type.enum';

@Component({
  selector: 'appy-gas-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('class.is-page') public isPage = true;

  @Input() private redirectUrl = '/';

  public error: string = null;
  public loginForm: FormGroup;
  public isLoading = false;
  public passwordSuccessfullyChanged = false;

  private readonly componentDestroyed$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private modalService: ModalService,
    private authenticationService: AuthenticationService,
    private modalHolder: AuthModalHolderService
  ) {
    this.createForm();
  }

  public ngOnInit(): void {
    this.authenticationService.logout();
    this.route.params.subscribe((params) => {
      this.passwordSuccessfullyChanged = params.passwordSuccessfullyChanged;
    });
    this.authenticationService.role.pipe(takeUntil(this.componentDestroyed$)).subscribe((role) => {
      const redirectedUrl = role === Role.Expired ? '/profile' : '/';
      this.router.navigateByUrl(redirectedUrl);
    });
  }

  public ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  public login(): void {
    if (!this.loginForm.valid) {
      each(this.loginForm.controls, (control) => control.markAsTouched());
      return;
    }
    this.isLoading = true;
    this.authenticationService
      .login(this.loginForm.value)
      .pipe(
        finalize(() => {
          this.loginForm.markAsPristine();
          this.isLoading = false;
        })
      )
      .subscribe(
        () => this.hideModal(),
        ({ error }) => (this.error = error.error)
      );
  }

  public signUp(): void {
    if (this.isPage) {
      this.router.navigate(['/auth/signup']);
    } else {
      this.modalHolder.put(AuthModalType.SIGNUP);
    }
  }

  public forgotPassword(): void {
    if (this.isPage) {
      this.router.navigate(['/auth/forgot-password']);
    } else {
      this.modalHolder.put(AuthModalType.FORGOT);
    }
  }

  private hideModal(): void {
    if (!this.isPage) {
      this.modalService.hide();
    }
  }

  private createForm(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, emailValidator]],
      password: ['', Validators.required],
      grant_type: ['password', Validators.required]
    });
  }
}
