import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getMetadataHeaders } from 'appy-gas-core';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  constructor(private http: HttpClient) {}

  public acceptTermsOfUse(): Observable<void> {
    return <Observable<void>>(
      this.http
        .post(
          `/termsofservice/accept`,
          { headers: getMetadataHeaders({ auth: true }) }
        )
        .pipe(tap(x => console.log(x)))
    );
  }
}
