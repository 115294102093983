import { MarketAreaActivePoint, Operators } from 'appy-gas-core';

export class GetEePoints {
  public static readonly type = '[Common Entities] Get EE Points';
  constructor(public eePoints: MarketAreaActivePoint[]) {}
}

export class GetOperators {
  public static readonly type = '[Common Entities] Get Operators';
  constructor(public operators: Operators[]) {}
}
