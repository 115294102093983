import { createSelector, Selector } from '@ngxs/store';

import { Role, User } from 'appy-gas-core';

import { UserState, UserStateModel } from './user.state';

export class UserSelectors {
  @Selector([UserState])
  public static getUser(state: UserStateModel): User {
    return state.user;
  }

  @Selector([UserState])
  public static getUserRole(state: UserStateModel): Role {
    return state.role;
  }

  @Selector([UserSelectors.getUserRole])
  public static isExclusiveUser(role: Role): boolean {
    return [Role.Exclusive, Role.TrialExclusive, Role.PromoExclusive, Role.PromoTrialExclusive, Role.Appygas].includes(
      role
    );
  }

  @Selector([UserSelectors.getUserRole])
  public static isPremiumPlusUser(role: Role): boolean {
    return role === Role.PremiumPlus;
  }

  @Selector([UserSelectors.getUserRole])
  public static isNonTrialExclusiveUser(role: Role): boolean {
    return [Role.Exclusive, Role.PromoExclusive, Role.Appygas].includes(role);
  }

  @Selector([UserSelectors.getUserRole])
  public static isExclusivePremiumUser(role: Role): boolean {
    return [
      Role.Premium,
      Role.PremiumPlus,
      Role.Exclusive,
      Role.TrialExclusive,
      Role.PromoExclusive,
      Role.PromoTrialExclusive,
      Role.Appygas
    ].includes(role);
  }

  @Selector([UserSelectors.getUserRole])
  public static isLimitedUser(role: Role): boolean {
    return [Role.Guest, Role.Free, Role.Expired, Role.Trial].includes(role);
  }

  @Selector([UserSelectors.getUserRole])
  public static isContractUser(role: Role): boolean {
    return [Role.PreClassic, Role.Classic, Role.Exclusive, Role.PromoExclusive, Role.Appygas].includes(role);
  }

  @Selector([UserSelectors.getUserRole])
  public static isClassicUser(role: Role): boolean {
    return [Role.PreClassic, Role.Classic, Role.TrialClassic].includes(role);
  }

  @Selector([UserSelectors.getUser])
  public static isTrialExclusiveUsed(user: User): boolean {
    return user.isTrialExclusiveUsed;
  }

  @Selector([UserSelectors.getUserRole, UserSelectors.isTrialExclusiveUsed])
  public static isSwitchableUser(role: Role, isTrialExclusiveUsed: boolean): boolean {
    return role === Role.Classic || (role === Role.TrialClassic && !isTrialExclusiveUsed);
  }

  public static isUserRoleIncluded(roles: Role[]): (userRole: Role) => boolean {
    return createSelector([UserSelectors.getUserRole], (userRole: Role) => {
      return roles.includes(userRole);
    });
  }
}
