import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { getMetadataHeaders } from '../core/http-auth/headers.helper';
import { ProfileHelperService } from '../shared/services/profile-helper.service';
import { BadgeModel } from './interfaces/badge.model';
import { PortfolioInformationModel } from './interfaces/portfolio-information.model';
import { UserBadgesModel } from './interfaces/user-badges.model';
import { WalletInformationModel } from './interfaces/wallet-information.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(private http: HttpClient) {}

  public getPortfolioInformation(): Observable<PortfolioInformationModel> {
    return <Observable<PortfolioInformationModel>>this.http.get('/preferences/portfolio');
  }

  public getUserPortfolioPoints(userId: number): Observable<number[]> {
    return <Observable<number[]>>(
      this.http.get(`/preferences/${userId}/portfolio`, { headers: getMetadataHeaders({ auth: true }) })
    );
  }

  public saveUserPortfolioPoints(userId: number, pointIds: number[]): Observable<number[]> {
    // TODO remove when super points with two operators removed
    const parsedWithExceptionsPoints = ProfileHelperService.parseSuperPointExceptionIds(pointIds);

    return <Observable<number[]>>(
      this.http
        .put(
          `/preferences/${userId}/portfolio`,
          { pointIds: parsedWithExceptionsPoints },
          { headers: getMetadataHeaders({ auth: true }) }
        )
        .pipe(map((ids) => ProfileHelperService.addSuperPointExceptionIds(<number[]>ids)))
    );
  }

  public getWalletInformation(): Observable<WalletInformationModel> {
    return <Observable<WalletInformationModel>>(
      this.http.get(`/accounts/wallet`, { headers: getMetadataHeaders({ auth: true }) })
    );
  }

  public getUserBadges(userId: number): Observable<UserBadgesModel[]> {
    return <Observable<UserBadgesModel[]>>(
      this.http.get(`/badges/${userId}`, { headers: getMetadataHeaders({ auth: true }) })
    );
  }

  public getAllBadges(): Observable<BadgeModel[]> {
    return <Observable<BadgeModel[]>>this.http.get(`/badges`, { headers: getMetadataHeaders({ auth: true }) });
  }
}
